const messages = {
    en: {
        "1-room-fits": "1 room fits",
        "about-hotel-description": "About Hotel Description",
        "ascending":"Ascending",
        "add-a-flight": "Add a flight",
        "address": "Address",
        "adult": "Adult",
        "adult(s)": "Adult(s)",
        "adult(s)-to": "adult(s) to",
        "adult-fare": "Adult Fare",
        "adults": "Adults",
        "age": "Age",
        "air-reservation": "Air Reservation",
        "air-tickets": "Air Tickets",
        "airline": "Airline",
        "airline-record-locator":"Airline Record Locator",
        "all": "All",
        "amenities": "Amenities",
        "amenity": "Amenities",
        "amenity-of-hotel": "AMENITIES OF HOTEL",
        "ancillary-services": "Ancillary services",
        "area-code": "Area Code",
        "arrival": "Arrival",
        "arrival-airport": "Arrival Airport",
        "arrival-time":"Arrival Time",
        "arrival-time-in-destination":"Arrival Time in Destination",
        "awesome-amsterdam": "Awesome Amsterdam",
        "avg": "Avg",
        "basic-description": "Basic Description",
        "become-a-partner": "Become A Partner",
        "best-price": "Best Price",
        "beach-goa": "Beach Goa",
        "bed-type": "Bed Type",
        "before": "Before",
        "blog": "BLOG",
        "book": "Book",
        "book-now": "BOOK NOW",
        "book-car-transfer": "Book Car Transfer",
        "book-flight-tickets": "Book Flight Tickets",
        "book-holiday-package": "Book Holiday Package",
        "book-hotel-rooms": "Book Hotel Rooms",
        "book-tour-activity": "Book Tour Activity",
        "booking-create-date":"Booking Create Date",
        "booking-engine": "Booking Engine",
        "booking-date": "Booking Date",
        "booking-id": "Booking ID",
        "booking-number": "Booking Number",
        "booking-overview": "Booking Overview",
        "booking-status": "Booking Status",
        "booking-total": "Booking Total",
        "bookings": "Bookings",
        "breakfast": "Breakfast",
        "brand": "Brand",
        "cabin-class": "Cabin Class",
        "cancellable": "Cancellable",
        "cancel": "Cancel",
        "cancellations-after": "Cancellations after",
        "cancelled": "Cancelled",
        "cancel-policy": "Cancel Policy",
        "car-brand": "Car Brand",
        "car-details": "Car Details",
        "car-rental-price": "Car Rental Price",
        "career": "Career",
        "car-features": "Car Features",
        "car-info": "Car Info",
        "change-password": "Change Password",
        "confirm-password": "Confirm Password",
        "contact-hotel": "Contact Hotel",
        "contact-number": "Contact Number",
        "contract-remarks": "Contract Remarks",
        "current-address": "Current Address",
        "car-name": "Car name",
        "car-rental": "Car Rental",
        "car-rental-info": "Car Rental Info",
        "car-transfer": "Car Transfer",
        "car-transfer-info-first-trip": "Car Transfer Info - First Trip",
        "car-transfer-info-second-trip": "Car Transfer Info - Second Trip",
        "car-transfer-total-price": "Car Transfer Total Price",
        "cars": "CARS",
        "casino-los-vages": "Casino Los Vages",
        "check---in": "Check - In",
        "check--out": "Check -Out",
        "check-date": "Check Date",
        "check-in": "Check-in",
        "check-in-date": "Check In Date",
        "check-out": "Check-out",
        "check-out-date": "Check Out Date",
        "child": "Child",
        "child(ren)": "Child(ren)",
        "child-age": "Child Age",
        "child-fare": "Child Fare",
        "city-name": "City Name",
        "class": "Class",
        "home": "Home",
        "package": "Package",
        "penalty": "penalty",
        "return-date": "return-date",
        "contact-agent": "Contact Agent",
        "contact-person": "Contact Person",
        "contact-us": "Contact Us",
        "contact": "Contact",
        "continue": "CONTINUE",
        "copyright-©-2021-limpidthemes.-all-rights-reserved.": "Copyright © 2021 LimpidThemes. All Rights Reserved.",
        "country": "Country",
        "country-code": "Country Code",
        "coverage-info": "Coverage Info",
        "cruise": "CRUISE",
        "cruise-holidays": "Cruise Holidays",
        "currencytag": "CurrencyTag",
        "customer-max-waiting-time": "Customer Max Waiting Time",
        "date-of-birth": "Date Of Birth",
        "date-range": "Date Range",
        "day": "Day",
        "day(s)": "Day(s)",
        "days": "Days",
        "dedicated-support": "Dedicated Support",
        "descending":"Descending",
        "description": "Description",
        "developers": "Developers",
        "departing-on": "Departing On",
        "departure": "Departure",
        "departure-airport":"Departure Airport",
        "departure-date": "departure-date",
        "departure-time": "Departure Time",
        "departure-time-in-origin": "Departure Time in Origin",
        "desired-pick-up-time": "Desired pick-up time",
        "destination": "Destination",
        "details": "DETAILS",
        "dinner": "Dinner",
        "discover-how-they-work,-what-tools-they-offer-and-how-to-choose-the-right-one-for-your-business": "Discover how they work, what tools they offer and how to choose the right one for your business.",
        "door": "Door",
        "doors": "doors",
        "domestic-flights": "Domestic Flights",
        "domestic-hotels": "Domestic Hotels",
        "driver-details": "Driver Details",
        "driver-info": "Driver Info",
        "driver-nation": "Driver Nation",
        "driver-nationality": "Driver Nationality",
        "drop-off-date-time": "Drop Off Date Time",
        "drop-off-location": "Drop Off Location",
        "duration": "Duration",
        "edit-profile": "Edit Profile",
        "email-id": "Email-ID",
        "email": "Email",
        "email-address": "Email address",
        "extra-price": "Extra Price",
        "explore": "EXPLORE",
        "extra": "Extra",
        "extra-service": "Extra Service",
        "facilities": "Facilities",
        "faq": "FAQ",
        "fare": "Fare",
        "fare-details": "FARE DETAILS",
        "flight-arrival-time":"Flight Arrival Time",
        "flight-departure-time":"FLight Departure Time",
        "flight-details":"Flight Details",
        "flight-preference": "Flight Preference",
        "flight-number": "Flight Number",
        "flight-tickets": "Tickets",
        "flight-total-payable": "Flight Total Payable",
        "flights": "Flights",
        "flying-time": "Flying Time",
        "food-preference": "Food Preference",
        "forget-password": "Forget Password",
        "found-matching-your-search": "Found Matching Your Search",
        "free-cancel": "Free Cancel",
        "fees": "Fees",
        "fees-and-taxes": "Fees And Taxes",
        "fees-info": "Fees Info",
        "fees-total": "Fees Total",
        "first-name": "First name",
        "first-trip": "First Trip",
        "first-trip-description": "First Trip Description",
        "fit": "Fit",
        "fit-description": "Fit Description",
        "free-cancel-before": "Free Cancel before",
        "free-cancel-date": "Free Cancel Date",
        "free-cancel-date(before)": "Free cancel date(before)",
        "from": "From",
        "get-latest-status": "Get latest status",
        "go-back": "Go Back",
        "guest-per-room": "Guest (Per Room)",
        "handpicked-tour": "Handpicked Tour",
        "hi": "Hi",
        "high-to-low": "High To Low",
        "holder-details": "Holder Details",
        "holder-info": "Holder Info",
        "hotel": "Hotel",
        "hotel-booking-overview": "Hotel Booking Overview",
        "hotel-info": "Hotel Info",
        "hotel-name": "Hotel Name",
        "hotels": "Hotels",
        "hot-deals": "HOT DEALS",
        "hotel+flights": "HOTEL+FLIGHTS",
        "hotel-preference": "Hotel Preference",
        "how-it-works?": "HOW IT WORKS?",
        "how-many-tickets?": "How many tickets?",
        "i-want-to-go": "I Want To Go",
        "important-info":"Important Info",
        "infant": "Infant",
        "infant(s)": "Infant(s)",
        "international-flights": "International Flights",
        "international-hotels": "International Hotels",
        "know-more": "Know More",
        "language": "Language",
        "last-name": "Last name",
        "leaving-from": "Leaving From",
        "leaving-to": "Leaving To",
        "included": "Included",
        "instant-confirmation": "Instant confirmation",
        "insurance-total":"Insurance Total",
        "it-allows-you-to-engage-them-when-they;re-researching,-planning-and-booking-their-next-trip.": "it allows you to engage them when they;re researching, planning and booking their next trip.",
        "itinerary": "Itinerary",
        "location": "Location",
        "login": "LOGIN",
        "lorem-ipsum-is-simply-dummy-text.-lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry.": "Lorem Ipsum is simply dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "low-to-high": "Low to high",
        "lowest-price": "Lowest Price",
        "lunch": "Lunch",
        "lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry.lorem-ipsum-has-been-the-industrys-standard-dummy-text-ever-since-the-1500s.": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",
        "make": "Make",
        "mall-road,-shimla,-himachal-pradesh,-176077": "Mall Road, Shimla, Himachal Pradesh, 176077",
        "meals": "Meals",
        "mode": "Mode",
        "more-info": "More Info",
        "multi-trip": "Multi Trip",
        "must-check-pick-up-time": "Must check pick-up time",
        "name": "Name",
        "manage-your-account": "Manage Your Account",
        "meal": "Meal",
        "minimum-order": "Minimum Order",
        "modify": "Modify",
        "modify-search": "MODIFY SEARCH",
        "must-check-pick-up-time.": "Must check pick-up time.",
        "need-help?": "Need Help?",
        "need-help?-call-us-or-drop-a-message.-our-agents-will-be-in-touch-shortly.": "Need Help? Call us or drop a message. Our agents will be in touch shortly.",
        "none": "None",
        "non-cancellable": "Non-cancellable",
        "non-refundable": "Non-refundable",
        "not-need-to-check-pick-up-time": "Not need to check pick-up time",
        "neighborhood": "Neighborhood",
        "next-step": "Next Step",
        "new-password": "New Password",
        "night": "Night",
        "no-available-car-transfer!": "No available car transfer!",
        "no-available-room": "No available room. Please select other available hotels.",
        "no-matched-booking":"No matched booking",
        "no-similar-hotel-": "No similar hotel ",
        "not-need-to-check-pick-up-time.": "Not need to check pick-up time.",
        "offer-ml":"Meal",
        "offer-bg":"Baggage",
        "offer-sa":"Seat",
        "offer-ie":"Entertainment",
        "offer-infant":"Infant",
        "offer-md":"Medical Service -- wheel chair / oxygen etc",
        "offer-po":"Priority",
        "one-way": "One Way",
        "our-services": "Our Services",
        "old-password": "Old Password",
        "others": "Others",
        "overview": "Overview",
        "partners": "Partners",
        "password": "Password",
        "person": "Person",
        "personal-information": "Personal Information",
        "pickup": "Pickup",
        "pickup-location": "Pickup Location",
        "please-enter-contact-number.": "Please enter Contact Number.",
        "policy":"policy",
        "price-range": "Price Range",
        "print--voucher": "PRINT  VOUCHER",
        "print-voucher": "Print Voucher",
        "privacy-policy": "Privacy Policy",
        "profile": "Profile",
        "passenger": "Passenger",
        "passenger-information": "Passenger Information",
        "passenger-name": "Passenger Name",
        "passengers": "Passengers",
        "passengers-information": "Passengers Information",
        "passport-country":"Passport Country",
        "passport-expire-date":"Passport Expire Date",
        "passport-number":"Passport Number",
        "per-day": "PER DAY",
        "per-night": "PER NIGHT",
        "per-pax": "PER PAX",
        "per-trip": "Per Trip",
        "phone-number": "Phone number",
        "pick-date-time": "Pick Date Time",
        "pick-up-date": "Pick Up Date",
        "pick-up-date-time": "Pick Up Date Time",
        "pick-up-location": "Pick Up Location",
        "pick-up-remarks": "Pick Up Remarks",
        "picnic-hotel-bangkok": "Picnic Hotel Bangkok",
        "please-enter-phone-number": "Please enter phone number",
        "popular-things-to-do": "Popular Things to do",
        "price": "Price",
        "price-detail": "PRICE DETAIL",
        "price-details": "Price Details",
        "price-per-night": "Price Per Night",
        "price-per-room": "Price Per Room",
        "price-policy": "Price Policy",
        "price/night": "Price/Night",
        "print-e-tickets": "Print E-tickets",
        "product-name": "Product Name",
        "product-type":"Product Type",
        "questions": "Questions",
        "rate-comments": "Rate Comments",
        "rating": "Rating",
        "reasons-to-trust-us": "REASONS TO TRUST US",
        "recent-booking": "Recent Booking",
        "record-locator": "Record Locator",
        "register": "REGISTER",
        "register-me": "REGISTER ME",
        "reservation-status": "Reservation status",
        "reservation-total": "Reservation Total",
        "reserve-now": "Reserve Now",
        "reset": "Reset",
        "result": "Result(s)",
        "result(s)-found-matching-your-search":"Result(s) Found Matching Your Search",
        "read-more": "READ MORE",
        "region": "Region",
        "remark": "Remark",
        "remove-last-flight": "Remove last flight",
        "rental-days": "Rental Days",
        "required": "Required",
        "reservation-total-price": "Reservation Total Price",
        "return": "return",
        "return-date-time": "Return Date Time",
        "return-location": "Return Location",
        "return-on": "Return On",
        "romantic-france": "Romantic France",
        "room": "Room",
        "room(s)": "Room(s)",
        "room-types": "Room Types",
        "room-info": "Room Info",
        "room-quantity": "Room quantity",
        "room-type": "Room Type",
        "rooms": "Rooms",
        "round": "Round",
        "round-trip": "Round Trip",
        "save": "Save",
        "save-changes": "Save Changes",
        "save-more": "SAVE MORE",
        "search-booking": "Search Booking",
        "segmentation-group": "Segmentation Group",
        "select-date": "Select Date",
        "select-trip": "Select Trip",
        "selected-departure-trip": "SELECTED DEPARTURE TRIP",
        "search": "Search",
        "search-car-transfer": "Search Car Transfer",
        "search-flights": "Search Flights",
        "search-form": "Search Form",
        "search-hotels": "Search Hotels",
        "search-package": "Search Package",
        "search-perfect-vehicle": "Search Perfect Vehicle",
        "search-tour": "Search Tour",
        "search-vehicles": "Search Vehicles",
        "second-trip": "Second Trip",
        "second-trip-description": "Second Trip Description",
        "select": "Select",
        "send-email": "Send Email",
        "session": "Session",
        "ship-name": "Ship Name",
        "shuttle-info": "Shuttle Info",
        "sign-in": "Sign In",
        "sign-out": "sign-out",
        "sign-up": "SIGN UP",
        "similar-hotel": "Similar Hotel",
        "star": "Star",
        "star-rating": "Star Rating",
        "suitcase": "Suitcase",
        "special-request": "Special Request",
        "start-date": "Start Date",
        "street-#156-burbank,-studio-city-hollywood,-california-usa": "Street #156 Burbank, Studio City Hollywood, California USA",
        "submit": "Submit",
        "subscribe": "SUBSCRIBE",
        "subscribe-to-our-newsletter": "SUBSCRIBE TO OUR NEWSLETTER",
        "suggestions-for-you": "Suggestions For You",
        "supplier-max-waiting-time": "Supplier Max Waiting Time",
        "taxes": "Taxes",
        "taxes-and-fees": "Taxes And Fees",
        "taxes-total": "Taxes Total",
        "telephone-number": "Telephone Number",
        "terms-of-use": "Terms Of Use",
        "thank-you": "THANK YOU",
        "the": "The",
        "things-to-do": "Things to do",
        "things-to-do-date": "Things to do Date",
        "ticket-of-adult(s)": "Ticket Of Adult(s)",
        "ticket-of-child(ren)": "Ticket Of Child(ren)",
        "ticket-number": "Ticket Number",
        "ticket(s)-and-price":"Ticket(s) And Price",
        "tickets": "Tickets",
        "title": "Title",
        "ticket(s)": "Ticket(s)",
        "to": "to",
        "top-destination": "TOP DESTINATION",
        "total": "Total",
        "transit(s)":"Transit(s)",
        "transmission": "Transmission",
        "travle-hassel-with-a-companies-recognized-for-professional-services": "Travle hassel with a companies recognized for professional services",
        "trip-way": "Trip Way",
        "total-pay": "Total Pay",
        "total-payment-to-be-made": "Total Payment to be made",
        "tour-group-name": "Tour Group Name",
        "tour-info": "Tour Info",
        "tour-name": "Tour Name",
        "tours": "Tours",
        "train-arrival-time":"Train Arrival Time",
        "train-departure-time": "Train Departure Time",
        "train-number": "Train Number",
        "update": "Update",
        "useful-links": "Useful Links",
        "username": "Username",
        "user-rating": "User Rating",
        "vehicle-name": "Vehicle Name",
        "vehicle-type": "Vehicle Type",
        "view-details": "View Details",
        "we-accept": "We Accept",
        "welcome": "Welcome",
        "why-choose-us?": "WHY CHOOSE US?",
        "will-incur-a": "will incur a",
        "with": "With",
        "with-advanced-technology-and-superior-customer-services,": "With advanced technology and superior customer services,",
        "with-air-conditioning": "With Air Conditioning",
        "without": "Without",
        "wild-africa": "Wild Africa",
        "wonderful-lodnon": "Wonderful Lodnon",
        "years-old": "Years Old",
        "you-need-to-pay": "You Need To Pay",
        "you-pay": "You Pay",
        "youth": "youth",
        "your-selection": "Your Selection",
        "your-booking-is-confirmed":"Your booking is confirmed"
    },
    zh_CN: {},
    zh_HK: {
        "1-room-fits": "一個房間適合",
        "about-hotel-description": "酒店描述",
        "ascending":"升序",
        "add-a-flight": "添加航程",
        "address": "地址",
        "adult": "成人",
        "adult(s)": "成人",
        "adult(s)-to": "成人到",
        "adult-fare": "成人價格",
        "adults": "成人",
        "age": "歲數",
        "air-reservation": "機票訂單",
        "air-tickets": "機票",
        "airline": "航空公司",
        "airline-record-locator": "航空公司記錄編號",
        "all": "全部",
        "amenities": "便利設施",
        "amenity": "便利設施",
        "amenity-of-hotel": "酒店設施",
        "ancillary-services": "附加服務",
        "area-code": "地區代碼",
        "arrival": "到達",
        "arrival-airport": "到達機場",
        "arrival-time":"到達時間",
        "arrival-time-in-destination":"目的地到達時間",
        "awesome-amsterdam": "Awesome Amsterdam",
        "avg": "平均",
        "basic-description": "基本描述",
        "become-a-partner": "成為夥伴",
        "best-price": "最低價格",
        "beach-goa": "Beach Goa",
        "bed-type": "床型",
        "before": "之前",
        "blog": "BLOG",
        "book": "預定",
        "book-now": "現在預定",
        "book-car-transfer": "預定車接",
        "book-flight-tickets": "預定機票",
        "book-holiday-package": "Book Holiday Package",
        "book-hotel-rooms": "預定房間",
        "book-tour-activity": "預定門票",
        "booking-create-date":"訂單創建日期",
        "booking-engine": "Booking Engine",
        "booking-date": "預定時間",
        "booking-id": "訂單標識",
        "booking-number": "訂單單號",
        "booking-overview": "訂單概覽",
        "booking-status": "訂單狀態",
        "booking-total": "訂單總價",
        "bookings": "訂單",
        "breakfast": "早餐",
        "brand": "品牌",
        "cabin-class": "艙型",
        "cancellable": "可取消",
        "cancel": "取消",
        "cancellations-after": "之後取消",
        "cancelled": "已取消",
        "cancel-policy": "取消策略",
        "car-brand": "汽車品牌",
        "car-details": "汽車詳情",
        "car-rental-price": "租賃費用",
        "career": "Career",
        "car-features": "特性",
        "car-info": "汽車信息",
        "change-password": "修改密碼",
        "confirm-password": "確認密碼",
        "contact-hotel": "聯繫酒店",
        "contact-number": "聯繫號碼",
        "contract-remarks": "備註",
        "current-address": "當前地址",
        "car-name": "汽車名",
        "car-rental": "租車",
        "car-rental-info": "租賃信息",
        "car-transfer": "車接",
        "car-transfer-info-first-trip": "車接 - 第一程",
        "car-transfer-info-second-trip": "車接 - 第二程",
        "car-transfer-total-price": "總費用",
        "cars": "車",
        "casino-los-vages": "Casino Los Vages",
        "check-date": "日期",
        "check-in": "入住",
        "check-in-date": "入住日期",
        "check-out": "退房",
        "check-out-date": "退房時間",
        "child": "兒童",
        "child-age": "兒童歲數",
        "child(ren)": "兒童",
        "child-fare": "兒童價",
        "city-name": "城市名",
        "class": "類型",
        "departure-date": "離開時間",
        "departure-time": "起飛時間",
        "departure-time-in-origin": "始發地起飛時間",
        "home": "首頁",
        "package": "機+酒",
        "return-date": "回程時間",
        "contact-agent": "聯繫代理商",
        "contact-person": "聯繫人",
        "contact-us": "聯係我們",
        "contact": "聯繫人",
        "continue": "繼續",
        "copyright-©-2021-limpidthemes.-all-rights-reserved.": "Copyright © 2021 LimpidThemes. All Rights Reserved.",
        "country": "國家",
        "country-code": "國家代碼",
        "coverage-info": "附加信息",
        "cruise": "遊輪",
        "cruise-holidays": "Cruise Holidays",
        "currencytag": "貨幣",
        "customer-max-waiting-time": "顧客最大等待時間",
        "date-of-birth": "出生日期",
        "date-range": "日期範圍",
        "day": "天",
        "day(s)": "天",
        "days": "天",
        "dedicated-support": "專用支持",
        "descending":"降序",
        "description": "描述",
        "developers": "開發者",
        "dinner": "晚餐",
        "discover-how-they-work,-what-tools-they-offer-and-how-to-choose-the-right-one-for-your-business": "Discover how they work, what tools they offer and how to choose the right one for your business.",
        "door": "門",
        "doors": "門",
        "departing-on": "出發日期",
        "departure": "離開",
        "departure-airport":"出發機場",
        "desired-pick-up-time": "需要接駁時間",
        "destination": "目的地",
        "details": "詳情",
        "domestic-flights": "國內航班",
        "domestic-hotels": "國內酒店",
        "driver-details": "司機詳情",
        "driver-info": "司機信息",
        "driver-nation": "司機國籍",
        "driver-nationality": "司機國籍",
        "drop-off-date-time": "落車時間",
        "drop-off-location": "落車地點",
        "duration": "期間",
        "edit-profile": "修改信息",
        "email-id": "郵件",
        "email": "郵件",
        "email-address": "郵件地址",
        "extra-price": "額外費用",
        "explore": "探索",
        "extra": "附加",
        "extra-service": "附加服務",
        "facilities": "設施",
        "faq": "快速問答",
        "fare": "費用",
        "fare-details": "費用詳情",
        "flight-arrival-time":"飛機到達時間",
        "flight-departure-time":"飛機起飛時間",
        "flight-preference": "航班偏好",
        "flight-details":"飛機詳情",
        "flight-number": "飛機號",
        "flight-tickets": "機票",
        "flight-total-payable": "機票總額",
        "flights": "飛機",
        "flying-time":"飛行時間",
        "food-preference": "食物偏好",
        "forget-password": "Forget Password",
        "found-matching-your-search": "匹配您的搜索條件",
        "free-cancel": "免費取消",
        "fees": "費用",
        "fees-and-taxes": "費用與稅費",
        "fees-info": "費用信息",
        "fees-total": "費用總額",
        "first-name": "名",
        "first-trip": "第一行程",
        "first-trip-description": "第一行程描述",
        "fit": "適合",
        "fit-description": "適合描述",
        "free-cancel-before": "免費取消",
        "free-cancel-date": "免費取消日期",
        "free-cancel-date(before)": "免費取消日期（前）",
        "from": "從",
        "get-latest-status": "獲取最新狀態",
        "go-back": "回退",
        "guest-per-room": "客人/房間",
        "handpicked-tour": "精選之旅",
        "hi": "你好",
        "high-to-low": "由高到低",
        "holder-details": "持有人詳細信息",
        "holder-info": "持有人信息",
        "hotel": "酒店",
        "hotel-booking-overview": "酒店訂單概覽",
        "hotel-info": "酒店信息",
        "hotel-name": "酒店名",
        "hotels": "酒店",
        "hot-deals": "熱門訂單",
        "hotel+flights": "機+酒",
        "hotel-preference": "酒店偏好",
        "how-it-works?": "如何工作？",
        "how-many-tickets?": "多少張票？",
        "i-want-to-go": "我想要去",
        "important-info":"重要信息",
        "infant": "嬰兒",
        "infant(s)": "嬰兒",
        "international-flights": "國際航班",
        "international-hotels": "國際酒店",
        "know-more": "了解更多",
        "language": "語言",
        "last-name": "姓",
        "leaving-from": "離開",
        "leaving-to": "去往",
        "included": "包含",
        "instant-confirmation": "及時確認",
        "insurance-total":"保险总费用",
        "it-allows-you-to-engage-them-when-they;re-researching,-planning-and-booking-their-next-trip.": "it allows you to engage them when they;re researching, planning and booking their next trip.",
        "itinerary": "行程",
        "location": "位置",
        "login": "登入",
        "lorem-ipsum-is-simply-dummy-text.-lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry.": "Lorem Ipsum is simply dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "low-to-high": "由低到高",
        "lowest-price": "最優惠價格",
        "lunch": "午餐",
        "lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry.lorem-ipsum-has-been-the-industrys-standard-dummy-text-ever-since-the-1500s.": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",
        "make": "製造",
        "mall-road,-shimla,-himachal-pradesh,-176077": "Mall Road, Shimla, Himachal Pradesh, 176077",
        "meals": "餐飲",
        "mode": "模式",
        "more-info": "更多信息",
        "multi-trip": "多程",
        "must-check-pick-up-time": "必須確認接駁時間",
        "name": "名字",
        "manage-your-account": "管理賬號",
        "meal": "餐飲",
        "minimum-order": "最小訂單數",
        "modify": "修改",
        "modify-search": "重新搜索",
        "must-check-pick-up-time.": "必須確認接駁時間",
        "need-help?": "需要幫忙？",
        "need-help?-call-us-or-drop-a-message.-our-agents-will-be-in-touch-shortly.": "Need Help? Call us or drop a message. Our agents will be in touch shortly.",
        "none": "無",
        "non-cancellable": "不可取消",
        "non-refundable": "不可退款",
        "not-need-to-check-pick-up-time": "不需要確認接駁時間",
        "neighborhood": "鄰居",
        "next-step": "下一步",
        "new-password": "新密碼",
        "night": "晚",
        "no-available-car-transfer!": "無可用車接服務！",
        "no-available-room": "無空餘房間，請搜索其他酒店",
        "no-matched-booking":"無匹配訂單",
        "no-similar-hotel-": "無類似酒店",
        "offer-ml":"餐饮",
        "offer-bg":"行李",
        "offer-sa":"座位",
        "offer-ie":"娱乐",
        "offer-md":"医疗服务 -- 轮椅 / 氧气等",
        "one-way": "單程",
        "our-services": "我們的服務",
        "old-password": "舊密碼",
        "others": "其他",
        "overview": "概覽",
        "partners": "夥伴",
        "password": "密碼",
        "penalty": "罰款",
        "person": "人員",
        "personal-information": "個人信息",
        "pickup": "接",
        "pickup-location": "接駁地點",
        "please-enter-contact-number.": "請輸入聯繫號碼",
        "price-range": "價格區間",
        "print-voucher": "打印發票",
        "privacy-policy": "隱私權政策",
        "profile": "簡介",
        "passenger": "旅客",
        "passenger-information": "旅客信息",
        "passenger-name": "旅客名",
        "passengers": "旅客",
        "passengers-information": "旅客信息",
        "passport-country":"護照國家",
        "passport-expire-date":"護照過期時間",
        "passport-number":"護照編號",
        "per-day": "每天",
        "per-night": "每晚",
        "per-pax": "每人",
        "per-trip": "每行程",
        "phone-number": "電話號碼",
        "pick-date-time": "接駁時間",
        "pick-up-date": "接駁日期",
        "pick-up-date-time": "接駁日期時間",
        "pick-up-location": "接駁地點",
        "pick-up-remarks": "接駁備註",
        "picnic-hotel-bangkok": "Picnic Hotel Bangkok",
        "please-enter-phone-number": "請輸入電話號碼",
        "policy": "策略",
        "popular-things-to-do": "熱門門票",
        "price": "價格",
        "price-detail": "價格詳情",
        "price-details": "價格詳情",
        "price-per-night": "每晚價格",
        "price-per-room": "房間單價",
        "price-policy": "費用策略",
        "price/night": "價格/晚",
        "print-e-tickets": "打印電子發票",
        "product-name":"產品名",
        "product-type":"產品類型",
        "questions": "問題",
        "rate-comments": "费率解释",
        "rating": "評分",
        "reasons-to-trust-us": "相信我們的原因",
        "recent-booking": "最近訂單",
        "record-locator": "記錄編號",
        "register": "註冊",
        "register-me": "註冊",
        "reservation-status": "訂單狀態",
        "reservation-total": "訂單總價",
        "reserve-now": "現在預定",
        "reset": "重置",
        "result": "結果",
        "result(s)-found-matching-your-search":"個結果匹配您的搜索條件",
        "read-more": "閱讀更多",
        "region": "區域",
        "remark": "備註",
        "remove-last-flight": "移除最後的航程",
        "rental-days": "租賃天數",
        "required": "需要",
        "reservation-total-price": "訂單總價",
        "return": "返回",
        "return-date-time": "返回日期時間",
        "return-location": "返回地點",
        "return-on": "返回時間",
        "romantic-france": "Romantic France",
        "room": "房間",
        "room(s)": "房間",
        "room-types": "房型",
        "room-info": "房間信息",
        "room-quantity": "房間數量",
        "room-type": "房型",
        "rooms": "房間",
        "round": "去回",
        "round-trip": "去回程",
        "save": "保存",
        "save-changes": "保存修改",
        "save-more": "保存更多",
        "search-booking": "搜素訂單",
        "segmentation-group": "團詳情",
        "select-date": "選擇日期",
        "select-trip": "選擇行程",
        "selected-departure-trip": "已選擇離開行程",
        "search": "搜索",
        "search-car-transfer": "搜索車接",
        "search-flights": "搜索機票",
        "search-form": "搜索條件",
        "search-hotels": "搜索酒店",
        "search-package": "搜索機+酒",
        "search-perfect-vehicle": "搜索更符合的車輛",
        "search-tour": "搜索門票",
        "search-vehicles": "搜索車輛",
        "second-trip": "第二行程",
        "second-trip-description": "第二行程描述",
        "select": "選擇",
        "send-email": "發送郵件",
        "session": "時間段",
        "ship-name": "船名",
        "shuttle-info": "航班信息",
        "sign-in": "登入",
        "sign-out": "退出",
        "sign-up": "註冊",
        "similar-hotel": "類似酒店",
        "star": "星級",
        "star-rating": "星級",
        "suitcase": "手提箱",
        "special-request": "特殊請求",
        "start-date": "開始日期",
        "street-#156-burbank,-studio-city-hollywood,-california-usa": "Street #156 Burbank, Studio City Hollywood, California USA",
        "submit": "Submit",
        "subscribe": "訂閱",
        "subscribe-to-our-newsletter": "訂閱我們的新聞稿",
        "suggestions-for-you": "建議",
        "supplier-max-waiting-time": "供應商最大等待時間",
        "taxes": "稅費",
        "taxes-and-fees": "稅費和費用",
        "taxes-total": "總稅費",
        "telephone-number": "電話號碼",
        "terms-of-use": "使用條款",
        "thank-you": "謝謝",
        "the": "第",
        "things-to-do": "門票",
        "things-to-do-date": "門票日期",
        "ticket-of-adult(s)": "成人票",
        "ticket-of-child(ren)": "兒童票",
        "ticket-number":"票號",
        "ticket(s)-and-price":"門票與費用",
        "tickets": "門票",
        "title": "稱謂",
        "ticket(s)": "票券",
        "to": "到",
        "top-destination": "熱門地點",
        "total": "總共",
        "transit(s)":"中轉",
        "transmission": "運輸",
        "travle-hassel-with-a-companies-recognized-for-professional-services": "Travle hassel with a companies recognized for professional services",
        "trip-way": "行程",
        "total-pay": "總共支付",
        "total-payment-to-be-made": "需要支付的總額",
        "tour-group-name": "旅遊團名",
        "tour-info": "旅遊信息",
        "tour-name": "旅遊名",
        "tours": "旅遊",
        "train-arrival-time": "火車到達時間",
        "train-departure-time": "火車離開時間",
        "train-number": "火車號",
        "update": "更新",
        "useful-links": "有用的鏈接",
        "username": "用戶名",
        "user-rating": '用戶評分',
        "vehicle-name": "車名",
        "vehicle-type": "車類型",
        "view-details": "查看詳情",
        "we-accept": "我們接受",
        "welcome": "歡迎",
        "why-choose-us?": "為什麼選擇我們",
        "will-incur-a": "將會導致",
        "with": "有",
        "with-advanced-technology-and-superior-customer-services,": "With advanced technology and superior customer services,",
        "with-air-conditioning": "空調",
        "without": "沒有",
        "wild-africa": "Wild Africa",
        "wonderful-lodnon": "Wonderful Lodnon",
        "years-old": "歲",
        "you-need-to-pay": "你需要支付",
        "you-pay": "支付",
        "youth": "年輕人",
        "your-selection": "你選擇了",
        "your-booking-is-confirmed":"你的訂單已經確認"
    }
}

export default {
    messages
}
