
const getters = {
    flightItineraries(state){
        return state.flightItineraries;
    },
    offerMap(state){
        return state.offerMap;
    },
    originDestinationMap(state){
        return state.originDestinationMap;
    },
    originDestinationShow(state){
        return state.originDestinationShow;
    },
    airlineOptions(state){
        return state.airlineOptions;
    },
    departureAirportOptions(state){
        return state.departureAirportOptions;
    },
    arrivalAirportOptions(state){
        return state.arrivalAirportOptions;
    },
    pricedItineraries(state){
        return state.lowFareSearchRS.pricedItineraryList;
    },
    verifyPricedItineraryInfo(state){
        return state.verifyPricedItineraryInfo;
    },
    fareRuleMap(state){
        return state.fareRuleMap;
    },
    offers(state){
        return state.offers;
    },
    seatMaps(state){
        return state.seatMaps;
    },
};

export default getters;
