import * as mutationName from "@/store/modules/hotel/mutationTypes";
import HotelAPI from "@/apis/HotelAPI";
import HotelUtils from "@/utils/HotelUtils";
import {ElMessage} from "element-plus";

const actions = {
    async searchCityHotels({commit}, payload){
        await  HotelAPI.avail(payload.destinationId, payload.ttiCode, payload.bestOnlyIndicator,payload.checkDate[0], payload.checkDate[1],
            payload.roomNum, payload.adultNum, payload.childNum, payload.language, payload.childAges).then(res =>{
            let cityHotelsAvailRS = res.data;
            commit(mutationName.UPDATE_CITY_HOTELS_AVAIL, {
                cityHotelsAvailRS: cityHotelsAvailRS,
                pageSize: payload.pageSize
            });
        }).catch(error => {
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });
    },
    paginationHotels({commit},payload){
        commit(mutationName.UPDATE_HOTELS_SHOW, payload);
    },
    searchHotelDetails({commit}, payload){
        HotelAPI.avail(null, payload.ttiCode, payload.bestOnlyIndicator, payload.checkDate[0], payload.checkDate[1],
            payload.roomNum, payload.adultNum, payload.childNum, payload.language, payload.childAges, payload.importantType).then(res =>{
            let hotelDetailsAvailRS = res.data;
            // //console.info(hotelDetailsAvailRS);
            commit(mutationName.UPDATE_HOTEL_DETAILS_AVAIL, {
                hotelDetailsAvailRS: hotelDetailsAvailRS
            })
        }).catch(error => {
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });
    },
    searchHotelDescription({commit}, payload){
        HotelAPI.descriptive(payload.ttiCode, payload.language).then(res =>{
            //console.info(res.data);
            let hotelDescriptionRS = res.data;
            commit(mutationName.UPDATE_HOTEL_DESCRIPTION, {
                hotelDescriptionRS: hotelDescriptionRS
            })
        }).catch(error => {
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });
    },
    confirm({commit}, payload){
        HotelAPI.confirm(payload.checkInDate, payload.checkOutDate, payload.roomStayList, payload.serviceList, payload.resGuestList).then(res =>{
            //console.info(res.data);
            let confirmRS = res.data;
            if (confirmRS.success){
                let preBookRQ = HotelUtils.getHotelResRQ("Initiate", payload.checkInDate, payload.checkOutDate, payload.roomStayList, payload.serviceList, payload.resGuestList);
                commit(mutationName.UPDATE_CONFIRM, {
                    confirmRS: confirmRS,
                    preBookRQ: preBookRQ
                })
            }else if (confirmRS.errors.errors){
                console.info(confirmRS.errors.errors);
                ElMessage({
                    message: "This is an instant confirmation booking and you do not have the permission to book.",
                    type: 'error'
                })
            }
        }).catch(error => {
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });
    },
    preBook({commit}, payload){
        HotelAPI.preBook(payload.preBookRQ).then(res =>{
            //console.info(res.data);
            let preBookRS = res.data;
            if (preBookRS.success){
                commit(mutationName.UPDATE_PREBOOK, {
                    preBookRS: preBookRS
                })
            }else {
                commit(mutationName.UPDATE_PREBOOK, {
                    preBookRS: null
                })
            }
        }).catch(error => {
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });
    },
    saveBook({commit}, payload){
        commit(mutationName.UPDATE_BOOK, {
            bookRS: payload.bookRS
        })
    },
}

export default actions;
