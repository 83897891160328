const state={
    /*列表页相关*/
    carTransferInfos:[],
    carTransferInfosShow:[],
    carTransfersInfo:{},
    minTransferPrice: 0,
    maxTransferPrice: 0,
    resultNum: 0,
    currencyCode: '',
    /*预定页面相关*/
    passengerInfo: {},
    carTransferBookingReview:{
        holder:{}
    },
    groundBookRQ:{},
    totalPayment: {}
};

export default state;
