import * as mutationName from "@/store/modules/booking/mutationTypes"
import BookingUtils from "@/utils/BookingUtils";

const mutations = {
    [mutationName.UPDATE_BOOKINGS_INFO](state, payload){
        if (payload.bookingsRS!=null && payload.bookingsRS.elements!=null && payload.bookingsRS.elements.length>0){
            state.bookingsRS = payload.bookingsRS;

            let bookingsShow = {
                offset: 0,
                limit: 0,
                total: 0,
                resultList: []
            };

            bookingsShow.limit = payload.bookingsRS.limit;
            bookingsShow.offset = payload.bookingsRS.offset;
            bookingsShow.total = payload.bookingsRS.totalElements;


            payload.bookingsRS.elements.forEach( booking => {


                if(booking.hotelReservations!=null && booking.hotelReservations.length>0){
                    let hotelBookingShow = BookingUtils.getHotelBookingShow(booking);
                    bookingsShow.resultList.push(hotelBookingShow);
                }else if (booking.vehicleReservations!=null && booking.vehicleReservations.length>0){
                    let vehicleBookingShow = BookingUtils.getVehicleBookingShow(booking);
                    bookingsShow.resultList.push(vehicleBookingShow);
                }else if (booking.groundReservations){
                    let carTransferBookingDetails = BookingUtils.getCarTransferBookingShow(booking);
                    bookingsShow.resultList.push(carTransferBookingDetails);
                }else if (booking.tourActivityReservations){
                    let activityBookingShow = BookingUtils.getActivityBookingShow(booking);
                    bookingsShow.resultList.push(activityBookingShow);
                }else if (booking.airReservations){
                    let flightBookingDetails = BookingUtils.getFlightBookingDetails(booking);
                    bookingsShow.resultList.push(flightBookingDetails);
                    console.info(flightBookingDetails)
                }

            });

            state.bookingsShow = bookingsShow;
            console.info(state.bookingsShow)

        }else {
            state.bookingsShow = {
                offset: 0,
                limit: 0,
                total: 0,
                resultList: []
            };
        }
    },

    [mutationName.UPDATE_BOOKING_DETAILS_INFO](state, payload){
        if (payload.bookingDetailsRS!=null && payload.bookingDetailsRS.elements!=null && payload.bookingDetailsRS.elements.length>0){


            let bookingDetails = payload.bookingDetailsRS.elements[0];

            if(bookingDetails.hotelReservations!=null && bookingDetails.hotelReservations.length>0){
                let hotelBookingDetails = BookingUtils.getHotelBookingDetails(bookingDetails);
                state.bookingDetailsShow = hotelBookingDetails;
            }else if (bookingDetails.vehicleReservations!=null && bookingDetails.vehicleReservations.length>0){
                let vehicleBookingDetails = BookingUtils.getVehicleBookingDetails(bookingDetails);
                state.bookingDetailsShow = vehicleBookingDetails;
            }else if (bookingDetails.groundReservations){
                let carTransferBookingDetails = BookingUtils.getCarTransferBookingDetails(bookingDetails);
                state.bookingDetailsShow = carTransferBookingDetails;
                console.info(carTransferBookingDetails)
            }else if (bookingDetails.airReservations){
                let flightBookingDetails = BookingUtils.getFlightBookingDetails(bookingDetails);
                state.bookingDetailsShow = flightBookingDetails;
                console.info(flightBookingDetails)
            }else if (bookingDetails.tourActivityReservations){
                let activityBookingDetails = BookingUtils.getActivityBookingDetails(bookingDetails);
                state.bookingDetailsShow = activityBookingDetails;
                console.info(activityBookingDetails)
            }


            //console.info(state.bookingDetailsShow)

        }else {
            state.profileReadRS = {};
        }
    },

};

export default mutations;
