import axios from "axios";
import app from "@/main";

let excludeUrls =[
    '/autocomplete',
    '/login-status'
];

let showProcessingMsgUrl =[
    '/payment',
    '/hotel/book'
]

axios.interceptors.request.use(req => {
    let exclude = false;
    let url = req.url;
    excludeUrls.forEach(excludeUrl => {
        if (url.indexOf(excludeUrl)!=-1){
            exclude = true;
        }
    });

    let showMsg = false;
    showProcessingMsgUrl.forEach(msgUrl => {
        if (url.indexOf(msgUrl)!=-1){
            showMsg = true;
        }
    });

    if (!exclude &&  showMsg){
        app.config.globalProperties.$toLoading.show('Processing the booking. Please wait a minute.');
    }else if (!exclude){
        app.config.globalProperties.$toLoading.show('');
    }

    //console.info(req);
    return Promise.resolve(req)
}, error => {
    app.config.globalProperties.$toLoading.hide();
    // console.info(error)
    return Promise.reject(error)
});

// 响应拦截
axios.interceptors.response.use(res => {
    let exclude = false;
    let url = res.config.url;
    excludeUrls.forEach(excludeUrl => {

        if (url.indexOf(excludeUrl)!=-1){
            exclude = true;
        }
    });

    if (!exclude){
        app.config.globalProperties.$toLoading.hide();
    }

    if (res.status === 200) {
        return Promise.resolve(res)
    }
}, error => {
    app.config.globalProperties.$toLoading.hide();
    let data = {};
    if (error.response.status === 401) {
         data = {
            code: -1,
            message: 'Session expired.'
        };
        app.config.globalProperties.$router.replace({
            path: '/loginPage',
            query: {
                auth: 0
            }
        })
    }
    // console.info(error)
    return Promise.reject(data)
});

export default axios;
