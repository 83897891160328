import HotelUtils from "@/utils/HotelUtils";
import axios from "@/config/AxiosConfig";

function avail(destinationId, ttiCode, bestOnlyIndicator, checkInDate, checkOutDate, roomNum, adultNum, childNum, language, childAges, importantType) {
    let hotelAvailRQ = HotelUtils.getHotelAvailRQ(destinationId, ttiCode, bestOnlyIndicator, checkInDate, checkOutDate, roomNum, adultNum, childNum, language, childAges, importantType);
    let url = "/api/v1/hotel/avail";
    //console.info(hotelAvailRQ);
    return axios.post(url, hotelAvailRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function descriptive(ttiCode, language) {
    let url = "/api/v1/hotel/description";
    let hotelDescriptiveRQ = HotelUtils.getHotelDescriptiveRQ(ttiCode, language);
    return axios.post(url, hotelDescriptiveRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function confirm(checkInDate, checkOutDate, roomStayList, serviceList, resGuestList) {
    let url = "/api/v1/hotel/confirm";
    let hotelResRQ = HotelUtils.getHotelResRQ("Hold", checkInDate, checkOutDate, roomStayList, serviceList, resGuestList);
    return axios.post(url, hotelResRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}
//
// function preBook(checkInDate, checkOutDate, roomStayList, serviceList, resGuestList) {
//     let url = "/api/v1/hotel/prebook";
//     let hotelResRQ = HotelUtils.getHotelResRQ("Initiate", checkInDate, checkOutDate, roomStayList, serviceList, resGuestList);
//     return axios.post(url, hotelResRQ,{
//         headers:{
//             "Content-Type": "application/json"
//         }
//     });
// }


function preBook(preBookRQ) {
    let url = "/api/v1/hotel/prebook";
    return axios.post(url, preBookRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function book(bookRQ) {
    let url = "/api/v1/hotel/book";
    return axios.post(url, bookRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

export default {
    avail,
    descriptive,
    confirm,
    preBook,
    book
}
