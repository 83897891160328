import state from "@/store/modules/flight/state";
import getters from "@/store/modules/flight/getters";
import actions from "@/store/modules/flight/actions";
import mutations from "@/store/modules/flight/mutations";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
