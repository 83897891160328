import { createStore } from 'vuex'
import hotel from "@/store/modules/hotel";
import flight from "@/store/modules/flight"
import carrental from "@/store/modules/carrental";
import tourActivity from "@/store/modules/touractivity";
import cartransfer from "@/store/modules/cartransfer";
import booking from "@/store/modules/booking";
import account from "@/store/modules/account";
import insurance from "@/store/modules/insurance";
import axios from  "@/store/modules/axios"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    hotel: hotel,
    carRental:carrental,
    booking: booking,
    account: account,
    carTransfer: cartransfer,
    tourActivity: tourActivity,
    flight: flight,
    insurance: insurance,
    axios: axios
  }
})
