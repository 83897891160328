import axios from "@/config/AxiosConfig";
import TourActivityUtils from "@/utils/TourActivityUtils";

function tourActivitySearch(destinationCode, startDate, endDate) {
    let tourActivitySearchRQ = TourActivityUtils.getTourActivitySearchRQ(destinationCode, startDate, endDate);
    let url = "/api/v1/touractivity/search";
    console.info(tourActivitySearchRQ);
    return axios.post(url, tourActivitySearchRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function tourActivityAvail(tourActivityID, startDate, endDate, participantCounts, groupCode) {
    let tourActivityAvailRQ = TourActivityUtils.getTourActivityAvailRQ(tourActivityID, startDate, endDate, participantCounts, groupCode);
    let url = "/api/v1/touractivity/avail";
    return axios.post(url, tourActivityAvailRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function tourActivityPreconfirm(rateKey, selectedDate, holder, username) {
    let tourActivityPreconfirmRQ = TourActivityUtils.getTourActivityPreconfirmRQ(rateKey, selectedDate, holder, username);
    let url = "/api/v1/touractivity/prebook";
    console.info(tourActivityPreconfirmRQ);
    return axios.post(url, tourActivityPreconfirmRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function tourActivityBook(tourActivityBookRQ) {
    let url = "/api/v1/touractivity/book";
    return axios.post(url, tourActivityBookRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}
// function tourActivityBook(rateKey, selectedDate, holder, username) {
//     let tourActivityPreconfirmRQ = TourActivityUtils.getTourActivityPreconfirmRQ(rateKey, selectedDate, holder, username);
//     let url = "/api/v1/v1/touractivity/book";
//     return axios.post(url, tourActivityPreconfirmRQ,{
//         headers:{
//             "Content-Type": "application/json"
//         }
//     });
// }

export default {
    tourActivitySearch,
    tourActivityAvail,
    tourActivityPreconfirm,
    tourActivityBook
}
