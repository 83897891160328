import * as mutationName from "@/store/modules/carrental/mutationTypes"
import CarRentalUtils from "@/utils/CarRentalUtils";
import FilterUtils from "@/utils/FilterUtils";
import SortUtils from "@/utils/SortUtils";

const mutations = {
    [mutationName.UPDATE_VEHICLES_INFO](state, payload){
        state.minVehiclePrice = 0;
        state.maxVehiclePrice = 0;
        if (payload.vehAvailRS!=null && payload.vehAvailRS.vehAvailRSCore!=null
                && payload.vehAvailRS.vehAvailRSCore.vehVendorAvails!=null
                && payload.vehAvailRS.vehAvailRSCore.vehVendorAvails.length>0
        ){
            let result = CarRentalUtils.getVehiclesByVehAvailRS(payload.vehAvailRS);
            state.vehicles= result.vehicles;
            state.vehicleResultNum = result.vehicles.length;
            state.minVehiclePrice = result.minPrice;
            state.maxVehiclePrice = result.maxPrice;
            state.resultNum = result.vehicles.length;
            state.currencyCode = result.currencyCode;

            //车品牌
            let vehicleBrandMap = {};
            let transmissionTypMap = {};
            state.vehicleBrands = [];
            state.transmissionTypes = [];
            state.vehicles.forEach(vehicle => {
                if (!vehicleBrandMap[vehicle.brand]){
                    vehicleBrandMap[vehicle.brand] = vehicle.brand;
                    state.vehicleBrands.push(vehicle.brand);
                }
                if (!transmissionTypMap[vehicle.transmissionType]){
                    transmissionTypMap[vehicle.transmissionType] = vehicle.transmissionType;
                    state.transmissionTypes.push(vehicle.transmissionType);
                }
            });



            state.vehiclesShow = [];
            let vehiclesShow = CarRentalUtils.getVehiclesShow(state.vehicles, 1, 10);
            console.info(vehiclesShow);
            state.vehiclesShow = vehiclesShow;
        }else {
            state.vehAvailRS = {};
            state.vehicles =[];
            state.vehiclesShow =[];
            state.vehicleResultNum = 0;
            state.minVehiclePrice = 0;
            state.maxVehiclePrice = 0;
            state.resultNum = 0;
            state.currencyCode = '';
        }
    },
    [mutationName.UPDATE_VEHICLES_SHOW](state, payload){
        let currentPage = payload.currentPage;
        let pageSize = payload.pageSize;
        let filterCondition = payload.filterCondition;
        let vehicles = state.vehicles;
        vehicles = FilterUtils.filterVehciles(vehicles, filterCondition);

        if (payload.sortCondition.sortAttribute!=null && payload.sortCondition.type!=null){
            vehicles = SortUtils.sort(vehicles,payload.sortCondition.sortAttribute, payload.sortCondition.type);
        }
        state.resultNum = vehicles==null?0:vehicles.length;

        state.vehiclesShow = [];
        let vehiclesShow = CarRentalUtils.getVehiclesShow(vehicles, currentPage, pageSize);
        state.vehiclesShow = vehiclesShow;
    },
    [mutationName.UPDATE_VEHICLE_DETAIL](state, payload){
        if (payload.vehAvailRS!=null && payload.vehAvailRS.vehAvailRSCore!=null
            && payload.vehAvailRS.vehAvailRSCore.vehVendorAvails!=null
            && payload.vehAvailRS.vehAvailRSCore.vehVendorAvails.length>0
        ){
            let vehicleDetail = CarRentalUtils.getVehicleDetail(payload.vehAvailRS);
            state.vehicleDetail = vehicleDetail;
            state.totalPayment = {
                currencyCode: vehicleDetail.currencyCode,
                amount: vehicleDetail.price
            }

        }else {
            state.vehicleDetail = {}
        }
    },
    [mutationName.UPDATE_VEHICLE_BOOKING](state, payload){
        if (payload.vehResRS!=null && payload.vehResRS.vehResRSCore!=null
            && payload.vehResRS.vehResRSCore.vehReservation!=null
        ){
            let vehBookingReviewInfo = CarRentalUtils.getVehBookingReviewInfo(payload.vehResRS.vehResRSCore.vehReservation);
            state.vehBookingReviewInfo = vehBookingReviewInfo;
            state.vehResRS = payload.vehResRS;

            state.totalPayment = {
                currencyCode: vehBookingReviewInfo.priceInfo.total.currencyCode ,
                amount: vehBookingReviewInfo.priceInfo.total.amount
            };
            //Construct 'book' status rq
            let vehicleResRQ = payload.vehicleResRQ;
            vehicleResRQ.vehResRQCore.status = 'book';
            vehicleResRQ.vehResRQCore.rateQualifier.rateQualifier = payload.vehResRS.vehResRSCore.vehReservation.vehSegmentCore.rentalRate.rateQualifier.rateQualifier;
            vehicleResRQ.vehResRQCore.uniqueIDs = payload.vehResRS.vehResRSCore.vehReservation.vehSegmentCore.confIDs;
            state.vehicleResRQBook = vehicleResRQ;
        }else {
            state.vehicleResRQBook = {}
        }
    },
    [mutationName.UPDATE_DRIVER_INFO](state, payload){
        if (payload.driverInfo!=null){
            state.driverInfo = payload.driverInfo;
        }else {
            state.driverInfo = {};
        }
    }
};

export default mutations;
