
import { createApp, reactive } from 'vue'

import toLoading from './toLoading.vue'

const toLoadingObject = reactive({
  show: false,
  text:''
})

const $toLoading = createApp(toLoading, {toLoadingObject}).mount(document.createElement('div'))
// console.log($loading);
const load = {
    show(text) { // 控制显示loading的方法
      toLoadingObject.show = true;
      toLoadingObject.text = text;
      document.body.appendChild($toLoading.$el);
      document.body.style.overflow = 'hidden';
    },

    hide() { // 控制loading隐藏的方法
      toLoadingObject.show = false;
      // toLoadingObject.text = '';
      document.body.style.overflow = 'visible';
    }
}

export default {
    install(app) {
        // console.log(app);
        // Vue.prototype.$http = axios
        app.config.globalProperties.$toLoading = load;
    }
}
