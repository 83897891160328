function filterHotels(arr, condition) {
    let arrAfterFilter
    arrAfterFilter = filterAllConditions(arr, 'price', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'name', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'star', condition);
    return arrAfterFilter;
}


function getHotelFilterCondition(hotelsFilterCondition) {
    let filterCondition={
        priceFilterCondition: {},
        starFilterCondition: {},
        nameFilterCondition: {}
    };

    let priceRangeModel = hotelsFilterCondition.priceRangeModel;
    filterCondition.priceFilterCondition.minPrice = priceRangeModel[0];
    filterCondition.priceFilterCondition.maxPrice = priceRangeModel[1];

    let hotelNameModel = hotelsFilterCondition.hotelNameModel;
    filterCondition.nameFilterCondition.keyword = hotelNameModel.keyword;

    let starFilterCondition = null;
    if (hotelsFilterCondition.starsModel.length>0)starFilterCondition={};
    hotelsFilterCondition.starsModel.forEach( starCondition => {

        starFilterCondition[starCondition] = starCondition;
    });
    filterCondition.starFilterCondition = starFilterCondition;

    return filterCondition;
}

/* 开始 租车相关*/
function filterVehciles(arr, condition){
    let arrAfterFilter
    arrAfterFilter = filterAllConditions(arr, 'price', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'brand', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'transmission', condition);
    return arrAfterFilter;
}

function getVehiclesFilterCondition(vehiclesFilterCondition) {
    let filterCondition={
        priceFilterCondition: {},
        transmissionFilterCondition: {},
        brandFilterCondition: {}
    };

    let priceRangeModel = vehiclesFilterCondition.priceRangeModel;
    filterCondition.priceFilterCondition.minPrice = priceRangeModel[0];
    filterCondition.priceFilterCondition.maxPrice = priceRangeModel[1];


    let transmissionFilterCondition = null;
    if (vehiclesFilterCondition.transmissionTypeModel.length>0)transmissionFilterCondition={};
    vehiclesFilterCondition.transmissionTypeModel.forEach( transmission => {

        transmissionFilterCondition[transmission] = transmission;
    });
    filterCondition.transmissionFilterCondition = transmissionFilterCondition;

    let brandFilterCondition = null;
    if (vehiclesFilterCondition.carBrandModel.length>0)brandFilterCondition={};
    vehiclesFilterCondition.carBrandModel.forEach( brand => {
        brandFilterCondition[brand] = brand;
    });
    filterCondition.brandFilterCondition = brandFilterCondition;

    return filterCondition;
}
/* 结束 租车相关*/

/* 开始 车接相关*/
function filterCarTransfers(arr, condition){
    let arrAfterFilter = filterAllConditions(arr, 'price', condition);
    return arrAfterFilter;
}

function getCarTransferFilterCondition(carTransferFilterCondition) {
    let filterCondition={
        priceFilterCondition: {}
    };

    let priceRangeModel = carTransferFilterCondition.priceRangeModel;
    filterCondition.priceFilterCondition.minPrice = priceRangeModel[0];
    filterCondition.priceFilterCondition.maxPrice = priceRangeModel[1];

    return filterCondition;
}
/* 结束 车接相关*/

/* 开始 门票相关*/
function filterActivities(arr, condition){
    let arrAfterFilter
    arrAfterFilter = filterAllConditions(arr, 'price', condition);
    return arrAfterFilter;
}

function getActivityFilterCondition(activityFilterCondition) {
    let filterCondition={
        priceFilterCondition: {}
    };

    let priceRangeModel = activityFilterCondition.priceRangeModel;
    filterCondition.priceFilterCondition.minPrice = priceRangeModel[0];
    filterCondition.priceFilterCondition.maxPrice = priceRangeModel[1];

    return filterCondition;
}
/* 结束 门票相关*/

/* 开始 机票相关*/
function filterFlights(arr, condition) {
    let arrAfterFilter = filterAllConditions(arr, 'price', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'transit', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'airline', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'departureInterval', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'arrivalInterval', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'departureAirportCode', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'arrivalAirportCode', condition);
    arrAfterFilter = filterAllConditions(arrAfterFilter, 'flyingTime', condition);
    return arrAfterFilter;
}

function getFlightFilterCondition(flightFilterCondition) {
    let filterCondition = {
        priceFilterCondition: {},
        transitFilterCondition:{},
        airlineFilterCondition:{},
        departIntervalFilterCondition:{},
        arrivalIntervalFilterCondition:{},
        departAirportFilterCondition:{},
        arrivalAirportFilterCondition:{},
        flyingTimeRangeFilterCondition:{},
    };

    let priceRangeModel = flightFilterCondition.priceRangeModel;
    filterCondition.priceFilterCondition.minPrice = priceRangeModel[0];
    filterCondition.priceFilterCondition.maxPrice = priceRangeModel[1];

    let transitModel = flightFilterCondition.transitModel;
    let transitFilterCondition = null;
    if (transitModel && Object.keys(transitModel).length>0){
        transitFilterCondition = {};
        transitModel.forEach( transit => {
            transitFilterCondition[transit] = transit;
        });
    }
    filterCondition.transitFilterCondition = transitFilterCondition;

    let airlineModel = flightFilterCondition.airlineModel;
    let airlineFilterCondition = null;
    if (airlineModel && Object.keys(airlineModel).length>0){
        airlineFilterCondition = {};
        airlineModel.forEach( airline => {
            airlineFilterCondition[airline] = airline;
        });
    }
    filterCondition.airlineFilterCondition = airlineFilterCondition;

    let departIntervalModel = flightFilterCondition.departureIntervalModel;
    let departIntervalFilterCondition = null;
    if (departIntervalModel && Object.keys(departIntervalModel).length>0){
        departIntervalFilterCondition = {};
        departIntervalModel.forEach(departInterval => {
            departIntervalFilterCondition[departInterval] = departInterval;
        })
    }
    filterCondition.departIntervalFilterCondition = departIntervalFilterCondition;

    let arrivalIntervalModel = flightFilterCondition.arrivalIntervalModel;
    let arrivalIntervalFilterCondition = null;
    if (arrivalIntervalModel && Object.keys(arrivalIntervalModel).length>0){
        arrivalIntervalFilterCondition = {};
        arrivalIntervalModel.forEach(arrivalInterval => {
            arrivalIntervalFilterCondition[arrivalInterval] = arrivalInterval;
        })
    }
    filterCondition.arrivalIntervalFilterCondition = arrivalIntervalFilterCondition;

    let departAirportModel = flightFilterCondition.departureAirportModel;
    let departAirportFilterCondition = null;
    if (departAirportModel  && Object.keys(departAirportModel).length>0){
        departAirportFilterCondition={}
        departAirportModel.forEach(departAirport => {
            departAirportFilterCondition[departAirport] = departAirport;
        })
    }
    filterCondition.departAirportFilterCondition = departAirportFilterCondition;

    let arrivalAirportModel = flightFilterCondition.arrivalAirportModel;
    let arrivalAirportFilterCondition = null;
    if (arrivalAirportModel && Object.keys(arrivalAirportModel).length>0){
        arrivalAirportFilterCondition = {};
        arrivalAirportModel.forEach(arrivalAirport => {
            arrivalAirportFilterCondition[arrivalAirport] = arrivalAirport;
        })
    }
    filterCondition.arrivalAirportFilterCondition = arrivalAirportFilterCondition;

    let flyingTimeRangeModel = flightFilterCondition.flyingTimeModel;
    let flyingTimeRangeFilterCondition = null;
    if (flyingTimeRangeModel){
        flyingTimeRangeFilterCondition = {};
        flyingTimeRangeFilterCondition.minTime = flyingTimeRangeModel[0];
        flyingTimeRangeFilterCondition.maxTime = flyingTimeRangeModel[1];
    }
    filterCondition.flyingTimeRangeFilterCondition = flyingTimeRangeFilterCondition;

    return filterCondition;
}

/* 结束 机票相关*/

/* 开始 通用*/
function filterAllConditions(arr, attribute, condition){
    let filteredArr = [];
    switch (attribute) {
        case 'price':
            if (condition.priceFilterCondition.minPrice==null || condition.priceFilterCondition.maxPrice==null)return arr;

            arr.forEach(a => {
                if (a.price >= condition.priceFilterCondition.minPrice && a.price <= condition.priceFilterCondition.maxPrice){
                    filteredArr.push(a);
                }
            });
            break;
        case 'flyingTime':
            if (!condition.flyingTimeRangeFilterCondition || condition.flyingTimeRangeFilterCondition.minTime==null || condition.flyingTimeRangeFilterCondition.maxTime==null)return arr;
            arr.forEach(a => {
                if (a.originDestinationOptions[0].durationInMins >= condition.flyingTimeRangeFilterCondition.minTime && a.originDestinationOptions[0].durationInMins <= condition.flyingTimeRangeFilterCondition.maxTime){
                    filteredArr.push(a);
                }
            });
            break;
        case 'star':
            if (condition.starFilterCondition==null)return arr;
            arr.forEach(a=> {
                if (condition.starFilterCondition[a.star]==a.star){
                    filteredArr.push(a);
                }
            });
            break;
        case 'name':
            if (condition.nameFilterCondition.keyword == null || condition.nameFilterCondition.keyword=="")return arr;

            arr.forEach(a => {
                let dynamicNameRegex = RegExp("\\b"+condition.nameFilterCondition.keyword+"\\b", "g");
                if (a.name.match(dynamicNameRegex)){
                    filteredArr.push(a);
                }
            });
            break;
        case 'brand':
            if (condition.brandFilterCondition==null)return arr;
            arr.forEach(a=> {
                if (condition.brandFilterCondition[a.brand]==a.brand){
                    filteredArr.push(a);
                }
            });
            break;
        case 'transmission':
            if (condition.transmissionFilterCondition==null)return arr;
            arr.forEach(a=> {
                if (condition.transmissionFilterCondition[a.transmissionType]==a.transmissionType){
                    filteredArr.push(a);
                }
            });
            break;
        case 'transit':
            if (!condition.transitFilterCondition)return arr;
            arr.forEach(a=> {
                if (condition.transitFilterCondition[a.originDestinationOptions[0].stopNumber]!=null){
                    filteredArr.push(a);
                }
            });
            break;
        case 'airline':
            if (!condition.airlineFilterCondition)return arr;
            arr.forEach(a=> {
                if (condition.airlineFilterCondition[a.originDestinationOptions[0].flightSegments[0].operatingAirlineCode]!=null){
                    filteredArr.push(a);
                }
            });
            break;
        case 'departureInterval':
            if (!condition.departIntervalFilterCondition)return arr;
            arr.forEach(a=> {
                if (condition.departIntervalFilterCondition[a.originDestinationOptions[0].departureTimeInterval]){
                    filteredArr.push(a);
                }
            });
            break;
        case 'arrivalInterval':
            if (!condition.arrivalIntervalFilterCondition)return arr;
            arr.forEach(a=> {
                if (condition.arrivalIntervalFilterCondition[a.originDestinationOptions[0].arrivalTimeInteraval]){
                    filteredArr.push(a);
                }
            });
            break;
        case 'departureAirportCode':
            if (!condition.departAirportFilterCondition)return arr;
            arr.forEach(a=> {
                if (condition.departAirportFilterCondition[a.originDestinationOptions[0].originalDepartureCode]){
                    filteredArr.push(a);
                }
            });
            break;
        case 'arrivalAirportCode':
            if (!condition.arrivalAirportFilterCondition)return arr;
            arr.forEach(a=> {
                if (condition.arrivalAirportFilterCondition[a.originDestinationOptions[0].destinationArrivalCode]){
                    filteredArr.push(a);
                }
            });
            break;
        default:
    }

    return filteredArr;
}
/* 结束 通用*/
export default {
    filterHotels,
    filterVehciles,
    filterCarTransfers,
    filterActivities,
    filterFlights,
    getHotelFilterCondition,
    getVehiclesFilterCondition,
    getCarTransferFilterCondition,
    getActivityFilterCondition,
    getFlightFilterCondition
}
