import * as mutationName from "@/store/modules/flight/mutationTypes";
import FlightAPI from "@/apis/FlightAPI";
import {ElMessage} from "element-plus";

const actions = {
    searchFlights({commit}, payload) {
        FlightAPI.lowFareSearch(payload.originDestiations, payload.adultNum, payload.youthNum, payload.childNum, payload.infantNum, payload.cabinPrefs).then(res => {
            let lowFareSearchRS = res.data;
            commit(mutationName.UPDATE_FLIGHTS_INFO, {
                lowFareSearchRS: lowFareSearchRS
            })

        }).catch(error => {
            console.info(error);
        });
    },
    changeSpecificTripItineraries({commit}, payload) {
        commit(mutationName.CHANGE_SPECIFIC_TRIP_ITINERARIES_SHOW, payload)
    },
    paginationFlights({commit}, payload) {
        commit(mutationName.UPDATE_FLIGHTS_SHOW, payload);
    },
    verifyFlightItineraryPrice({commit}, payload) {
        FlightAPI.verifyPrice(payload.airItinerary, payload.passengerTypeQuantityList).then(res => {
            let data = res.data;
            if (data.success) {
                commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, {
                    verifyPriceRS: data
                })
            } else {
                commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, {
                    verifyPriceRS: null
                })
                ElMessage({
                    message: data.errors.errors[0].stringValue,
                    type: 'error'
                });
            }


        }).catch(error => {
            ElMessage({
                message: error,
                type: 'error'
            });
        });
        commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, payload);
    },
    searchFareRules({commit}, payload) {
        FlightAPI.fareRules(payload.rph).then(res => {
            let data = res.data;
            commit(mutationName.UPDATE_FLIGHT_RULES, {
                fareRulesRS: data
            });
        }).catch(error => {
            ElMessage({
                message: error,
                type: 'error'
            });
        });

    },
    searchOffersAndSeatMap({commit}, payload) {
        FlightAPI.getOffers(payload.quoteId).then(res => {
            let data = res.data;
            commit(mutationName.UPDATE_FLIGHT_OFFERS_SEATMAP, {
                offersRS: data
            });
        }).catch(error => {
            ElMessage({
                message: error,
                type: 'error'
            });
        });

    },
    bookFlight({commit}, payload) {
        FlightAPI.book(payload.quoteID, payload.travelerInfos, payload.seatRequests, payload.pricedOffers, payload.username).then(res => {
            let airBookRS = res.data;
            console.info(airBookRS);
            commit(mutationName.UPDATE_FLIGHT_BOOKING, {
                airBookRS: airBookRS
            })
        }).catch(error => {
            ElMessage({
                type: 'error',
                message: error
            })
        })
    }
}

export default actions;
