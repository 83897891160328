import * as mutationName from "@/store/modules/flight/mutationTypes"
import FlightUtils from "@/utils/FlightUtils";
import FilterUtils from "@/utils/FilterUtils";
import SortUtils from "@/utils/SortUtils";

const mutations = {
    [mutationName.UPDATE_FLIGHTS_INFO](state, payload){
        if (payload.lowFareSearchRS && payload.lowFareSearchRS.pricedItineraryList){
            state.lowFareSearchRS = payload.lowFareSearchRS;
            let pricedItineraryList = FlightUtils.getWrapperPricedItineraryList(payload.lowFareSearchRS.pricedItineraryList);
            if (payload.lowFareSearchRS.offer && payload.lowFareSearchRS.offer){
                let offerMap = FlightUtils.getOfferMap(payload.lowFareSearchRS.offer.summary);
                state.offerMap = offerMap;
            }else {
                state.offerMap = {}
            }
            let wrapperPricedItineraryList = pricedItineraryList;
            if (wrapperPricedItineraryList){
                let originDestinationOptions = wrapperPricedItineraryList[0].airItinerary.originDestinationOptions;
                let originDestinationMap = {};
                for (let i=0 ; i<originDestinationOptions.length; i++){
                    originDestinationMap[i] = {
                        departureCode: originDestinationOptions[i].originalDepartureCode,
                        arrivalCode: originDestinationOptions[i].destinationArrivalCode,
                        departureDate: originDestinationOptions[i].destinationArrivalDateTime.split(" ")[0]
                    }
                }
                state.originDestinationMap = originDestinationMap;
                console.info(state.originDestinationMap);
                state.originDestinationShow = state.originDestinationMap[0];
                state.groupNumberShow = 0;
            }else {
                state.originDestinationMap ={};
                state.originDestinationShow ={};
                state.groupNumberShow = 0;
            }
            state.pricedItineraryInfos = wrapperPricedItineraryList;
            let flightItinerariesResult = FlightUtils.getSpecificFlightItineraries(state.pricedItineraryInfos, 0);
            state.flightItineraries = flightItinerariesResult.flightItineraries;
            state.flightResultNum = state.flightItineraries==null?0:state.flightItineraries.length;

            state.airlineOptions = flightItinerariesResult.airlineOptions;
            state.departureAirportOptions = flightItinerariesResult.departureAirportOptions;
            state.arrivalAirportOptions = flightItinerariesResult.arrivalAirportOptions;
            state.tripNum = "0";
            state.minFlyingTime = flightItinerariesResult.minFlyingTime;
            state.maxFlyingTime = flightItinerariesResult.maxFlyingTime;
            state.minFlightPrice = flightItinerariesResult.minFlightPrice;
            state.maxFlightPrice = flightItinerariesResult.maxFlightPrice;
            state.currencyCode = flightItinerariesResult.flightItineraries[0].currencyCode;
            //分页
            state.flightItinerariesShow = FlightUtils.getItinerariesShow(flightItinerariesResult.flightItineraries,1, 10);

        }else {
            state.flightItineraries = [];
            state.flightItinerariesShow = [];
        }
    },
    [mutationName.UPDATE_FLIGHTS_SHOW](state, payload){
        let currentPage = payload.currentPage;
        let pageSize = payload.pageSize;
        let filterCondition = payload.filterCondition;
        let flightItineraries = state.flightItineraries;
        flightItineraries = FilterUtils.filterFlights(flightItineraries, filterCondition);

        if (payload.sortCondition.sortAttribute!=null && payload.sortCondition.type!=null){
            flightItineraries = SortUtils.sort(flightItineraries,payload.sortCondition.sortAttribute, payload.sortCondition.type);
        }
        state.flightResultNum = flightItineraries==null?0:flightItineraries.length;

        state.flightItinerariesShow = [];
        let flightItinerariesShow = FlightUtils.getItinerariesShow(flightItineraries, currentPage, pageSize);
        state.flightItinerariesShow = flightItinerariesShow;
    },
    [mutationName.CHANGE_SPECIFIC_TRIP_ITINERARIES_SHOW](state, payload){
        let groupNumber = payload.groupNumber;
        let origDestRph = payload.origDestRph;
        let flightItinerariesResult = FlightUtils.getSpecificFlightItineraries(state.pricedItineraryInfos, groupNumber, origDestRph);
        state.flightItineraries = flightItinerariesResult.flightItineraries;
        state.flightResultNum = state.flightItineraries==null?0:state.flightItineraries.length;

        state.groupNumberShow = groupNumber;
        state.originDestinationShow = state.originDestinationMap[groupNumber];
        state.airlineOptions = flightItinerariesResult.airlineOptions;
        state.departureAirportOptions = flightItinerariesResult.departureAirportOptions;
        state.arrivalAirportOptions = flightItinerariesResult.arrivalAirportOptions;
        state.tripNum = "0";
        state.minFlyingTime = flightItinerariesResult.minFlyingTime;
        state.maxFlyingTime = flightItinerariesResult.maxFlyingTime;
        state.minFlightPrice = flightItinerariesResult.minFlightPrice;
        state.maxFlightPrice = flightItinerariesResult.maxFlightPrice;
        state.currencyCode = flightItinerariesResult.flightItineraries[0].currencyCode;
        //分页
        state.flightItinerariesShow = FlightUtils.getItinerariesShow(flightItinerariesResult.flightItineraries,1, 10);
    },
    [mutationName.VERIFY_PRICE_FLIGHT_ITINERARY](state, payload){
        if (payload.verifyPriceRS){
            state.verifyPriceRS = payload.verifyPriceRS;
            let pricedItineraryList = FlightUtils.getWrapperPricedItineraryList(payload.verifyPriceRS.pricedItineraryList);
            if (payload.verifyPriceRS.offer && payload.verifyPriceRS.offer && Object.keys(payload.verifyPriceRS.offer).length>0){
                let offerMap = FlightUtils.getOfferMap(payload.verifyPriceRS.offer.summary);
                state.offerMap = offerMap;
            }else {
                state.offerMap = {}
            }
            let wrapperPricedItineraryList = pricedItineraryList;
            console.info(wrapperPricedItineraryList);
            state.verifyPricedItineraryInfo = wrapperPricedItineraryList[0];
            let ptcFareBreakdowns = state.verifyPricedItineraryInfo.airItineraryPricingInfo.PTC_FareBreakdowns;

            let passengerNumber = 1;
            let passengerNumMap ={};
            ptcFareBreakdowns.forEach(fareBreakdown => {
                let code = fareBreakdown.passengerTypeQuantity.code;
                //passenger index
                let quantity = fareBreakdown.passengerTypeQuantity.quantity;
                for (let passengerIndex =1; passengerIndex<=quantity; passengerIndex++){
                    passengerNumMap[code+':'+passengerIndex] = passengerNumber;
                    ++passengerNumber;
                }
            });
            state.passengerNumMap = passengerNumMap;
        }else {
            state.verifyPriceRS = {};
        }
    },
    [mutationName.UPDATE_FLIGHT_RULES](state, payload){
        if (payload.fareRulesRS && payload.fareRulesRS.success){
            state.fareRulesRS = payload.fareRulesRS;
            let fareRuleMap = {}
            payload.fareRulesRS.fareRuleResponseInfos.forEach( fareRule => {
                let fareRuleInfo = fareRule.fareRuleInfo;
                let departureAirportCode = fareRuleInfo.departureAirportCode;
                let arrivalAirportCode = fareRuleInfo.arrivalAirportCode;
                let key = departureAirportCode+' - '+arrivalAirportCode;
                let subSections = [];
                fareRule.fareRules.subSections.forEach(subSection => {
                    subSection.paragraphs.forEach(paragraph => {
                        let text = paragraph.text.replaceAll("\n", "<br>");
                        paragraph.text = text;
                    })
                    subSections.push(subSection);
                })
                fareRuleMap[key] = subSections;
            });
            console.info(fareRuleMap);
            state.fareRuleMap = fareRuleMap;
        }else {
            state.fareRulesRS={}
        }
    },
    [mutationName.UPDATE_FLIGHT_OFFERS_SEATMAP](state, payload){
        if (payload.offersRS && payload.offersRS.pricedOffers){
            state.offersRS = payload.offersRS;
            let offers = []
            payload.offersRS.pricedOffers.forEach( pricedOffer => {
                let name = pricedOffer.name;
                let amount = pricedOffer.pricing.amount;
                let pricingCurrency = pricedOffer.pricing.pricingCurrency;
                let flightSegmentRPH = pricedOffer.flightSegmentRPH;
                let travelerRPH = pricedOffer.travelerRPH;
                let id = pricedOffer.id;

                let offer = {
                    name,
                    amount,
                    pricingCurrency,
                    flightSegmentRPH,
                    travelerRPH,
                    id
                }
                if (pricedOffer.serviceFamily && pricedOffer.serviceFamily.productGroups){
                    let productGroup = pricedOffer.serviceFamily.productGroups[0];
                    let productCode = productGroup.code;
                    let subGroupCode = productGroup.subGroups[0].code;
                    offer.productCode = productCode;
                    offer.subGroupCode = subGroupCode;
                }
                offers.push(offer);
            });
            console.info(offers);
            state.offers = offers;
            state.seatMaps = payload.offersRS.seatMaps;
        }else {
            state.offersRS = {}
            state.offers=[]
        }
    },
    [mutationName.UPDATE_FLIGHT_BOOKING](state, payload){
        if (payload.airBookRS && payload.airBookRS.success){
            state.airBookRS = payload.airBookRS;

            let currencyCode = '';
            let totalAmount = 0;
            state.airBookRS.airReservation.airItineraryPricingInfo.PTC_FareBreakdowns.forEach(breakdown => {
                let quantity = breakdown.passengerTypeQuantity.quantity;

                breakdown.passengerFareList.forEach( fare => {
                    if (fare.usage == 'PassengerFare'){
                        totalAmount += fare.totalFare.amount * quantity;
                        currencyCode = fare.totalFare.currencyCode;
                    }
                })
            })
            let totalPayment = {
                currencyCode: currencyCode,
                amount: totalAmount
            }

            state.totalPayment = totalPayment
        }else {
            state.airBookRS = {}
        }
    }

};

export default mutations;
