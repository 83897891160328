<template>
  <div class="site-wrap">
    <!-- <el-carousel indicator-position="outside" class="fixed-bg" v-show="toShowInHome">
      <el-carousel-item v-for="(item, index) in bgImg" :key="index">
        <div class="fixed-bg" :style="'background-image:url('+item.src+')'"></div>
      </el-carousel-item>
    </el-carousel> -->
    <TopMenu></TopMenu>
    <el-main>
      <router-view/>
    </el-main>
    <FooterMenu></FooterMenu>
    
  </div>
</template>
<script>
import TopMenu from '@/components/TopMenu'
import FooterMenu from '@/components/FooterMenu'
import img1 from '@/assets/images/index_bg.png'
import img2 from '@/assets/images/index_bg1.png'
import img3 from '@/assets/images/index_bg2.png'
export default {
  components: {
    TopMenu,
    FooterMenu
  },
  data(){
    return {
      bgImg:[
        {src:img1},
        {src:img2},
        {src:img3},
      ],
      toShowInHome: false,
    }
  },
  watch:{
    $route:{
    handler: function(val){
      if(val.path == '/'){
        this.toShowInHome = true;
      }else{
        this.toShowInHome = false;
      }
    },
    // 深度观察监听
    deep: true
    }
  },
  methods:{

  },
  mounted(){
    
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

.site-wrap{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
*,html{
  margin: 0;
  padding: 0;
}
html{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #07253F;
  font-size: 15px;
}
.fixed-bg{
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -999;
  height: 100%;
  width: 100%;
  background-size: cover;
  .el-carousel__container{
    height: 100%!important;
  }
}
</style>
