import DateUtils from "@/utils/DateUtils";
/*
*   根据传入的参数，创建搜索酒店列表或酒店房价详情的信息
*
*   @param giataCityId 城市代码，格式为 864
*   @param ttiCode 酒店代码，格式为 456666756
*   @param checkInDate 入住日期，格式为2022-02-01
*   @param checkOutDate 离店日期，格式为 2022-02-11
*   @param roomNum  房间数量
*   @param adultNum 成人数量
*   @param childNum 儿童数量
*
* */

function getHotelAvailRQ(destinationId, ttiCode, bestOnlyIndicator, checkInDate, checkOutDate, roomNum, adultNum, childNum, language, childAges, importantType) {

    let searchLevel = "VeryRecent";
    let hotelRef = {
        hotelCityCode: destinationId,
        "hotelCodeContext": "PLACE"
    }
    if (ttiCode!=null && ttiCode!=""){
        searchLevel = "Live";
        hotelRef.tticode = ttiCode;
        bestOnlyIndicator = false;
    }
    let roomStayCandidates =  []
    // if (childAges != null && childAges.length >0){
    // childAges = null;
    childNum = 0;
    if (childAges != null && childAges.length >0){
        let roomStayCandidate = {
            "quantity": roomNum,
            "guestCounts": {
                "guestCountList": [
                    {
                        "ageQualifyingCode": "10",
                        "count": adultNum
                    }
                ]
            }
        };

        childAges.forEach(childAge => {
            let age = childAge.age;
            let childGuest = {
                "ageQualifyingCode": "8",
                "age": age,
                "count": 1
            }
            roomStayCandidate.guestCounts.guestCountList.push(childGuest);
        })
        roomStayCandidates.push(roomStayCandidate)

    }else {
        roomStayCandidates = [
            {
                "quantity": roomNum,
                "guestCounts": {
                    "guestCountList": [
                        {
                            "ageQualifyingCode": "10",
                            "count": adultNum
                        },
                        {
                            "ageQualifyingCode": "8",
                            "age": 6,
                            "count": childNum
                        }
                    ]
                }
            }
        ]
    }

    return {
        "primaryLangID": language,
        "availRequestSegments": {
            "maximumWaitTime": 60000,
            "availRequestSegments": [
                {
                    "hotelSearchCriteria": {
                        "availableOnlyIndicator": true,
                        "bestOnlyIndicator": bestOnlyIndicator,
                        "criterionList": [
                            {
                                "infoSource": "",
                                "hotelRefList": [
                                    hotelRef
                                ],
                                "stayDateRange": {
                                    "start": checkInDate,
                                    "end": checkOutDate
                                },
                                "importanceType": importantType!=null? importantType:3,
                                "additionalContents": []
                            }
                        ]
                    },
                    "roomStayCandidates": roomStayCandidates
                }
            ]
        },
        "searchCacheLevel": searchLevel
    }

}

/*开始 酒店列表页相关*/
function getHotelsByAvailRS(availRS) {
    let result = {
      minPrice: null,
      maxPrice: null,
      currencyCode: '',
      hotels: []
    };
    if (availRS==undefined || (availRS.success!=null &&!availRS.success)
        || availRS.roomStays == null || availRS.roomStays.roomStayList == null
        || availRS.roomStays.roomStayList.length == null
    ){
        return [];
    }else if (availRS.success|| availRS.roomStays.roomStayList.length>0){
        let roomPriceMap ={};
        availRS.roomStays.roomStayList.forEach(roomStay => {
            let tticode = roomStay.basicPropertyInfo.TTIcode;
            if (roomPriceMap[tticode] != null){
                let price = roomStay.total.amountIncludingMarkup;
                if (roomPriceMap[tticode].price > price){
                    roomPriceMap[tticode].price = price;
                }
            }else {
                roomPriceMap[tticode] = {
                    price: roomStay.total.amountIncludingMarkup,
                    currencyCode: roomStay.total.currencyCode
                };
            }

            //
            let amountIncludingMarkup = roomStay.total.amountIncludingMarkup;
            result.currencyCode = roomStay.total.currencyCode;
            if (result.minPrice==null || result.minPrice > amountIncludingMarkup){
                result.minPrice = amountIncludingMarkup;
            }
            if (result.maxPrice ==null || result.maxPrice<amountIncludingMarkup){
                result.maxPrice = amountIncludingMarkup;
            }
        });
        let hotelStayList = availRS.hotelStays;

        hotelStayList.forEach(hotelStay => {
            let hotel = getReconstructHotel(hotelStay);
            //price
            hotel.price = roomPriceMap[hotel.ttiCode].price;
            hotel.currencyCode = roomPriceMap[hotel.ttiCode].currencyCode;

            result.hotels.push(hotel);
        });


    }
    return result;
}

function getHotelsShow(hotels, currentPage, pageSize) {
    let totalPages = hotels.length<= pageSize? 1: Math.ceil(hotels.length/pageSize);

    let starIndex = pageSize * (currentPage -1);
    let endIndex = hotels.length<=pageSize || totalPages==currentPage? hotels.length : pageSize*currentPage;
    return hotels.slice(starIndex, endIndex);
}
/*结束 酒店列表页相关*/

/*开始 酒店详情页相关*/
function getHotelSelected(hotelDetailsAvailRS, hotelDescriptionRS) {
    let hotelSelected = {};
    if (hotelDetailsAvailRS==undefined || (hotelDetailsAvailRS.success!=null &&!hotelDetailsAvailRS.success)
        || hotelDetailsAvailRS.roomStays == null || hotelDetailsAvailRS.roomStays.roomStayList == null
        || hotelDetailsAvailRS.roomStays.roomStayList.length == null
    ){
        //console.info('None result.')
    }else if (hotelDetailsAvailRS.success|| hotelDetailsAvailRS.roomStays.roomStayList.length>0){
        let hotelStayList = hotelDetailsAvailRS.hotelStays;
        //此时，该数组只有一个hotelStay,构造基础信息
        hotelStayList.forEach(hotelStay => {
            hotelSelected = getReconstructHotel(hotelStay);
        });

        // 构建房间价格信息,以房型作为分类
        let roomTypes ={};
        hotelDetailsAvailRS.roomStays.roomStayList.forEach(roomStay => {
            // 房型相关
            let roomTypeNme = roomStay.roomTypes[0].roomType;
            let bedTypeName = roomStay.roomTypes[0].bedTypeName;
            let roomTypeCode = roomStay.roomTypes[0].roomTypeCode;
            let minOccupancy = roomStay.roomTypes[0].occupancies[0].minOccupancy;
            let maxOccupancy = roomStay.roomTypes[0].occupancies[0].maxOccupancy;
            let quantity = roomStay.roomTypes[0].quantity;
            if (roomTypes[roomTypeCode] == null){
                roomTypes[roomTypeCode] = {
                    typeCode: roomTypeCode,
                    name: roomTypeNme,
                    minOccupancy: minOccupancy,
                    maxOccupancy: maxOccupancy,
                    rooms: []
                }
            }
            let room ={
                quantity: quantity,
                bedTypeName: bedTypeName
            };

            // 餐饮相关
            let breakfast = roomStay.ratePlans[0].mealsIncluded.breakfast;
            let lunch = roomStay.ratePlans[0].mealsIncluded.lunch;
            let dinner = roomStay.ratePlans[0].mealsIncluded.dinner;
            let mealPlanIndicator = roomStay.ratePlans[0].mealsIncluded.mealPlanIndicator;
            room.breakfast  = breakfast;
            room.lunch = lunch;
            room.dinner = dinner;
            room.mealPlanIndicator = mealPlanIndicator;

            //房价相关
            let totalAmount = roomStay.total.amountIncludingMarkup;
            let currencyCode = roomStay.total.currencyCode;
            let referenceId = roomStay.reference.id;
            room.totalAmount  = totalAmount;
            room.currencyCode = currencyCode;
            room.referenceId = referenceId;

            //Rate Comments
            let roomRate = roomStay.roomRates.roomRates[0];
            let roomRateDescriptionList = roomRate.roomRateDescriptionList;
            let rateComments = null;
            if (roomRateDescriptionList){
                roomRateDescriptionList.forEach(roomRateDescription => {
                    if ("RATE_COMMENTS" == roomRateDescription.name){
                        rateComments = roomRateDescription.textList[0].stringValue;
                    }
                })
            }
            room.rateComments = rateComments;

            //Property Fees
            let taxes = roomRate.total.taxes;
            if (taxes&&taxes.taxes){
                room.propertyFees =[];
                taxes.taxes.forEach(tax => {
                    if (tax.type == 'EXCLUSIVE'){
                        let propertyFee = {
                            amount: tax.amount,
                            currencyCode: currencyCode,
                            name: tax.taxDescriptions[0].name
                        }

                        if (tax.taxDescriptions[0].listItems){
                            propertyFee.amountInNet =  tax.taxDescriptions[0].listItems[0].textFormat
                        }

                        room.propertyFees.push(propertyFee);
                    }
                })
            }

            //Tax and Fees inclusive
            let rates = roomRate.rates.rateList;
            let feesAndTaxes = 0;
            rates.forEach(rate => {
                let fees = rate.fees;
                if (fees){
                    fees.forEach(fee => {
                        let amount = fee.amount;
                        feesAndTaxes += amount;
                    })
                }
            })
            room.feesAndTaxes = feesAndTaxes;

            //住客相关
            room.adultNum = 0;
            room.childNum = 0;
            room.roomNum = 0;
            roomStay.guestCounts.guestCountList.forEach(guestCount => {
               switch (guestCount.ageQualifyingCode) {
                   case '10':
                       room.adultNum += guestCount.count;
                       break;
                   case '8':
                       room.childNum += guestCount.count;
                       break;
                   default:
               }
            });

            // 可取消日期
            let cancelPolicies = [];
            if (roomStay.cancelPenalties!=null && roomStay.cancelPenalties.cancelPenalties!=null
                && roomStay.cancelPenalties.cancelPenalties.length>0
            ){
                roomStay.cancelPenalties.cancelPenalties.forEach(cancelPenalty => {
                    if (cancelPenalty.deadline != null){
                        let absoluteDeadlineTimeStamp = cancelPenalty.deadline.absoluteDeadline;
                        let absoluteDeadlineDate = new Date(absoluteDeadlineTimeStamp);
                        let dateFormat = DateUtils.format(absoluteDeadlineDate);
                        let amountPercent = cancelPenalty.amountPercent;
                        let cancelPolicy = {
                            date: dateFormat,
                            amount: amountPercent.amount
                        };
                        if (amountPercent.nmbrOfNights){
                            cancelPolicy.amount=0;
                            rates.slice(0, amountPercent.nmbrOfNights).forEach(rate => {
                                cancelPolicy.amount += rate.total.amountIncludingMarkup;
                            });
                        }else if (amountPercent.amount==null && amountPercent.percent!=null){
                            cancelPolicy.amount = amountPercent.percent/100 * room.totalAmount;
                        }
                        cancelPolicies.push(cancelPolicy);

                    }

                })
            }
            room.cancelPolicies = cancelPolicies;

            roomTypes[roomTypeCode].rooms.push(room);

            //basic info
            let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
            let policy = roomStay.basicPropertyInfo.policy;
            if (policy){
                hotelSelected.checkInTime = policy.checkInTime
                hotelSelected.checkOutTime = policy.checkOutTime
            }
            if (vendorMessages){
                let propertyContent = {};
                vendorMessages.forEach(vendorMessage => {
                    let infoType = vendorMessage.infoType;
                    let paragraphs = vendorMessage.subSections[0].paragraphs;
                    propertyContent[infoType] = paragraphs;
                })
                hotelSelected.propertyContent = propertyContent;
            }

        });
        hotelSelected.roomTypes = roomTypes;
    }


    if (hotelDescriptionRS ==null|| hotelDescriptionRS.hotelDescriptiveContents==null
        || hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList==null
        || hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList.length==0
    ){
        //console.info('None');
    }else {
        let content = hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList[0];
        //服务设施
        hotelSelected.services = [];
        if (content.hotelInfo.serviceList!=null){
            hotelSelected.services = content.hotelInfo.serviceList;
        }

        if (content.hotelInfo.position){
            hotelSelected.position = content.hotelInfo.position;
        }

        //酒店名
        if (hotelSelected.name == null){
            hotelSelected.name = content.hotelName;
        }

        // 酒店星级
        if (hotelSelected.star == null && content.affiliationInfo!=null && content.affiliationInfo.awardList!=null
            &&content.affiliationInfo.awardList.length>0
        ){
            hotelSelected.star = content.affiliationInfo.awardList[0].rating;
        }

        // 联系方式
        let contactInfo = content.contactInfoList[0];
        if (contactInfo.emailList!=null && contactInfo.emailList.length>0){
            hotelSelected.email = contactInfo.emailList[0].emailAddress;
        }

        if (contactInfo.urlList!=null && contactInfo.urlList.length>0){
            hotelSelected.officialUrl = contactInfo.urlList[0].urlString;
        }

        if (contactInfo.phoneList!=null && contactInfo.phoneList.length>0){
            contactInfo.phoneList.forEach(phone => {
                if ("5" == phone.phoneUseType){
                    hotelSelected.contactNumer = phone.phoneNumber;
                }else{
                    hotelSelected.contactNumer = phone.phoneNumber;
                }
            })
        }

        // 图片，文字描述
        hotelSelected.images =[];
        if (content.multimediaDescriptions!=null
            && content.multimediaDescriptions.multimediaDescriptionList!=null
            && content.multimediaDescriptions.multimediaDescriptionList.length>0
        ){
            let multimediaDescription = content.multimediaDescriptions.multimediaDescriptionList[0];

            if (multimediaDescription.imageItems){
                multimediaDescription.imageItems.forEach(imageItem => {
                    let image = {
                        url: imageItem.url
                    };
                    if(imageItem.imageFormatList!=null && imageItem.imageFormatList.length>0 && 'bigger' == imageItem.imageFormatList[0].dimensionCategory){

                        let roomTypeCode = imageItem.roomTypeCode;
                        if (roomTypeCode!=null && hotelSelected!=null && hotelSelected.roomTypes!=null && hotelSelected.roomTypes[roomTypeCode] != null){
                            hotelSelected.roomTypes[roomTypeCode].imgUrl = image.url;
                        }
                        hotelSelected.images.push(image);
                    }else if (imageItem.imageFormatList==null) {
                        hotelSelected.images.push(image);
                    }
                });
            }

            if (hotelSelected.description==null){
                multimediaDescription.textItems.forEach(textItem => {
                    if (textItem.descriptionList && hotelSelected.description == null){
                        hotelSelected.description = textItem.descriptionList[0].stringValue;
                    }
                    if (hotelSelected.description == null){
                        hotelSelected.description = '';
                        textItem.descriptionList[0].subSections[0].paragraphs.forEach(paragraph => {
                            hotelSelected.description += paragraph.text+'<br><br>';
                        })
                    }
                })
            }
        }
    }

    return hotelSelected;
}

/*结束 酒店详情页相关*/

function getHotelResRQ(resStatus, checkInDate, checkOutDate, roomStayList, serviceList, resGuestList, uniqueIDList) {
    if (uniqueIDList==null){
        uniqueIDList =[];
    }
    return {
        "resStatus": resStatus,
        "hotelReservationList": [
            {
                "resGlobalInfo": {
                    "timeSpan": {
                        "start": checkInDate,
                        "end": checkOutDate
                    }
                },
                "uniqueIDList": uniqueIDList,
                "roomStayList": roomStayList,
                "serviceList": serviceList,
                "resStatus": resStatus,
                "pos": {
                    "sourceList": [
                        {
                            "bookingChannel": {
                                "type": "6"
                            }
                        }
                    ]
                },
                "resGuestList": resGuestList
            }
        ]
    }
}


function getHotelDescriptiveRQ(ttiCode, language) {
    if (language==null){
        language = "en";
    }
    return {
        "primaryLangID": language,
        "hotelDescriptiveInfos": {
            "hotelDescriptiveInfoRequestInfo": [
                {
                    "tticode": ttiCode,
                    "multimediaObjects": {
                        "infoSource": "HOTELBEDS_APITUDE,EXPEDIA_RAPID,FITRUUMS,SUNSERIES,MIKITRAVEL,ABREU_ONLINE,TTI"
                    }
                }
            ]
        }
    }
}


/*开始 通用辅助函数*/
function getReconstructHotel(hotelStay){
    let hotel = {
        ttiCode: "",
        name: "",
        nameZhCN: "",
        nameZhHK: "",
        imgUrl: "",
        star: 0,
        rating: 0,
        cityName: "",
        cityCode: "",
        country:"",
        address: "",
        longitude: 0,
        latitude: 0,
        price: 0,
        currencyCode: "",
        description: ""
    };

    let basicPropertyInfo = hotelStay.basicPropertyInfo;
    //基本信息
    hotel.ttiCode = basicPropertyInfo.TTIcode;
    hotel.star = basicPropertyInfo.rating==null?0:Math.floor(parseFloat(basicPropertyInfo.rating));
    hotel.imgUrl = basicPropertyInfo.mainImageURL;
    if (basicPropertyInfo.awards!=null && basicPropertyInfo.awards.length>0){
        basicPropertyInfo.awards.forEach(award => {
            if (award.provider == "TRIPADVISOR"){
                hotel.rating = award.rating==null || award.rating=='' || award.rating=='null'? 0: parseFloat(award.rating);
            }
        })
    }

    // 国际化
    hotel.name = basicPropertyInfo.hotelName;
    if (basicPropertyInfo.propertyNames!=null && basicPropertyInfo.propertyNames.length>0){
        basicPropertyInfo.propertyNames.forEach(propertyName => {
            let languageCode = propertyName.languageCode;
            switch (languageCode) {
                case "zh_HK":
                    hotel.nameZhHK = propertyName.name;
                    break;
                case "zh_CN":
                    hotel.nameZhCN = propertyName.name;
                    break;
                default:
            }
        });
    }

    // 地理位置
    if(basicPropertyInfo.position!=null){
        hotel.latitude = basicPropertyInfo.position.latitude;
        hotel.longitude = basicPropertyInfo.position.longitude;
    }

    // 城市与国家
    hotel.cityName = basicPropertyInfo.hotelCityName;
    hotel.cityCode = basicPropertyInfo.hotelCityCode;
    hotel.country = basicPropertyInfo.countryCode;
    hotel.address = basicPropertyInfo.address.addressString;

    // 文字描述
    hotel.description = basicPropertyInfo.shortDescriptions;

    return hotel;
}


export default {
    getHotelAvailRQ,
    getHotelResRQ,
    getHotelDescriptiveRQ,
    getHotelsByAvailRS,
    getHotelsShow,
    getHotelSelected
}
