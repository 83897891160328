import axios from "@/config/AxiosConfig";
import CarRentalUtils from "@/utils/CarRentalUtils";

function vehicleAvail(pickupDateTime, returnDateTime, pickupLocationCode, returnLocationCode, driverNationCode, vehicleClassCode) {
    let vehicleAvailRQ = CarRentalUtils.getVehicleAvailRQ(pickupDateTime, returnDateTime, pickupLocationCode,
        returnLocationCode, driverNationCode, vehicleClassCode);

    let url = "/api/v1/car/vehicles";
    // console.info(vehicleAvailRQ);
    return axios.post(url, vehicleAvailRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function vehicleRes(pickUpDatetime, returnDatetime, pickupLocationCode, returnLocationCode, driverInfo, vehClassCode, rateQualifier, coveragePrefs, status){
    let vehicleResRQ = CarRentalUtils.getVehicleResRQ(pickUpDatetime, returnDatetime,
        pickupLocationCode, returnLocationCode, driverInfo, vehClassCode, rateQualifier, coveragePrefs, status);

    let url = "/api/v1/car/reservation";
    // console.info(vehicleResRQ);
    return axios.post(url, vehicleResRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}
function vehicleResBook(vehicleResRQBook){

    let url = "/api/v1/car/reservation";
    // console.info(vehicleResRQ);
    return axios.post(url, vehicleResRQBook,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

export default {
    vehicleAvail,
    vehicleRes,
    vehicleResBook
}
