<template>
    <el-header class="top-menu">
        <div class="container">
            <el-row class="navbar-contact">
                <el-col :span="14" class="left-navbar-contact">
                    <a href="#" class="transition-effect ml0" style="display: none"><i class="fa fa-phone"></i> (+852) 39889931</a>
                    <a href="#" class="transition-effect" style="display: none"><i class="fa fa-envelope-o"></i> sales@bookingengine.hk</a>
                </el-col>
                <el-col :span="10" class="search-box right-navbar-contact">
                    <el-col :span="10" class="">
                        <el-form :model="search" :inline="true" style="display: none">
                            <el-form-item>
                                <el-input v-model="search.keyword" class="" :placeholder="$t('common.search')">
                                    <template #append>
                                        <i class="fa fa-search fa-fw"></i>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="14" class="user-logged">
                        <div id="logged-in"  v-if="userInfo.username!=null && userInfo.username!=''">
                            <a class="transition-effect i18n" style="cursor:pointer" @click="openDialog">{{$t('common.language')}}</a>
                            <a class="transition-effect i18n" style="cursor:pointer" @click="goToBookings">{{$t('common.bookings')}}</a>
                            <a class="transition-effect" @click="goToUserInfo" style="cursor: pointer;">{{$t('Hi')}},
                                <span class="givenName">{{userInfo.givenName}}</span>
                            </a>
                            <a @click="signOut" class="transition-effect i18n" style="cursor:pointer">
                                {{$t('common.sign-out')}}<i class="fa fa-sign-out" style="margin-left: 3px;"></i>
                            </a>
                        </div>
                        <div class="not-logged-in" v-else>
                            <a class="transition-effect i18n" style="cursor:pointer" @click="openDialog">{{$t('common.language')}}</a>
                            <a class="transition-effect" style="cursor:pointer"  @click="signIn">{{$t('common.sign-in')}}</a>
                        </div>
                    </el-col>
                </el-col>
            </el-row>
        </div>
        <div class="sub-menu navbar-wrapper ">
            <div class="nav-web container clear">
                <!-- <a class="navbar-brand logo" href="#">{{$t('Booking Engine')}}</a> -->
                <a class="navbar-brand logo" href="#">
                    <img src="../assets/images/logo1.png" alt="" width="180">
                </a>
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a href="#">
                            <i class="fa fa-home"></i>{{$t('common.home')}}
                        </a>
                    </li>
                    <li>
                        <a @click.prevent="jumpTo('1')">
                            <i class="fa fa-plane"></i>{{$t('common.flights')}}
                        </a>
                    </li>
                    <li>
                        <a @click.prevent="jumpTo('2')">
                            <i class="fa fa-bed"></i>{{$t('common.hotels')}}
                        </a>
                    </li>
<!--                    <li>-->
<!--                        <a href="#">-->
<!--                            <i class="fa fa-suitcase"></i>{{$t('common.things-to-do')}}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li><a href="#">-->
<!--                        <i class="fa fa-taxi"></i>{{$t('common.car-rental')}}-->
<!--                    </a>-->
<!--                    </li>-->
<!--                    <li><a href="#">-->
<!--                        <i class="fa fa-taxi"></i>{{$t('common.car-transfer')}}-->
<!--                    </a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a href="#">-->
<!--                            <i class="fa fa-ship"></i>{{$t('common.cruise')}}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a href="#">-->
<!--                            <i class="fa fa-th-large"></i>{{$t('common.package')}}-->
<!--                        </a>-->
<!--                    </li>-->
                    <li>
                        <a href="#/contactus">
                            <i class="fa fa-phone"></i>Contact Us
                        </a>
                    </li>
                </ul>
            </div>
            <div class="nav-mobile container clear">
                <!-- <a class="navbar-brand logo" href="#">{{$t('Booking Engine')}}</a> -->
                <a class="navbar-brand logo" href="#">
                    <img src="../assets/images/logo1.png" alt="" width="180">
                </a>
                <el-button @click="open" class="navbar-toggle">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </el-button>
            </div>
        </div>
        <el-collapse-transition>
            <ul class="nav navbar-nav transition-nav-mobile"
                v-show="isActive"
                role="tabpanel"
                :aria-hidden="!isActive"
            >
                <li>
                    <a href="#">
                        <i class="fa fa-home"></i>{{$t('common.home')}}
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-plane"></i>{{$t('common.flights')}}
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-bed"></i>{{$t('common.hotels')}}
                    </a>
                </li>
<!--                <li>-->
<!--                    <a href="#">-->
<!--                        <i class="fa fa-suitcase"></i>{{$t('common.things-to-do')}}-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li><a href="#">-->
<!--                    <i class="fa fa-taxi"></i>{{$t('common.car-rental')}}-->
<!--                </a>-->
<!--                </li>-->
<!--                <li><a href="#">-->
<!--                    <i class="fa fa-taxi"></i>{{$t('common.car-transfer')}}-->
<!--                </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <a href="#">-->
<!--                        <i class="fa fa-ship"></i>{{$t('common.cruise')}}-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <a href="#">-->
<!--                        <i class="fa fa-th-large"></i>{{$t('common.package')}}-->
<!--                    </a>-->
<!--                </li>-->
                <li>
                    <a href="#/contactus">
                        <i class="fa fa-phone"></i>Contact Us
                    </a>
                </li>
            </ul>
        </el-collapse-transition>
    </el-header>
    <el-dialog
            v-model="dialogVisible"
            title="Display settings"
            width="40%"
            :before-close="handleClose"
    >
        <div class="change-site-language">
            <el-row>
                <el-col :span="24">
                    <label>{{$t('Region')}}</label>
                    <el-select v-model="languageModel.region" disabled :placeholder="$t('common.select')">
                        <el-option
                                v-for="item in regionOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <label>{{$t('Language')}}</label>
                    <el-select v-model="languageModel.language" placeholder="Select" @change="selectLanguage">
                        <el-option
                                v-for="item in languageOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <label>{{$t('CurrencyTag')}}</label>
                    <el-select v-model="languageModel.currencyTag" disabled placeholder="Select">
                        <el-option
                                v-for="item in currencyTagOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>
                </el-col>
            </el-row>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">Cancel</el-button> -->
                <el-button type="primary" @click="save"
                >{{$t('Save')}}</el-button
                >
            </span>
        </template>
    </el-dialog>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import AccountAPI from "../apis/AccountAPI";
    import {ElMessage} from 'element-plus'

    export default {
        name: 'TopMenu',
        computed: {
            ...mapGetters('account', [
                'userInfo'
            ])
        },
        data() {
            return {
                search: {
                    keyword: ''
                },
                dialogVisible: false,
                languageModel: {
                    region: 'Hong Kong',
                    language: 'zh_HK',
                    currencyTag: 'Hong Kong',
                },
                regionOptions: [
                    {value: 'Hong Kong', label: 'Hong Kong'},
                ],
                languageOptions: [
                    {value: 'en', label: 'English'},
                    // {value: 'zh_CN', label: '简体中文'},
                    // {value: 'zh_HK', label: '繁体中文'},
                ],
                currencyTagOptions: [
                    {value: 'Hong Kong', label: 'Hong Kong'},
                ],
                isActive: false,
            }
        },
        components: {},

        methods: {
            ...mapActions('account',[
                'searchLoginStatus'
            ]),
            signOut() {
                AccountAPI.exit().then(res => {
                    if (res.data.success){
                        this.$router.push({path:'/'});
                        this.searchLoginStatus({});
                    }
                }).catch(error => {
                  console.info(error);
                    ElMessage({
                        message: error,
                        type: 'error',
                        duration: 3000
                    })
                })
            },
            signIn() {
                this.$router.push({path:'/loginPage', query: {auth: 0}})
            },
            goToUserInfo(){
                this.$router.push({path:'/userInfo'})
            },
            goToBookings(){
                this.$router.push({path:'/userInfo', query: {
                    activeName: 2
                  }})
            },
            openDialog() {
                this.dialogVisible = true;
            },
            save() {
                this.dialogVisible = false;
                console.log(this.languageModel);
                this.$router.go(0)
            },
            handleClose() {
                this.dialogVisible = false;
            },
            selectLanguage(val){
                sessionStorage.setItem('language', val);
            },
            open(){
                this.isActive = !this.isActive;
            },
            jumpTo(tab){
              this.$router.push({
                path:'/',
                query: {
                  activeTab: tab
                }
              });
            }
        },
        created(){
            let language = sessionStorage.getItem('language');
            this.languageModel.language = language!=null?language=='zh_HK'?'中文繁體':(language=='zh_CN'?'中文简体':'English'):'English';
        }
    }
</script>
<style lang="scss">
    .top-menu {
        background: rgba(10, 49, 82, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center; /*由于flex-direction: column，因此align-items代表的是水平方向*/
        justify-content: center; /*由于flex-direction: column，因此justify-content代表的是垂直方向*/
        height: auto;
        min-height: 50px;
        padding: 0;

        .navbar-contact {
            font-size: 12px;
            padding: 10px 0;

            a {
                display: inline-block;
                margin: 9px 0 0 15px;
                color: #BEC4C8;

                i {
                    color: #f9676b;
                    margin-right: 5px;
                }
            }

            .search-box {
                .el-form{
                    .el-form-item{
                        width: 100%;
                    }
                }
                .user-logged {
                    float: right;
                    text-align: right;

                    .logged-in, .not-logged-in {
                        text-align: right;
                    }
                }

                .el-input-group {
                    --el-input-bg-color: rgb(10, 49, 82);
                    --el-input-text-color: #BEC4C8;
                    --el-input-border-color: rgb(10, 49, 82);
                }

                .el-input-group__append {
                    // padding: 0;
                    padding-right: 10px;
                    // --el-input-bg-color: rgb(10, 49, 82);
                    padding: 0 5px;
                    background: rgb(10, 49, 82);

                    .fa-search {
                        color: #f9676b;
                    }
                }

                .el-input__wrapper {
                    border-radius: 0;
                }

                .el-form-item__content {
                    width: 205px;
                }

                .el-input-group--append {
                    height: 30px !important;
                }
            }

            .el-form-item {
                margin-bottom: 0;
            }
        }

        .sub-menu {
            width: 100%;
            background: rgba(10, 49, 82, 0.5);
            padding: 10px 0px;

            .logo {
                float: left;
                padding: 15px 15px;
                line-height: 20px;
                font-size: 20px;
                color: #ffffff !important;
                letter-spacing: 1px;
                font-weight: bold;
                padding-left: 0;
                padding-right: 0;
            }

            .navbar-right {
                float: right !important;
                margin-right: -15px;
                padding: 15px;
                padding-right: 0;
                padding-left: 0;

                li {
                    float: left;

                    a {
                        color: #ffffff;
                        font-weight: bold;
                        font-size: 14px;
                        padding: 15px 15px;
                        letter-spacing: 1px;
                        transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                    }

                    & > a:hover {
                        color: #f9676b;
                    }
                }
            }

            .nav-mobile{
                display: none;
                .navbar-toggle{
                    color: #ffffff;
                    background: none;
                    height: auto;
                    float: right;
                    line-height: 20px;
                    margin: 7px 0;
                    >span{
                        display: block;
                    }
                    .icon-bar{
                        display: block;
                        width: 22px;
                        height: 2px;
                        border-radius: 1px;
                        background: #ffffff;
                        margin-top: 5px;
                    }
                    .icon-bar:nth-child(1){
                        margin-top: 0;
                    }
                }
            }
        }

        .transition-nav-mobile{
            width: 100%;
            border-top: 1px solid #fff;
            padding-top: 5px;
            li{
                a{
                    display: block;
                    color: #ffffff;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 10px 20px;
                    letter-spacing: 1px;
                    transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -webkit-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                }
            }
        }
    }

    .change-site-language {
        .el-row {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
            }

            .el-select {
                width: 100%;
            }
        }
    }
</style>
