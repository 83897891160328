import * as mutationName from "@/store/modules/account/mutationTypes";
import AccountAPI from "@/apis/AccountAPI";
import {ElMessage} from "element-plus";

const actions = {
    searchUserInfo({commit},payload){
        AccountAPI.userInfo(payload.username).then(res => {
            if (res.data.success){
                commit(mutationName.UPDATE_USER_INFO, {
                    profileReadRS: res.data
                });
            }
        }).catch(error =>{
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });

    },
    modifyUserInfo({commit},payload){
        AccountAPI.modification(payload.username, payload.oldPassword, payload.newPassword, payload.givenName,
                payload.surname, payload.phoneNumber, payload.emailAddress, payload.address
        ).then(res => {
            if (res.data.success){
                this.searchUserInfo({commit},{
                    username: payload.user
                })
            }
        }).catch(error =>{
            console.info(error);
            ElMessage({
                message: error,
                type: 'error'
            })
        });

    },
    searchLoginStatus({commit}){
        AccountAPI.loginStatus().then(res => {
            let data = res.data;
            if (data.success){
                try {
                    commit(mutationName.UPDATE_USER_INFO, {
                        profileReadRS: res.data
                    });
                }catch (e) {
                    commit(mutationName.UPDATE_USER_INFO, {
                        profileReadRS: null
                    });
                }
            }else {
                commit(mutationName.UPDATE_USER_INFO, {
                    profileReadRS: null
                });
            }

            }).catch( () => {
            console.log('错误了');
            commit(mutationName.UPDATE_USER_INFO, {
                profileReadRS: null
            });
        })
        //     .catch(error => {
        //     // console.info(error);
        //     // ElMessage({
        //     //     message: 'Get Profile Failed.\n'+error,
        //     //     type: 'error',
        //     //     duration: 3000
        //     // });
        // })
    }
};

export default actions;
