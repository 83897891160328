/*开始 租车列表页相关*/
function getVehicleAvailRQ(pickupDateTime, returnDateTime, pickupLocationCode, returnLocationCode, driverNationCode, vehicleClassCode) {

    let status = 'vehicle';
    let vehPrefs = null;
    if (vehicleClassCode){
        status = 'rate';
        vehPrefs = [
            {
                "vehClass": {
                    "vehicleClassCode": vehicleClassCode
                }
            }
        ]
    }
    return {
        "vehAvailRQCore": {
            "status": status,
            "vehRentalCore": {
                "pickUpDateTime": pickupDateTime,
                "returnDateTime": returnDateTime,
                "pickUpLocations": [
                    {
                        "locationCode": pickupLocationCode
                    }
                ],
                "returnLocations": [
                    {
                        "locationCode": returnLocationCode
                    }
                ]
            },
            "vehPrefs": vehPrefs
        },
        "vehAvailRQInfo": {
            "customer": {
                "primary": {
                    "citizenCountryName": {
                        "code": driverNationCode
                    }
                }
            }
        }
    }
}

function getVehiclesShow(vehicles, currentPage, pageSize) {
    let totalPages = vehicles.length<= pageSize? 1: Math.ceil(vehicles.length/pageSize);

    let starIndex = pageSize * (currentPage -1);
    let endIndex = vehicles.length<=pageSize || totalPages==currentPage? vehicles.length : pageSize*currentPage;
    return vehicles.slice(starIndex, endIndex);
}

function getVehiclesByVehAvailRS(vehAvailRS) {
    let result = {
        pickupLocation: {},
        returnLocation: {},
        minPrice: null,
        maxPrice: null,
        currencyCode: '',
        vehicles: []
    };
    if (vehAvailRS==undefined || (vehAvailRS.success!=null &&!vehAvailRS.success)
        || vehAvailRS.vehAvailRSCore == null || vehAvailRS.vehAvailRSCore.vehVendorAvails == null
    ){
        return [];
    }else if (vehAvailRS.success|| (vehAvailRS.vehAvailRSCore!=null && vehAvailRS.vehAvailRSCore.vehVendorAvails!=null)){
        vehAvailRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails.forEach(vehAvail => {

            let vehAvailCore = vehAvail.vehAvailCore;
            let vehicle = vehAvailCore.vehicle;

            let rentalRates = vehAvailCore.rentalRates;

            let totalCharges = vehAvailCore.totalCharges;
            let rateTotalAmount = totalCharges[0].rateTotalAmount;
            let currencyCode = totalCharges[0].currencyCode;

            //
            let vehicleShow = {
                airConditionInd: vehicle.airConditionInd,
                transmissionType: vehicle.transmissionType,
                oilConsume: vehicle.oilConsume,
                doorCount: vehicle.vehType.doorCount,
                vehClassName: vehicle.vehClass.vehClassName,
                vehClassCode: vehicle.vehClass.vehClassCode,
                passengerQuantity: vehicle.passengerQuantity,
                baggageQuantity: vehicle.baggageQuantity,
                name: vehicle.vehMakeModel.name,
                brand: vehicle.vehMakeModel.make,
                model: vehicle.vehMakeModel.model,
                pictureURL: vehicle.pictureURL,
                rateQualifier: rentalRates[0].rateQualifier==null? '': rentalRates[0].rateQualifier.rateQualifier,
                price: rateTotalAmount,
                currencyCode: currencyCode
            }

            if (vehicleShow.brand==null){
                vehicleShow.brand = vehicleShow.name.split(' ')[0];
            }

            result.vehicles.push(vehicleShow);

            result.currencyCode = currencyCode;
            if (result.minPrice==null || result.minPrice > rateTotalAmount){
                result.minPrice = rateTotalAmount;
            }
            if (result.maxPrice ==null || result.maxPrice<rateTotalAmount){
                result.maxPrice = rateTotalAmount;
            }
        });

    }
    return result;
}

/*结束 租车列表页相关*/

/*开始 租车详情页相关*/
function getVehicleDetail(vehAvailRS) {
    if (vehAvailRS==undefined || (vehAvailRS.success!=null &&!vehAvailRS.success)
        || vehAvailRS.vehAvailRSCore == null || vehAvailRS.vehAvailRSCore.vehVendorAvails == null
    ){
        return {};
    }else if (vehAvailRS.success|| (vehAvailRS.vehAvailRSCore!=null && vehAvailRS.vehAvailRSCore.vehVendorAvails!=null)){
        let vehAvail = vehAvailRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails[0];
        let vehRentalCore = vehAvailRS.vehAvailRSCore.vehRentalCore;

        let vehAvailCore = vehAvail.vehAvailCore;
        let vehAvailInfo = vehAvail.vehAvailInfo;
        let vehicle = vehAvailCore.vehicle;

        let rentalRates = vehAvailCore.rentalRates;
        let fees = vehAvailCore.fees;

        let totalCharges = vehAvailCore.totalCharges;
        let rateTotalAmount = totalCharges[0].rateTotalAmount;
        let currencyCode = totalCharges[0].currencyCode;

        //
        let vehicleDetail= {
            airConditionInd: vehicle.airConditionInd,
            transmissionType: vehicle.transmissionType,
            oilConsume: vehicle.oilConsume,
            doorCount: vehicle.vehType.doorCount,
            vehClassName: vehicle.vehClass.vehClassName,
            vehClassCode: vehicle.vehClass.vehClassCode,
            passengerQuantity: vehicle.passengerQuantity,
            baggageQuantity: vehicle.baggageQuantity,
            name: vehicle.vehMakeModel.name,
            brand: vehicle.vehMakeModel.make,
            model: vehicle.vehMakeModel.model,
            pictureURL: vehicle.pictureURL,
            rateQualifier: rentalRates[0].rateQualifier==null? '': rentalRates[0].rateQualifier.rateQualifier,
            price: rateTotalAmount,
            currencyCode: currencyCode,
            fees: [],
            taxes: [],
            insurances:[]
        };

        if (vehicleDetail.brand==null){
            vehicleDetail.brand = vehicleDetail.name.split(' ')[0];
        }

        //费用
        let feeTotal = 0;
        let feeOrigCurrencyCode = '';
        let taxTotal = 0;
        let taxOrigCurrencyCode = '';
        if (fees!=null && fees.length>0){
            fees.forEach(fee =>{
                if (!fee.taxInclusive){
                    let feeShow = {
                        name: fee.description,
                        amount: fee.amount,
                        currencyCode: fee.currencyCode
                    }
                    feeTotal += fee.amount;
                    feeOrigCurrencyCode = fee.currencyCode;
                    vehicleDetail.fees.push(feeShow);
                }else {
                    let taxShow = {
                        name: fee.description,
                        amount: fee.amount,
                        currencyCode: fee.currencyCode
                    }
                    taxTotal += fee.amount;
                    taxOrigCurrencyCode = fee.currencyCode;
                    vehicleDetail.taxes.push(taxShow);
                }
            });
        }

        vehicleDetail.feeTotal = feeTotal;
        vehicleDetail.feeOrigCurrencyCode = feeOrigCurrencyCode;
        vehicleDetail.taxTotal = taxTotal;
        vehicleDetail.taxOrigCurrencyCode = taxOrigCurrencyCode;

        // 保险
        let pricedCoverages = vehAvailInfo.pricedCoverages;
        let extraTotal = 0;
        let extraOrigCurrencyCode = '';
        if (pricedCoverages!=null && pricedCoverages.length>0){
            pricedCoverages.forEach(pricedCoverage => {
               let insuranceShow = {
                   selected: pricedCoverage.selected,
                   taxInclusive: pricedCoverage.charge.taxInclusive,
                   code: pricedCoverage.coverage.code,
                   name: pricedCoverage.charge.description,
                   amount: pricedCoverage.charge.amount,
                   currencyCode: pricedCoverage.charge.currencyCode,
                   included: pricedCoverage.charge.includedInRate || pricedCoverage.selected
               };
               // if (insuranceShow.included || pricedCoverage.selected){
               //     extraTotal += insuranceShow.amount;
               // }
                extraOrigCurrencyCode = insuranceShow.currencyCode;
               vehicleDetail.insurances.push(insuranceShow);
            });
        }
        vehicleDetail.extraTotal = extraTotal;
        vehicleDetail.extraOrigCurrencyCode = extraOrigCurrencyCode;
        vehicleDetail.price += vehicleDetail.extraTotal;
        //天数
        let pickUpDateTime = new Date(vehRentalCore.pickUpDateTime);
        let returnDateTime = new Date(vehRentalCore.returnDateTime);
        let days = Math.ceil((returnDateTime - pickUpDateTime)/(1000 * 60 * 60 * 24));
        vehicleDetail.days = days;
        vehicleDetail.pickupDate = vehRentalCore.pickUpDateTime.split('T')[0];
        vehicleDetail.returnDate = vehRentalCore.returnDateTime.split('T')[0];

        console.info(vehicleDetail);
        return vehicleDetail;

    }
}

/*结束 租车详情页相关*/

/*开始 预定页相关*/
function getVehicleResRQ(pickupDatetime, returnDatetime, pickupLocationCode, returnLocationCode, driverInfo, vehClassCode, rateQualifier, coveragePrefs, status){

    return {
        "vehResRQCore": {
            "status": status,
            "vehRentalCore": {
                "pickUpDateTime": pickupDatetime.split(' ')[0] + 'T' + pickupDatetime.split(' ')[1],
                "returnDateTime": returnDatetime.split(' ')[0] + 'T' + returnDatetime.split(' ')[1],
                "pickUpLocations": [
                    {
                        "locationCode": pickupLocationCode
                    }
                ],
                "returnLocations": [
                    {
                        "locationCode": returnLocationCode
                    }
                ]
            },
            "customer": {
                "primary": {
                    "personName": {
                        "givenName": driverInfo.firstName,
                        "surname": driverInfo.lastName,
                        "nameTitle": driverInfo.title
                    },
                    "telephoneList": [
                        {
                            "phoneNumber": driverInfo.phoneNumber
                        }
                    ],
                    "emailList": [
                        {
                            "stringValue": driverInfo.emailAddress
                        }
                    ],
                    "citizenCountryName": {
                        "code": driverInfo.nation.code
                    }
                }
            },
            "vehPref": {
                "vehClass": {
                    "vehicleClassCode": vehClassCode
                }
            },
            "rateQualifier": {
                "rateQualifier": rateQualifier
            }
        },
        "vehResRQInfo":{
            "coveragePrefs": coveragePrefs
        }
    }
}

function getVehBookingReviewInfo(vehReservation) {


    let customer = vehReservation.customer;
    let primary = customer.primary;
    let personName = primary.personName;
    let givenName = personName.givenName;
    let surname = personName.surname;
    let nameTitle = personName.nameTitle;
    let phoneNumber = primary.telephoneList[0].phoneNumber;
    let emailAddress = primary.emailList[0].stringValue;
    let countryCode = primary.citizenCountryName.code;


    let vehSegmentCore = vehReservation.vehSegmentCore;
    let confIDs = vehSegmentCore.confIDs;
    let pickUpDateTime = vehSegmentCore.vehRentalCore.pickUpDateTime;
    let pickupLocationCode = vehSegmentCore.vehRentalCore.pickUpLocations[0].locationCode;
    let pickupLocationName = vehSegmentCore.vehRentalCore.pickUpLocations[0].locationName;
    let returnDateTime = vehSegmentCore.vehRentalCore.returnDateTime;
    let returnLocationCode = vehSegmentCore.vehRentalCore.returnLocations[0].locationCode;
    let returnLocationName = vehSegmentCore.vehRentalCore.returnLocations[0].locationName;

    let vehicle = vehSegmentCore.vehicle;
    let vehMakeModel = vehicle.vehMakeModel;
    let vehicleName = vehMakeModel.name;
    let brand = vehicle.vehMakeModel.make;
    let vehClassName = vehicle.vehClass.vehClassName;
    let pictureURL = vehicle.pictureURL;

    let totalCharge = vehSegmentCore.totalCharge;
    let estimatedTotalAmount = totalCharge.estimatedTotalAmount;
    let currencyCode = totalCharge.currencyCode;

    //天数
    let pickUpDateTimeRe = new Date(pickUpDateTime);
    let returnDateTimeRe = new Date(returnDateTime);
    let days = Math.ceil((returnDateTimeRe - pickUpDateTimeRe)/(1000 * 60 * 60 * 24));

    let fees = vehSegmentCore.fees;

    let vehBookingReviewInfo = {
        driverInfo: {
            givenName: givenName,
            surname: surname,
            title: nameTitle,
            phoneNumber: phoneNumber,
            emailAddress: emailAddress,
            countryCode: countryCode
        },
        vehicleInfo: {
            name: vehicleName,
            className: vehClassName,
            pictureURL: pictureURL,
            brand: brand
        },
        rentalInfo: {
            pickup: {
                datetime: pickUpDateTime,
                locationCode: pickupLocationCode,
                locationName: pickupLocationName
            },
            return: {
                datetime: returnDateTime,
                locationCode: returnLocationCode,
                locationName: returnLocationName
            },
            rentalDays: days
        },
        priceInfo: {
            total: {
                amount: estimatedTotalAmount,
                currencyCode: currencyCode
            },
            fees: fees,
            coverages: []
        },
        uniqueIDs: confIDs
    };

    if (vehBookingReviewInfo.vehicleInfo.brand==null){
        vehBookingReviewInfo.vehicleInfo.brand = vehBookingReviewInfo.vehicleInfo.name.split(' ')[0];
    }

    //费用
    let feeTotal = 0;
    let feeOrigCurrencyCode = '';
    let taxTotal = 0;
    let taxOrigCurrencyCode = '';
    if (fees!=null && fees.length>0){
        fees.forEach(fee =>{
            if (!fee.taxInclusive){
                feeTotal += fee.amount;
                feeOrigCurrencyCode = fee.currencyCode;
            }else {
                taxTotal += fee.amount;
                taxOrigCurrencyCode = fee.currencyCode;
            }
        });
    }
    vehBookingReviewInfo.priceInfo.feeTotal = feeTotal;
    vehBookingReviewInfo.priceInfo.feeOrigCurrencyCode = feeOrigCurrencyCode;

    vehBookingReviewInfo.priceInfo.taxTotal = taxTotal;
    vehBookingReviewInfo.priceInfo.taxOrigCurrencyCode = taxOrigCurrencyCode;

    let vehSegmentInfo = vehReservation.vehSegmentInfo;
    let pricedCoverages = vehSegmentInfo.pricedCoverages;

    // 保险
    let extraTotal = 0;
    let extraOrigCurrencyCode = '';
    if (pricedCoverages!=null && pricedCoverages.length>0){
        pricedCoverages.forEach(pricedCoverage => {
            let coverage = {
                selected: pricedCoverage.selected,
                taxInclusive: pricedCoverage.charge.taxInclusive,
                code: pricedCoverage.coverage.code,
                type: pricedCoverage.coverage.coverageType,
                name: pricedCoverage.charge.description,
                amount: pricedCoverage.charge.amount,
                currencyCode: pricedCoverage.charge.currencyCode,
                included: pricedCoverage.charge.includedInRate || pricedCoverage.selected
            };
            vehBookingReviewInfo.priceInfo.coverages.push(coverage);
        });
    }
    vehBookingReviewInfo.priceInfo.extraTotal = extraTotal;
    vehBookingReviewInfo.priceInfo.extraOrigCurrencyCode =extraOrigCurrencyCode;

    return vehBookingReviewInfo;
}
/*结束 预定页相关*/

export default {
    getVehicleAvailRQ,
    getVehiclesByVehAvailRS,
    getVehiclesShow,
    getVehicleDetail,
    getVehicleResRQ,
    getVehBookingReviewInfo
}
