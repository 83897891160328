const state={
    /*列表页相关*/
    vehAvailRS: {},
    vehicles:[],
    vehiclesShow: [],
    vehicleResultNum: 0,
    minVehiclePrice: 0,
    maxVehiclePrice: 0,
    currencyCode: '',
    vehicleBrands:[],
    transmissionTypes:[],
    /*详情页相关*/
    vehicleDetail: {
        taxTotal: 0,
        feeTotal: 0,
        extraTotal: 0
    },
    /*预定页面相关*/
    vehResRS: {},
    vehicleResRQBook: {},
    driverInfo: {},
    totalPayment: {},
    vehBookingReviewInfo: {
        driverInfo: {},
        vehicleInfo: {},
        rentalInfo: {
            pickup: {
            },
            return: {
            }
        },
        priceInfo: {
            total: {
            },
            feeTotal: 0,
            taxTotal: 0,
            extraTotal: 0,
            fees: [],
            coverages: []
        }
    }
};

export default state;
