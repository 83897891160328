import CarTransferUtils from "@/utils/CarTransferUtils";
import FlightUtils from "@/utils/FlightUtils";
import DateUtils from "@/utils/DateUtils";

function getBookingsRetrieveRQ(username, bookingId,offset, limit, start, end, status) {

    let type = "USERNAME";
    let id = username;
    if (bookingId!=null && bookingId!=''){
        type  = 'BOOKING_ID';
        id = bookingId;
    }

    return {
        "uniqueID": {
            "type": type,
            "id": id,
            "id_context": "ERP"
        },
        "offset": offset,
        "limit": limit,
        "readRequests": {
            "globalReservationReadRequests":  [
                {
                    "start": start,
                    "end": end,
                    "status": status
                }
            ]
        }
    }
}

function getBookingStatus(key) {
    return {
        'BOOKING_IN_PROCESS':'In Process',
        'BOOKING_CANCELLED':'Cancelled',
        'BOOKING_CLOSED':'Closed',
        'BOOKING_CONFIRMED':'Confirmed',
        'BOOKING_RECEIVED':'Received',
    }[key];

}

function getCancelRQ(bookingId, cancelType) {
    return {
        "cancelType": cancelType,
        "uniqueIDs": [
            {
                "type": "BOOKING_ID",
                "id": bookingId,
                "id_context": "ERP"
            }
        ]
    }
}


/*开始  历史订单相关*/
function getHotelBookingShow(booking) {
    let bookingShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let hotelReservation = booking.hotelReservations[0];
    let dateTime = hotelReservation.createDateTime;
    let resStatus = hotelReservation.resStatus;
    let checkInDate = hotelReservation.checkInDate;
    let checkOutDate = hotelReservation.checkOutDate;
    let hotelName = hotelReservation.hotelName;
    let resGlobalInfo = hotelReservation.resGlobalInfo;
    let amountIncludingMarkup = resGlobalInfo.total.amountIncludingMarkup;
    let currencyCode = resGlobalInfo.total.currencyCode;

    let bookingId = '';
    hotelReservation.uniqueIDList.forEach( uniqueID => {
        if (uniqueID.type == 'BOOKING_ID'){
            bookingId = uniqueID.id;
        }
    });
    // let bookingStatus = getBookingStatus(resStatus);
    let bookingStatus = resStatus;

    bookingShow.type = 'HOTEL';
    bookingShow.typeCode = 'HOTEL';
    bookingShow.bookingId = bookingId;
    bookingShow.totalAmount = amountIncludingMarkup;
    bookingShow.currency = currencyCode;
    bookingShow.statusId = bookingStatus;
    bookingShow.bookingDate = dateTime;
    bookingShow.necessaryInfo.name = hotelName;
    bookingShow.necessaryInfo.checkInDate = checkInDate;
    bookingShow.necessaryInfo.checkOutDate = checkOutDate;
    return bookingShow;
}

function getActivityBookingShow(booking) {
    let bookingShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let tourActivityReservation = booking.tourActivityReservations[0];
    let globalReservations = booking.globalReservations;
    let name = tourActivityReservation.basicInfo.name;
    let dateTime = tourActivityReservation.pickupDropoffs[0].dateTime;

    let bookingId = '';
    globalReservations.forEach( globalRes => {
        if (globalRes.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalRes.uniqueID.id;
        }
    });
    let resStatus = booking.statusId;
    let bookingStatus = getBookingStatus(resStatus);
    let totalAmount = tourActivityReservation.pricing.groups[0].price.amount;
    let currencyCode = tourActivityReservation.pricing.summary.currencyCode;
    let bookingDate = tourActivityReservation.bookingDate;

    bookingShow.type = 'ACTIVITY';
    bookingShow.typeCode = 'ACTIVITY';
    bookingShow.bookingId = bookingId;
    bookingShow.totalAmount = totalAmount;
    bookingShow.currency = currencyCode;
    bookingShow.statusId = bookingStatus;
    bookingShow.bookingDate = bookingDate;
    bookingShow.necessaryInfo.name = name;
    bookingShow.necessaryInfo.startDate = DateUtils.formatDate(new Date(dateTime), 'YYYY-MM-DD');
    return bookingShow;
}

function getVehicleBookingShow(booking) {
    let bookingShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let vehicleReservation = booking.vehicleReservations[0];
    let dateTime = vehicleReservation.createDateTime;
    let resStatus = booking.statusId;
    let pickUpDateTime = vehicleReservation.vehSegmentCore.vehRentalCore.pickUpDateTime;
    let returnDateTime = vehicleReservation.vehSegmentCore.vehRentalCore.returnDateTime;
    let carName = vehicleReservation.vehSegmentCore.vehicle.vehMakeModel.name;
    let amountIncludingMarkup = vehicleReservation.vehSegmentCore.totalCharge.estimatedTotalAmount;
    let currencyCode = vehicleReservation.vehSegmentCore.totalCharge.currencyCode;

    let bookingId = '';
    booking.globalReservations.forEach( globalReservation => {
        if (globalReservation.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalReservation.uniqueID.id;
        }
    });
    let bookingStatus = getBookingStatus(resStatus);

    bookingShow.type = 'CAR RENTAL';
    bookingShow.typeCode = 'CAR_RENTAL';
    bookingShow.bookingId = bookingId;
    bookingShow.totalAmount = amountIncludingMarkup;
    bookingShow.currency = currencyCode;
    bookingShow.statusId = bookingStatus;
    bookingShow.bookingDate = dateTime;
    bookingShow.necessaryInfo.name = carName;
    bookingShow.necessaryInfo.pickUpDateTime = pickUpDateTime;
    bookingShow.necessaryInfo.returnDateTime = returnDateTime;
    return bookingShow;
}

function getCarTransferBookingShow(booking) {
    let bookingShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let groundReservation = booking.groundReservations[0];
    let resStatus = booking.statusId;
    let pickUpDateTime = groundReservation.groundServices[0].service.location.pickup.dateTime;
    let returnDateTime = null;
    if (groundReservation.groundServices[1] && groundReservation.groundServices[1].service.location.pickup.dateTime){
        returnDateTime = groundReservation.groundServices[1].service.location.pickup.dateTime;
    }

    let carName = groundReservation.groundServices[0].service.vehicleMakeModel.name+'-'+groundReservation.groundServices[0].service.vehicleType.description;
    let departCarUrl = '';
    let imageItems = groundReservation.groundServices[0].service.multimediaDescriptions.multimediaDescriptionLists[0].imageItems;
    imageItems.forEach(image => {
        if ('LARGE' == image.imageFormatList[0].dimensionCategory){
            departCarUrl = image.url;
        }
    });

    let returnCarUrl = '';
    if (groundReservation.groundServices[1]){
        let returnImageItems = groundReservation.groundServices[1].service.multimediaDescriptions.multimediaDescriptionLists[0].imageItems;
        returnImageItems.forEach(image => {
            if ('LARGE' == image.imageFormatList[0].dimensionCategory){
                returnCarUrl = image.url;
            }
        });
    }

    let returnName = null;
    if (groundReservation.groundServices[0]){
        returnName = groundReservation.groundServices[0].service.vehicleMakeModel.name+'-'+groundReservation.groundServices[0].service.vehicleType.description;
    }

    let amountIncludingMarkup = groundReservation.totalCharge.estimatedTotalAmount;
    let currencyCode = groundReservation.totalCharge.currencyCode;

    let bookingId = '';
    booking.globalReservations.forEach( globalReservation => {
        if (globalReservation.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalReservation.uniqueID.id;
        }
    });
    let bookingStatus = getBookingStatus(resStatus);

    bookingShow.type = 'CAR TRANSFER';
    bookingShow.typeCode = 'CAR_TRANSFER';
    bookingShow.bookingId = bookingId;
    bookingShow.totalAmount = amountIncludingMarkup;
    bookingShow.currency = currencyCode;
    bookingShow.statusId = bookingStatus;
    bookingShow.bookingDate = groundReservation.bookingDate;
    bookingShow.necessaryInfo.name = carName;
    bookingShow.necessaryInfo.departureName = carName;
    bookingShow.necessaryInfo.departCarUrl = departCarUrl;
    bookingShow.necessaryInfo.departureDateTime = pickUpDateTime;
    bookingShow.necessaryInfo.returnName = returnName;
    bookingShow.necessaryInfo.returnCarUrl = returnCarUrl;
    bookingShow.necessaryInfo.returnDateTime = returnDateTime;
    return bookingShow;
}

function getHotelBookingDetails(bookingDetails) {

    let bookingDetailsShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let hotelReservation = bookingDetails.hotelReservations[0];
    let dateTime = hotelReservation.createDateTime;
    let resStatus = hotelReservation.resStatus;
    let checkInDate = hotelReservation.checkInDate;
    let checkOutDate = hotelReservation.checkOutDate;
    let hotelName = hotelReservation.hotelName;
    let resGlobalInfo = hotelReservation.resGlobalInfo;
    let amountIncludingMarkup = resGlobalInfo.total.amountIncludingMarkup;
    let currencyCode = resGlobalInfo.total.currencyCode;

    let bookingId = '';
    let vouchers = [];
    hotelReservation.uniqueIDList.forEach( uniqueID => {
        if (uniqueID.type == 'BOOKING_ID'){
            bookingId = uniqueID.id;
        }
        if (uniqueID.type == 'BOOKING_HOTEL_ITEM_ID'){
            vouchers.push({
                id: uniqueID.id,
                tripName: hotelName
            })
        }
    });
    // let bookingStatus = getBookingStatus(resStatus);
    let bookingStatus = resStatus;

    bookingDetailsShow.type = 'HOTEL';
    bookingDetailsShow.typeCode = 'HOTEL';
    bookingDetailsShow.bookingId = bookingId;
    bookingDetailsShow.totalAmount = amountIncludingMarkup;
    bookingDetailsShow.currency = currencyCode;
    bookingDetailsShow.statusId = bookingStatus;
    bookingDetailsShow.bookingDate = dateTime;

    let necessaryInfo = {
        name: hotelName,
        cityName: '',
        address: '',
        roomInfo: {
            type: '',
            bedTypeName: '',
            propertyFees:[],
            pricePerRoom: 0,
            feesAndTaxesPerRoom:0,
            priceTotal: 0,
            meals: '',
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            quantity: hotelReservation.roomStayList.length
        },
        roomGuests: [

        ],
        vouchers
    };

    //住客
    let resGuestMap = {};
    hotelReservation.resGuestList.forEach(resGuest => {
        resGuestMap[resGuest.resGuestRPH]= resGuest;
    });


    //价格
    let roomTotalAmount = 0;
    let pricePerRoom = 0;
    hotelReservation.roomStayList.forEach( roomStay => {

        // 房型
        necessaryInfo.roomInfo.type = roomStay.roomTypes[0].roomType;
        necessaryInfo.roomInfo.bedTypeName = roomStay.roomTypes[0].bedTypeName;

        // 餐饮
        necessaryInfo.roomInfo.meals = 'None';
        if (roomStay.ratePlans[0].mealsIncluded.mealPlanIndicator){
            necessaryInfo.roomInfo.meals = 'Breakfast';
        }

        // 取消日期
        if (roomStay.cancelPenalties!=null
            && roomStay.cancelPenalties.cancelPenalties!=null
            && roomStay.cancelPenalties.cancelPenalties.length >0
            && roomStay.cancelPenalties.cancelPenalties[0].deadline!=null
            && roomStay.cancelPenalties.cancelPenalties[0].deadline.absoluteDeadline!=null
        ){
            necessaryInfo.roomInfo.absoluteDeadline = roomStay.cancelPenalties.cancelPenalties[0].deadline.absoluteDeadline;
        }

        // Rate Comments
        let rateComments = '';
        if (roomStay.roomRates
                && roomStay.roomRates.roomRates
                && roomStay.roomRates.roomRates.length>0
        ){
            let roomRate = roomStay.roomRates.roomRates[0];
            if (roomRate. roomRateDescriptionList){
                roomRate. roomRateDescriptionList.forEach(description => {
                    if (description.name == 'RATE_COMMENTS'){
                        rateComments = description.textList[0].stringValue;
                    }
                })
            }

            //Property Fees
            if (roomRate.total&&roomRate.total.taxes&&roomRate.total.taxes.taxes){
                let taxes = roomRate.total.taxes;
                necessaryInfo.roomInfo.propertyFees =[];
                taxes.taxes.forEach(tax => {
                    if (tax.type == 'EXCLUSIVE'){
                        let propertyFee = {
                            amount: tax.amount,
                            currencyCode: currencyCode,
                            name: tax.taxDescriptions[0].name
                        }
                        if (tax.taxDescriptions[0].listItems){
                            propertyFee.amountInNet =  tax.taxDescriptions[0].listItems[0].textFormat
                        }

                        necessaryInfo.roomInfo.propertyFees.push(propertyFee);
                    }
                })
            }

            //Tax and Fees inclusive
            let rates = roomRate.rates.rateList;
            let feesAndTaxes = 0;
            rates.forEach(rate => {
                let fees = rate.fees;
                if (fees){
                    fees.forEach(fee => {
                        let amount = fee.amount;
                        feesAndTaxes += amount;
                    })
                }
            })
            necessaryInfo.roomInfo.feesAndTaxesPerRoom = feesAndTaxes;
        }

        let basicPropertyInfo = roomStay.basicPropertyInfo;
        necessaryInfo.cityName = basicPropertyInfo.hotelCityName;
        necessaryInfo.address = basicPropertyInfo.address.addressString;

        //basic info
        let vendorMessages = basicPropertyInfo.vendorMessages;
        if (vendorMessages){
            let propertyContent = {};
            vendorMessages.forEach(vendorMessage => {
                let infoType = vendorMessage.infoType;
                let paragraphs = vendorMessage.subSections[0].paragraphs;
                propertyContent[infoType] = paragraphs;
            })
            necessaryInfo.propertyContent = propertyContent;
        }

        pricePerRoom = roomStay.total.amountIncludingMarkup - necessaryInfo.roomInfo.feesAndTaxesPerRoom;
        roomTotalAmount += roomStay.total.amountIncludingMarkup;



        let roomGuest = {
            guests: [],
            remarks: '',
            rateComments: rateComments
        };
        roomStay.guestCounts.guestCountList.forEach(guestCount => {

            let resGuest = resGuestMap[guestCount.resGuestRPH];
            let customer = resGuest.profiles.profileInfos[0].profileList[0].customer;
            let guest = {
                givenName: customer.personName.givenName,
                surname: customer.personName.surname,
                nameTitle: customer.personName.nameTitle,
                ageQualifyingCode: guestCount.ageQualifyingCode,
                birthDate: customer.birthDate,
                age: resGuest.age,
                emailAddress: '',
                phoneNumber: ''
            };
            if ('10' == guestCount.ageQualifyingCode){
                guest.emailAddress = customer.emailList[0].stringValue;
                guest.phoneNumber = customer.telephoneList[0].phoneNumber;
            }

            roomGuest.guests.push(guest);

        });

        if (roomStay.specialRequests!=null &&
            roomStay.specialRequests.specialRequests!=null &&
            roomStay.specialRequests.specialRequests.length>0
        ){
            roomGuest.remarks = roomStay.specialRequests.specialRequests[0].text;
        }

        necessaryInfo.roomGuests.push(roomGuest);

    });

    necessaryInfo.roomInfo.pricePerRoom = pricePerRoom;
    necessaryInfo.roomInfo.priceTotal = roomTotalAmount;

    //备注


    bookingDetailsShow.necessaryInfo = necessaryInfo;

    return bookingDetailsShow;
}

function getActivityBookingDetails(bookingDetails) {

    let bookingDetailsShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let tourActivityReservation = bookingDetails.tourActivityReservations[0];
    let globalReservations = bookingDetails.globalReservations;
    let name = tourActivityReservation.basicInfo.name;
    let groupName = tourActivityReservation.pricing.groups[0].groupName;
    let dateTime = tourActivityReservation.pickupDropoffs[0].dateTime;
    let contacter = {}
    tourActivityReservation.participantInfos.forEach(participantInfo => {
        let extensions = participantInfo.category.qualifierInfo.extension;
        if (extensions == 'CONTACT_PERSON'){
            let contact = participantInfo.group.contact;
            let emailAddress = contact.emailList[0].stringValue;
            let phoneNumber = contact.telephoneList[0].phoneNumber;
            let personName = contact.personName;
            let givenName = personName.givenName;
            let surname = personName.surname;
            let nameTitle = personName.nameTitle;
            contacter = {
                emailAddress,
                phoneNumber,
                givenName,
                surname,
                nameTitle
            }
        }
    })

    let bookingTourItemId = '';
    tourActivityReservation.confirmation.forEach(confirmation => {
        if ('BOOKING_TOUR_ITEM_ID' == confirmation.type){
            bookingTourItemId = confirmation.id;
        }
    })

    let questions = [];
    if (tourActivityReservation.description
        && tourActivityReservation.description.multimedia
        && tourActivityReservation.description.multimedia.multimediaDescriptionLists
        && tourActivityReservation.description.multimedia.multimediaDescriptionLists.length>0
        && tourActivityReservation.description.multimedia.multimediaDescriptionLists[0].textItems
    ){
        let textItems = tourActivityReservation.description.multimedia.multimediaDescriptionLists[0].textItems;
        textItems.forEach(textItem => {
            if (textItem.category == "ANSWER"){
                questions.push({
                    code: textItem.title,
                    answer: textItem.descriptionList[0].stringValue
                })
            }

        })
    }

    //voucher
    let vouchers = [];
    if (tourActivityReservation.paymentInfos
    ){
        tourActivityReservation.paymentInfos.forEach(paymentInfo => {
            if ('VOUCHER' == paymentInfo.description){
                vouchers.push({
                    language: paymentInfo.groupID,
                    url: paymentInfo.detail.voucher.identifier
                })
            }
        })
    }

    let freeCancelDate = DateUtils.formatDate(new Date(tourActivityReservation.policy.cancel.cancelDeadline), 'YYYY-MM-DD');

    let bookingId = '';
    globalReservations.forEach( globalRes => {
        if (globalRes.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalRes.uniqueID.id;
        }
    });
    let resStatus = bookingDetails.statusId;
    let bookingStatus = getBookingStatus(resStatus);
    let bookingDate = tourActivityReservation.bookingDate;

    bookingDetailsShow.type = 'ACTIVITY';
    bookingDetailsShow.typeCode = 'ACTIVITY';
    bookingDetailsShow.bookingId = bookingId;
    bookingDetailsShow.statusId = bookingStatus;
    bookingDetailsShow.bookingDate = bookingDate;

    let necessaryInfo = {
        name: name,
        groupName: groupName,
        cityName: '',
        address: '',
        startDate: DateUtils.formatDate(new Date(dateTime), 'YYYY-MM-DD'),
        contacter: contacter,
        freeCancelDate,
        questions,
        bookingTourItemId,
        vouchers
    };

    necessaryInfo.participantCategories = tourActivityReservation.pricing.participantCategories
    bookingDetailsShow.currency = tourActivityReservation.pricing.summary.currencyCode;
    bookingDetailsShow.priceType = tourActivityReservation.pricing.summary.pricingType.extension;

    bookingDetailsShow.necessaryInfo = necessaryInfo;

    return bookingDetailsShow;
}

function getVehicleBookingDetails(booking) {

    //console.info(bookingDetails);
    let bookingDetailsShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let vehicleReservation = booking.vehicleReservations[0];
    let dateTime = vehicleReservation.createDateTime;
    let resStatus = booking.statusId;
    let customer = vehicleReservation.customer;
    let pickUpDateTime = vehicleReservation.vehSegmentCore.vehRentalCore.pickUpDateTime;
    let pickUpLocation = vehicleReservation.vehSegmentCore.vehRentalCore.pickUpLocations[0].locationName;
    let returnDateTime = vehicleReservation.vehSegmentCore.vehRentalCore.returnDateTime;
    let returnLocation = vehicleReservation.vehSegmentCore.vehRentalCore.returnLocations[0].locationName;
    let carName = vehicleReservation.vehSegmentCore.vehicle.vehMakeModel.name;
    let amountIncludingMarkup = vehicleReservation.vehSegmentCore.totalCharge.estimatedTotalAmount;
    let currencyCode = vehicleReservation.vehSegmentCore.totalCharge.currencyCode;

    let bookingId = '';
    booking.globalReservations.forEach( globalReservation => {
        if (globalReservation.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalReservation.uniqueID.id;
        }
    });
    let bookingStatus = getBookingStatus(resStatus);


    bookingDetailsShow.type = 'CAR RENTAL';
    bookingDetailsShow.typeCode = 'CAR_RENTAL';
    bookingDetailsShow.bookingId = bookingId;
    bookingDetailsShow.totalAmount = amountIncludingMarkup;
    bookingDetailsShow.currency = currencyCode;
    bookingDetailsShow.statusId = bookingStatus;
    bookingDetailsShow.bookingDate = dateTime;

    let pricedCoverages = vehicleReservation.vehSegmentInfo.pricedCoverages;
    let coverages = [];
    if (pricedCoverages!=null && pricedCoverages.length>0){
        pricedCoverages.forEach(pricedCoverage => {
            let code = pricedCoverage.coverage.code;
            let description = pricedCoverage.coverage.description;
            let currencyCode = pricedCoverage.charge.currencyCode;
            let amount = pricedCoverage.charge.amount;

            let coverage = {
                code,
                description,
                currencyCode,
                amount
            }
            coverages.push(coverage);
        })
    }
    let fees = vehicleReservation.vehSegmentCore.fees;
    let feeTotal = 0;
    fees.forEach(fee => {
        feeTotal += fee.amount;
    })


    let necessaryInfo = {
        name: carName,
        pickUpDateTime: pickUpDateTime,
        pickUpLocation: pickUpLocation,
        returnDateTime: returnDateTime,
        returnLocation: returnLocation,
        driverInfo: {
            firstName: customer.primary.personName.givenName,
            lastName:  customer.primary.personName.surname,
            title:  customer.primary.personName.nameTitle,
            phoneNumber:  customer.primary.telephoneList[0].phoneNumber,
            nation: '',
            emailAddress: customer.primary.emailList[0].stringValue
        },
        fees:fees,
        feeTotal,
        coverages:coverages
    };

    bookingDetailsShow.necessaryInfo = necessaryInfo;

    return bookingDetailsShow;
}

function getCarTransferBookingDetails(booking) {

    //console.info(bookingDetails);
    let bookingDetailsShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };


    let bookingId = '';
    booking.globalReservations.forEach( globalReservation => {
        if (globalReservation.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalReservation.uniqueID.id;
        }
    });

    let groundReservation = booking.groundReservations[0];
    let primary = groundReservation.passenger.primary;
    let groundServices = groundReservation.groundServices;
    let transfersReviewInfo = {
    };

    let tripMap ={};
    let datetime = groundServices[0].service.location.pickup.dateTime;
    tripMap[datetime] = 'FIRSTTRIP';
    let firstTripPickupDatetimeStr = groundServices[0].service.location.pickup.dateTime.replaceAll('T', ' ');
    let firstTripPickupDate = new Date(firstTripPickupDatetimeStr);
    if (groundServices.length>1){
        groundServices.forEach(groundService => {
            if (!tripMap[groundService.service.location.pickup.dateTime]){
                let datetimeStr = groundService.service.location.pickup.dateTime.replaceAll('T', ' ');
                let compareDate = new Date(datetimeStr);
                if (compareDate.getTime()>firstTripPickupDate.getTime()){
                    tripMap[groundService.service.location.pickup.dateTime] = 'SECONDTRIP';
                }else{
                    tripMap[groundService.service.location.pickup.dateTime] = 'FIRSTTRIP';
                    tripMap[groundServices[0].service.location.pickup.dateTime] = 'SECONDTRIP';
                }
            }
        })
    }

    groundServices.forEach(groundService  => {
        transfersReviewInfo = CarTransferUtils.constructTransferInfo(groundService, transfersReviewInfo, tripMap);
    });

    transfersReviewInfo.confirmations = groundReservation.confirmations;
    transfersReviewInfo.totalAmount = groundReservation.totalCharge.estimatedTotalAmount;
    transfersReviewInfo.currencyCode = groundReservation.totalCharge.currencyCode;
    transfersReviewInfo.holder = {
        firstName: primary.personName.givenName,
        lastName: primary.personName.surname,
        nameTitle: primary.personName.nameTitle,
        phoneNumber: primary.telephoneList[0].phoneNumber,
        emailAddress: primary.emailList[0].stringValue,
    };

    let bookingStatus = getBookingStatus(booking.statusId);

    bookingDetailsShow.statusId = bookingStatus;
    bookingDetailsShow.type = 'CAR TRANSFER';
    bookingDetailsShow.typeCode = 'CAR_TRANSFER';
    bookingDetailsShow.bookingDate = groundReservation.bookingDate;
    bookingDetailsShow.bookingId = bookingId;
    bookingDetailsShow.necessaryInfo = transfersReviewInfo;

    return bookingDetailsShow;
}


function getFlightBookingDetails(booking) {
    //console.info(bookingDetails);
    let bookingDetailsShow = {
        statusId: '',
        type: '',
        bookingId: 0,
        totalAmount: 0,
        currency: '',
        bookingDate: '',
        necessaryInfo: {

        }
    };

    let bookingId = '';
    booking.globalReservations.forEach( globalReservation => {
        if (globalReservation.uniqueID.type == 'BOOKING_ID'){
            bookingId = globalReservation.uniqueID.id;
        }
    });

    // let bookingStatus = getBookingStatus(booking.statusId);
    let bookingStatus = booking.statusId;

    let airReservation = booking.airReservations[0];

    let wrapperAirItinerary = FlightUtils.getWrapperAirItinerary(airReservation.airItinerary);


    let neccesaryInfo = {
        airItinerary: wrapperAirItinerary,
    };
    airReservation.bookingReferenceIdList.forEach( bookingReferenceId => {
        let type = bookingReferenceId.type;
        switch (type) {
            case 'BOOKING_NUMBER':
                neccesaryInfo.bookingNumber = bookingReferenceId.id;
                break;
                case 'INVOICE_NUMBER':
                neccesaryInfo.invoiceNumber = bookingReferenceId.id;
                break;
            case 'RECORD_LOCATOR':
                neccesaryInfo.recordLocator = bookingReferenceId.id;
                break;
            case 'AIRLINE_RECORD_LOCATOR':
                neccesaryInfo.airlineRecordLocator = bookingReferenceId.id;
                break;
            default:
        }
    })

    let passengers = [];
    let adults = 0;
    let children = 0;
    let infants = 0;
    let passengerMap = {}
    if (airReservation.travelerInfo.airTravelerList){
        airReservation.travelerInfo.airTravelerList.forEach(airTraveler => {
            let gender = airTraveler.gender;
            let travelerRefNumber = airTraveler.travelerRefNumber.rph;
            let birthDate = airTraveler.birthDate;
            let passengerTypeCode = airTraveler.passengerTypeCode;
            let personName = airTraveler.personName;
            let namePrefix = personName.namePrefix;
            let givenName = personName.givenName;
            let surname = personName.surname;

            let docID = airTraveler.documents[0].docID;
            let expireDate = airTraveler.documents[0].expireDate;
            let docHolderNationality = airTraveler.documents[0].docHolderNationality;
            passengerMap[travelerRefNumber] = givenName+'/'+surname;

            let passenger = {
                gender,
                birthDate,
                passengerTypeCode,
                namePrefix,
                givenName,
                surname,
                docID,
                docHolderNationality,
                expireDate,
                travelerRefNumber
            };

            if ('ADT' == passengerTypeCode){
                let phoneNumber = airTraveler.telephoneList[0].phoneNumber;
                let emailAddress = airTraveler.emailList[0].emailAddress;
                passenger.phoneNumber = phoneNumber;
                passenger.emailAddress = emailAddress;
                adults +=1;
            }else if ('CNN' == passengerTypeCode){
                children+=1;
            }else {
                infants+=1;
            }

            passengers.push(passenger);
        });
    }

    //offer
    let offers = null;
    if (airReservation.offer && airReservation.offer.priced){
        offers = [];
        airReservation.offer.priced.forEach(pricedOffer => {
            let offerShow = {
                type: pricedOffer.serviceFamily.productGroups[0].code,
                name: pricedOffer.shortDescription,
                passenger: passengerMap[pricedOffer.travelerRPH],
                flight: pricedOffer.flightSegmentRPH.split('-')[1]
            }
            offers.push(offerShow);
        })
    }

    //Amount
    let currencyCode = '';
    let totalAmount = 0;
    if (airReservation.airItineraryPricingInfo && airReservation.airItineraryPricingInfo.PTC_FareBreakdowns){
        airReservation.airItineraryPricingInfo.PTC_FareBreakdowns.forEach(breakdown => {
            let quantity = breakdown.passengerTypeQuantity.quantity;

            breakdown.passengerFareList.forEach( fare => {
                if (fare.usage == 'TicketFee'){
                    totalAmount = fare.totalFare.amount * quantity;
                    currencyCode = fare.totalFare.currencyCode;
                }
            })
        });
    }
    let totalPayment = {
        currencyCode: currencyCode,
        amount: totalAmount
    };

    neccesaryInfo.totalPayment = totalPayment;
    neccesaryInfo.passengers = passengers;
    neccesaryInfo.adults = adults;
    neccesaryInfo.children = children;
    neccesaryInfo.infants = infants;
    if (wrapperAirItinerary.originDestinationOptions.length>0 && wrapperAirItinerary.originDestinationOptions[0].flightSegments){
        neccesaryInfo.name = wrapperAirItinerary.originDestinationOptions[0].flightSegments[0].departureAirportCode;
    }

    neccesaryInfo.offers = offers;

    bookingDetailsShow.statusId = bookingStatus;
    bookingDetailsShow.type = 'FLIGHT';
    bookingDetailsShow.typeCode = 'FLIGHT';
    bookingDetailsShow.bookingDate = airReservation.dateBooked;
    bookingDetailsShow.bookingId = bookingId;
    bookingDetailsShow.necessaryInfo = neccesaryInfo;
    bookingDetailsShow.totalAmount = totalAmount;
    bookingDetailsShow.currency = currencyCode;

    return bookingDetailsShow;
}

/*结束  历史订单相关*/

export default {
    getBookingsRetrieveRQ,
    getBookingStatus,
    getCancelRQ,
    getHotelBookingShow,
    getActivityBookingShow,
    getVehicleBookingShow,
    getCarTransferBookingShow,
    getHotelBookingDetails,
    getCarTransferBookingDetails,
    getVehicleBookingDetails,
    getFlightBookingDetails,
    getActivityBookingDetails

}
