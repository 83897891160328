import * as mutationName from "@/store/modules/hotel/mutationTypes"
import HotelUtils from "@/utils/HotelUtils";
import DateUtils from "@/utils/DateUtils";
import SortUtils from "@/utils/SortUtils";
import FilterUtils from "@/utils/FilterUtils";

const mutations = {
    [mutationName.UPDATE_CITY_HOTELS_AVAIL](state, payload){
        if (payload.cityHotelsAvailRS!=null){
            state.cityHotelsAvailRS = payload.cityHotelsAvailRS;
            let result = HotelUtils.getHotelsByAvailRS(state.cityHotelsAvailRS);
            state.hotels = result.hotels;
            state.resultNum = result.hotels.length;
            state.minHotelPrice = result.minPrice;
            state.maxHotelPrice = result.maxPrice;
            state.currencyCode = result.currencyCode;
            state.hotelsShow = [];
            let hotelsShow = HotelUtils.getHotelsShow(state.hotels, 1, payload.pageSize);
            state.hotelsShow = hotelsShow;
        }else {
            state.cityHotelsAvailRS = {};
            state.hotels =[];
        }
    },
    [mutationName.UPDATE_HOTELS_SHOW](state, payload){
        let currentPage = payload.currentPage;
        let pageSize = payload.pageSize;
        let filterCondition = payload.filterCondition;
        let hotels = state.hotels;
        hotels = FilterUtils.filterHotels(hotels, filterCondition);

        if (payload.sortCondition.sortAttribute!=null && payload.sortCondition.type!=null){
            hotels = SortUtils.sort(hotels,payload.sortCondition.sortAttribute, payload.sortCondition.type);
        }
        state.resultNum = hotels==null?0:hotels.length;

        state.hotelsShow = [];
        let hotelsShow = HotelUtils.getHotelsShow(hotels, currentPage, pageSize);
        state.hotelsShow = hotelsShow;
    },
    [mutationName.UPDATE_HOTEL_DETAILS_AVAIL](state, payload){
        if (payload.hotelDetailsAvailRS!=null){
            state.hotelDetailsAvailRS = payload.hotelDetailsAvailRS;
        }else {
            state.hotelDetailsAvailRS = {}
        }
        let hotelSelected = HotelUtils.getHotelSelected(state.hotelDetailsAvailRS, state.hotelDescriptionRS);
        state.hotelSelected = hotelSelected;
    },
    [mutationName.UPDATE_HOTEL_DESCRIPTION](state, payload){
        if (payload.hotelDescriptionRS!=null){
            state.hotelDescriptionRS = payload.hotelDescriptionRS;
        }else {
            state.hotelDescriptionRS = {}
        }

        let hotelSelected = HotelUtils.getHotelSelected(state.hotelDetailsAvailRS, state.hotelDescriptionRS);
        state.hotelSelected = hotelSelected;
    },
    [mutationName.UPDATE_CONFIRM](state, payload){
        if (payload.confirmRS!=null){
            state.confirmRS = payload.confirmRS;
            state.preBookRQ = payload.preBookRQ;

            let hotelRoomBookingInfo ={
                basicInfo: {
                    hotelName: '',
                    roomType: '',
                    bedTypeName: '',
                    checkIn: '',
                    checkOut: '',
                    roomNum: 0,
                    guestPerRoom: '',
                    durationDays: 0
                },
                passengerInfos: [],
                priceDetails: {
                    pricePerRoom: 0,
                    propertyFees:[],
                    feesAndTaxesPerRoom:0,
                    total: 0
                }
            };

            let hotelReservation = state.confirmRS.hotelReservationList[0];
            let resGlobalInfo = hotelReservation.resGlobalInfo;
            let timeSpan = resGlobalInfo.timeSpan;

            let total = resGlobalInfo.total;
            hotelRoomBookingInfo.priceDetails.total = total.amountIncludingMarkup;
            hotelRoomBookingInfo.priceDetails.currencyCode = total.currencyCode;
            let resGuestList = hotelReservation.resGuestList;
            let resGuestMap =  {};
            resGuestList.forEach(resGuest => {
               resGuestMap[resGuest.resGuestRPH] = resGuest;
            });

            let roomStayList = hotelReservation.roomStayList;
            let guestPerRoom = '';
            let rateComments = '';
            roomStayList.forEach(roomStay => {
                let passengerInfo = {
                  adults: [],
                  childern: []
                };
               roomStay.guestCounts.guestCountList.forEach(guestCount => {
                   if (guestCount.ageQualifyingCode == "10"){
                       passengerInfo.adults.push(resGuestMap[guestCount.resGuestRPH])
                   }else if (guestCount.ageQualifyingCode == "8"){
                       passengerInfo.childern.push(resGuestMap[guestCount.resGuestRPH])
                   }
               });

               guestPerRoom = passengerInfo.adults.length +'adult(s)';
               if (passengerInfo.childern.length>0){
                   guestPerRoom+= ', '+passengerInfo.childern.length+' child(ern)'
               }

               if (roomStay.specialRequests!=null && roomStay.specialRequests.specialRequests!=null
                    && roomStay.specialRequests.specialRequests.length>0
               ){
                   let specialRequest = roomStay.specialRequests.specialRequests[0].text;
                   passengerInfo.remarks = specialRequest;
               }

               roomStay.roomRates.roomRates.forEach(roomRate => {
                   if (roomRate.roomRateDescriptionList){
                       roomRate.roomRateDescriptionList.forEach(rateDescription => {
                           if (rateDescription.name == 'RATE_COMMENTS'){
                               passengerInfo.rateComments = rateDescription.textList[0].stringValue
                           }
                       })
                   }

                   //Property Fees
                   if (roomRate.total&&roomRate.total.taxes&&roomRate.total.taxes.taxes){
                       let taxes = roomRate.total.taxes;
                       hotelRoomBookingInfo.priceDetails.propertyFees =[];
                       taxes.taxes.forEach(tax => {
                           if (tax.type == 'EXCLUSIVE'){
                               let propertyFee = {
                                   amount: tax.amount,
                                   currencyCode: total.currencyCode,
                                   name: tax.taxDescriptions[0].name
                               }

                               if (tax.taxDescriptions[0].listItems){
                                   propertyFee.amountInNet =  tax.taxDescriptions[0].listItems[0].textFormat
                               }

                               hotelRoomBookingInfo.priceDetails.propertyFees.push(propertyFee);
                           }
                       })
                   }

                   //Tax and Fees inclusive
                   let rates = roomRate.rates.rateList;
                   let feesAndTaxes = 0;
                   rates.forEach(rate => {
                       let fees = rate.fees;
                       if (fees){
                           fees.forEach(fee => {
                               let amount = fee.amount;
                               feesAndTaxes += amount;
                           })
                       }
                   })
                   hotelRoomBookingInfo.priceDetails.feesAndTaxesPerRoom = feesAndTaxes;

               })

                //basic info
                let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;

                if (vendorMessages){
                    let propertyContent = {};
                    vendorMessages.forEach(vendorMessage => {
                        let infoType = vendorMessage.infoType;
                        let paragraphs = vendorMessage.subSections[0].paragraphs;
                        propertyContent[infoType] = paragraphs;
                    })
                    hotelRoomBookingInfo.basicInfo.propertyContent = propertyContent;
                }

               hotelRoomBookingInfo.passengerInfos.push(passengerInfo);
            });


            //基础信息
            let basicPropertyInfo = roomStayList[0].basicPropertyInfo;
            let roomType = roomStayList[0].roomTypes[0];
            let roomTotal = roomStayList[0].total;
            hotelRoomBookingInfo.priceDetails.pricePerRoom = roomTotal.amountIncludingMarkup - hotelRoomBookingInfo.priceDetails.feesAndTaxesPerRoom ;
            hotelRoomBookingInfo.priceDetails.rateComments = rateComments;

            hotelRoomBookingInfo.basicInfo.hotelName = basicPropertyInfo.hotelName;
            hotelRoomBookingInfo.basicInfo.roomType = roomType.roomType;
            hotelRoomBookingInfo.basicInfo.bedTypeName = roomType.bedTypeName;
            hotelRoomBookingInfo.basicInfo.checkIn = DateUtils.format(new Date(timeSpan.start));
            hotelRoomBookingInfo.basicInfo.checkOut = DateUtils.format(new Date(timeSpan.end));
            hotelRoomBookingInfo.basicInfo.roomNum = roomStayList.length;
            hotelRoomBookingInfo.basicInfo.guestPerRoom = guestPerRoom;
            hotelRoomBookingInfo.basicInfo.durationDays = DateUtils.getDayDurationByDates(timeSpan.start, timeSpan.end);

            state.hotelRoomBookingInfo = hotelRoomBookingInfo;

        }else {
            state.confirmRS = {}
        }
    },
    [mutationName.UPDATE_PREBOOK](state, payload){
        if (payload.preBookRS!=null){
            state.preBookRS = payload.preBookRS;
            let total = state.preBookRS.hotelReservationList[0].resGlobalInfo.total;
            let totalPayment = {
                currencyCode: total.currencyCode,
                amount: total.amountIncludingMarkup
            }
            state.totalPayment = totalPayment;
        }else {
            state.preBookRS = {}
        }
    },
    [mutationName.UPDATE_BOOK](state, payload){
        if (payload.bookRS!=null){
            state.bookRS = payload.bookRS;
        }else {
            state.bookRS = {}
        }
    }

};

export default mutations;
