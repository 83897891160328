/*开始 列表页相关*/
function getTourActivitySearchRQ(destinationCode, startDate, endDate) {

    return {
        "searchCriteria": {
            "dateTimePref": {
                "start": startDate,
                "end": endDate
            },
            "locationPrefs": [
                {
                    "region": {
                        "regionCode": destinationCode
                    }
                }
            ],
            "customerCounts": [
                {
                    "age": 30,
                    "quantity": 1
                }
            ]
        }
    }
}

function getActivitiesBySearchRS(activitiesSearchRS) {

    let result = {
        minPrice: null,
        maxPrice: null,
        currencyCode: '',
        activities: []
    };
    activitiesSearchRS.tourActivityInfos.forEach(tourActivity => {
        let activity = {
            name: tourActivity.basicInfo.name,
            id: tourActivity.basicInfo.tourActivityID,
            locationName: tourActivity.location.locationName+', '+tourActivity.location.address.countryName.text,
            price: tourActivity.pricing.minPrice,
            currencyCode: tourActivity.pricing.currencyCode,
            pictureURLs: [],
            description: tourActivity.basicInfo.tpaExtensionsType.valueMap.BASIC_DESCRIPTION,
            productURL: tourActivity.basicInfo.tpaExtensionsType.valueMap.PRODUCT_URL
        };

        let amountIncludingMarkup = tourActivity.pricing.minPrice;
        result.currencyCode = tourActivity.pricing.currencyCode;
        if (result.minPrice==null || result.minPrice > amountIncludingMarkup){
            result.minPrice = amountIncludingMarkup;
        }
        if (result.maxPrice ==null || result.maxPrice<amountIncludingMarkup){
            result.maxPrice = amountIncludingMarkup;
        }

        tourActivity.description.multimedia.multimediaDescriptionLists[0].imageItems.forEach(image =>{
            let dimensionCategory = image.imageFormatList[0].dimensionCategory;
            if ("LARGE" == dimensionCategory){
                activity.pictureURLs.push(image.url);
            }
        });

        result.activities.push(activity);

    });
    return result;
}

function getActivitiesShow(activities, currentPage, pageSize) {
    let totalPages = activities.length<= pageSize? 1: Math.ceil(activities.length/pageSize);

    let starIndex = pageSize * (currentPage -1);
    let endIndex = activities.length<=pageSize || totalPages==currentPage? activities.length : pageSize*currentPage;
    return activities.slice(starIndex, endIndex);
}

/*结束 列表页相关*/

/*开始 详情页页相关*/
function getTourActivityAvailRQ(tourActivityID, startDate, endDate, participantCounts, groupCode) {
    let groupInfo = null;
    if (groupCode!=null){
        groupInfo = {
            groupCode: groupCode
        }
    }

    return {
        "tourActivity": {
            "basicInfos": [
                {
                    "tourActivityID": tourActivityID
                }
            ],
            "schedules": [
                {
                    "startPeriod": startDate,
                    "endPeriod": endDate
                }
            ],
            "participantCounts": participantCounts,
            "groupInfo": groupInfo
        }
    }
}

function getTourActivityDetail(tourActivityInfo) {

    let basicInfo = tourActivityInfo.basicInfo;
    let tourActivityDetail = {
        name: basicInfo.name,
        tourActivityID: basicInfo.tourActivityID,
        operationDates: null,
        images: [],
        textItemMap: {},
        ticketAgeCategory:[],
        cancelPolicy:{}
    };
    let policies = tourActivityInfo.policies;
    if (policies && policies.cancel && policies.cancel.cancelDeadline){
        tourActivityDetail.cancelPolicy.absoluteDeadline = policies.cancel.cancelDeadline;
    }

    let multimediaDescription = tourActivityInfo.description.multimedia.multimediaDescriptionLists[0];
    let imageItems = multimediaDescription.imageItems;
    let textItems = multimediaDescription.textItems;

    imageItems.forEach( imageItem => {
        let dimensionCategory = imageItem.imageFormatList[0].dimensionCategory;
        if (dimensionCategory == 'XLARGE'){
            let url = imageItem.url;
            tourActivityDetail.images.push(url);
        }
    });

    textItems.forEach(textItem => {
        if (!tourActivityDetail.textItemMap[textItem.category]){
            tourActivityDetail.textItemMap[textItem.category] = [];
        }
        tourActivityDetail.textItemMap[textItem.category].push({
            title: textItem.title,
            contentID: textItem.contentID,
            descriptions: textItem.descriptionList
        })
    })

    let pricing = tourActivityInfo.pricing;
    let groups = pricing.groups;
    let participantCategories = pricing.participantCategories;
    participantCategories.forEach(participantCategory => {

        if (participantCategory.price.rph == tourActivityDetail.tourActivityID ){
            tourActivityDetail.ticketAgeCategory.push(participantCategory)
        }else {
            groups.forEach(group => {

                if (group.groupCode == participantCategory.price.rph){
                    group.categories ==null? group.categories=[]:'';
                    group.categories.push(participantCategory);
                }
            })
        }
    });

    tourActivityDetail.ticketAgeCategory = tourActivityDetail.ticketAgeCategory.sort((a, b) => {
        return a.minAge - b.minAge;
    });
    groups.forEach(group => {
        let languages = [];
        let languageKeyMap = {};
        let sessions = [];
        let sessionKeyMap = {}
        group.categories = group.categories.sort((a, b) => {
            return a.minAge - b.minAge;
        });

        group.categories.forEach(category => {
            let language = category.tpaExtensionsType.valueMap.LANGUAGE;
            if (!languageKeyMap[language]){
                languageKeyMap[language] = language;
                languages.push({
                    value: language,
                    label: language
                });
            }
            let session = category.tpaExtensionsType.valueMap.SESSION;
            if (!sessionKeyMap[session]){
                sessionKeyMap[session] = session;
                sessions.push({
                    value: session,
                    label: session
                });
            }
        })
        group.languages = languages;
        group.sessions = sessions;

        if (!tourActivityDetail.operationDates){
            tourActivityDetail.operationDates = group.prices[0].operationDates;
        }
        group.questions = [];

        if (tourActivityDetail.textItemMap['QUESTION']){
            tourActivityDetail.textItemMap['QUESTION'].forEach(textItem => {
                if (textItem.contentID == group.groupCode){
                    group.questions.push(textItem);
                }
            })
        }
    });
    tourActivityDetail.groups = groups;

    //activity schedule
    tourActivityInfo.schedule.details.forEach(detail => {

        if (!detail.tpaExtensionsType){
            tourActivityDetail.schedule = detail;
        }
    });



    return tourActivityDetail;
}

/*结束 详情页相关*/

/*开始 预定页相关*/
function getTourActivityPreconfirmRQ(rateKey, selectedDate, holder, username) {

    let tpaExtensionsType=null;
    if (holder.questions && Object.keys(holder.questions).length>0){
        let questionAndAnswers = [];
        for (const questionCode in holder.questions) {
            let question = holder.questions[questionCode];
            let questionAndAnswer = {
                code: questionCode,
                answer: question.answer
            }
            questionAndAnswers.push(questionAndAnswer)
        }

        tpaExtensionsType = {
            valueMap: {
                ANSWER: JSON.stringify(questionAndAnswers),
                SESSION: holder.session,
                LANGUAGE: holder.language
            }
        }
    }

    let participantInfoList = [];
    participantInfoList.push(
        {
            "category": {
                "contact": {
                    "contactType": "HOLDER",
                    "personName": {
                        "surname": holder.lastName,
                        "givenName": holder.firstName,
                        "nameTitle": holder.title
                    },
                    "addressList": [
                        {
                            "addressLines": [
                                holder.address
                            ],
                            "countryName":{
                                "code": holder.countryCode
                            }
                        }
                    ],
                    "emailList": [
                        {
                            "stringValue": holder.emailAddress
                        }
                    ],
                    "telephoneList": [
                        {
                            "phoneNumber": holder.phoneNumber
                        }
                    ]
                }
            }
        }
    )

    if (holder.paxes){
        holder.paxes.forEach(pax => {
            participantInfoList.push(
                {
                    "category": {
                        "age": pax.age==null?30:pax.age,
                        "ageQualifyingCode": pax.ageQualifyingCode,
                        "contact": {
                            "contactType": "PAX",
                            "personName": {
                                "surname": pax.lastName,
                                "givenName": pax.firstName
                            }
                        }
                    }
                }
            )
        })
    }

    return {
        "bookingInfo": {
            "resStatus": "Initiate",
            "pricing": {
                "summary": {
                    "rph": rateKey
                }
            },
            "schedule": {
                "start": selectedDate,
                "end": selectedDate
            },
            "participantInfoList": participantInfoList,
            "paymentInfoList": [
                {
                    "rph": username
                }
            ],
            "tpaExtensionsType": tpaExtensionsType
        },
        "pos": {
            "sourceList": [
                {
                    "bookingChannel": {
                        "companyName": {
                            "code": "BOOKINGENGINE"
                        },
                        "type": "7"
                    },
                    "requestorID": {
                        "id": "admin"
                    }
                }
            ]
        }
    }
}

function getTourActivityBookRQ(tourActivityPreconfirmRQ, tourActivityPreconfirmRS) {

    tourActivityPreconfirmRQ.resStatus = 'Book';
    tourActivityPreconfirmRQ.bookingInfo.resStatus = 'Book';
    tourActivityPreconfirmRQ.bookingInfo.confirmation = tourActivityPreconfirmRS.reservationDetails.confirmation;

    let uuid = '';
    tourActivityPreconfirmRQ.bookingInfo.confirmation.forEach(uniqueId => {
        if ('UUID' == uniqueId.type){
            uuid = uniqueId.id;
        }
    })
    tourActivityPreconfirmRQ.bookingInfo.pricing.summary.rph = uuid;
    return tourActivityPreconfirmRQ;
}
/*结束 预定页相关*/

export default {
    getTourActivitySearchRQ,
    getTourActivityAvailRQ,
    getTourActivityPreconfirmRQ,
    getTourActivityBookRQ,
    getActivitiesShow,
    getActivitiesBySearchRS,
    getTourActivityDetail
}
