import axios from "@/config/AxiosConfig";
import InsuranceUtils from "@/utils/InsuranceUtils";

function quote(requestorId, passengerInfos, destinations) {
    let url = "/api/v1/insurance/quote";
    let quoteRQ = InsuranceUtils.getQuoteRQ(requestorId, passengerInfos, destinations);
    return axios.post(url, quoteRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

export default {
    quote
}