import DateUtils from "@/utils/DateUtils";

function getQuoteRQ(requestorId, passengerInfos, destinations) {

    let coveredTravelers= [];
    let insuranceCustomer=null;
    let i=1;
    passengerInfos.forEach(passengerInfo => {

        if (passengerInfo.passengerTypeCode == "ADT" && insuranceCustomer==null){

            insuranceCustomer = {
                "birthDate": passengerInfo.birth,
                "personName": {
                    "givenName": passengerInfo.firstName,
                    "surname": passengerInfo.lastName,
                    "nameTitle": passengerInfo.title,
                    "travelerRefNumber": passengerInfo.passengerTypeCode+"-"+i
                },
                "documentList": [
                    {
                        "docID": passengerInfo.passportNumber,
                        "docType": "2",
                        "docIssueCountry": passengerInfo.passportCountryCode
                    }
                ]
            }

            coveredTravelers.push(
                {
                    "coveredPerson": {
                        "birthDate": passengerInfo.birth,
                        "givenName": passengerInfo.firstName,
                        "surname": passengerInfo.lastName,
                        "nameTitle": passengerInfo.title,
                        "travelerRefNumber": passengerInfo.passengerTypeCode+"-"+i,
                        "relation": "policy holder"
                    },
                    "documents": [
                        {
                            "docID": passengerInfo.passportNumber,
                            "docType": "2",
                            "docIssueCountry": passengerInfo.passportCountryCode
                        }
                    ]
                }
            )
        }else {
            coveredTravelers.push(
                {
                    "coveredPerson": {
                        "birthDate": passengerInfo.birth,
                        "givenName": passengerInfo.firstName,
                        "surname": passengerInfo.lastName,
                        "nameTitle": passengerInfo.title,
                        "travelerRefNumber": passengerInfo.passengerTypeCode+"-"+i,
                        // "relation": "policy holder"
                    },
                    "documents": [
                        {
                            "docID": passengerInfo.passportNumber,
                            "docType": "2",
                            "docIssueCountry": passengerInfo.passportCountryCode
                        }
                    ]
                }
            )
        }


    })

    let coveredTrips = []
    destinations.forEach(destination => {

        let coveredTrip = {
            "origin": {
                "countryName": {
                    "code": destination.originalDepartureCode
                }
            },
            "destinations": [
                {
                    "cityName": destination.destinationArrivalCode,
                    "countryName": {
                        "code": destination.destinationArrivalCode
                    }
                }
            ],
            "start": DateUtils.formatDate(destination.originalDepartureDateTime, 'YYYY-MM-DD'),
            "end": DateUtils.formatDate(destination.destinationArrivalDateTime, 'YYYY-MM-DD')
        }

        coveredTrips.push(coveredTrip)
    })

    return {
        "pos": {
            "sourceList": [
                {
                    "requestorID": {
                        "id": requestorId
                    }
                }
            ]
        },
        "planForQuoteRQList": [
            {
                "coveredTravelers": coveredTravelers,
                "insCoverageDetail": {
                    "coveredTrips": coveredTrips
                },
                "insuranceCustomer": insuranceCustomer
            }
        ]
    }
}

export default {
    getQuoteRQ
}