
const getters = {
    vehicles(state){
        return state.vehicles;
    },
    vehiclesShow(state){
        return state.vehiclesShow;
    },
    minVehiclePrice(state){
        return state.minVehiclePrice;
    },
    maxVehiclePrice(state){
        return state.maxVehiclePrice;
    },
    currencyCode(state){
        return state.currencyCode;
    },
    vehicleResultNum(state){
        return state.vehicleResultNum;
    },
    vehicleBrands(state){
        return state.vehicleBrands;
    },
    transmissionTypes(state){
        return state.transmissionTypes;
    },
    // 详情页相关
    vehicleDetail(state){
        return state.vehicleDetail;
    },
    // 预定页相关
    driverInfo(state) {
      return state.driverInfo;
    },
    vehBookingReviewInfo(state){
        return state.vehBookingReviewInfo;
    },
    tourActivityBookRQ(state){
        return state.tourActivityBookRQ;
    },
    totalPayment(state) {
        return state.totalPayment;
    }
};

export default getters;
