
function sort(arr, sortAttribute, sortType) {
    switch (sortAttribute) {
        case 'price':
            if ('ASC' == sortType){
                return arr.sort(comparePriceASC);
            }else if ('DSC' == sortType){
                return arr.sort(comparePriceDSC);
            }
            break;
        case 'userRating':
            if ('ASC' == sortType){
                return arr.sort(compareRatingASC);
            }else if ('DSC' == sortType){
                return arr.sort(compareRatingDSC);
            }
            break;
        case 'star':
            if ('ASC' == sortType){
                return arr.sort(compareStarASC);
            }else if ('DSC' == sortType){
                return arr.sort(compareStarDSC);
            }
            break;
        case 'name':

            if (arr.length>0){
                let notChineseRegx = /[^\u4E00-\u9FA5]/;
                let noChinese = notChineseRegx.test(arr[0].name);
                if (noChinese){
                    if ('ASC' == sortType){
                        return arr.sort(compareStrASC);
                    }else if ('DSC' == sortType){
                        return arr.sort(compareStrDSC);
                    }
                }

                if ('ASC' == sortType){
                    return arr.sort(compareCNStrASC);
                }else if ('DSC' == sortType){
                    return arr.sort(compareCNStrDSC);
                }
            }

            break;
        default:
            if (arr.length>0){
                let notChineseRegx = /[^\u4E00-\u9FA5]/;
                let noChinese = notChineseRegx.test(arr[0][sortAttribute]);
                if (noChinese){
                    if ('ASC' == sortType){
                        return arr.sort((a,b)=>compareStrAttASC(a,b, sortAttribute));
                    }else if ('DSC' == sortType){
                        return arr.sort((a,b)=>compareStrAttDSC(a,b, sortAttribute));
                    }
                }

                if ('ASC' == sortType){
                    return arr.sort((a,b)=>compareCNStrAttASC(a,b, sortAttribute));
                }else if ('DSC' == sortType){
                    return arr.sort((a,b)=>compareCNStrAttDSC(a,b, sortAttribute));
                }
            }
    }
}

function str(arr, sortType) {
    switch (sortType) {
        case 'ASC':
            return arr.sort(compareStrASC);
        case 'DSC':
            return arr.sort(compareStrDSC);
        default:
    }
}

function comparePriceASC(a, b) {
    return a.price-b.price;
}

function comparePriceDSC(a,b) {
    return b.price-a.price;
}

function compareRatingASC(a, b) {
    return a.rating-b.rating;
}

function compareRatingDSC(a,b) {
    return b.rating-a.rating;
}

function compareStarASC(a, b) {
    return a.star-b.star;
}

function compareStarDSC(a,b) {
    return b.star-a.star;
}

function compareStrASC(a, b) {
    return a.name.localeCompare(b.name);
}

function compareStrDSC(a,b) {
    return b.name.localeCompare(a.name);
}

function compareStrAttASC(a, b,sortAttribute) {
    return a[sortAttribute].localeCompare(b[sortAttribute]);
}

function compareStrAttDSC(a,b,sortAttribute) {
    return b[sortAttribute].localeCompare(a[sortAttribute]);
}
function compareCNStrASC(a, b) {
    return a.name.localeCompare(b.name,'zh-CN');
}

function compareCNStrDSC(a,b) {
    return b.name.localeCompare(a.name, 'zh-CN');
}
function compareCNStrAttASC(a, b, sortAttribute) {
    return a[sortAttribute].localeCompare(b[sortAttribute],'zh-CN');
}

function compareCNStrAttDSC(a,b, sortAttribute) {
    return b[sortAttribute].localeCompare(a[sortAttribute], 'zh-CN');
}

function getHotelSortCondition(hotelsSortedCondition) {
    let sortCondition = {};
    if (hotelsSortedCondition.priceModel!='1'){
        sortCondition.sortAttribute = "price";
        sortCondition.type = hotelsSortedCondition.priceModel;
    }else if (hotelsSortedCondition.starModel!='1'){
        sortCondition.sortAttribute = "star";
        sortCondition.type = hotelsSortedCondition.starModel;
    }else if (hotelsSortedCondition.userRatingModel!='1'){
        sortCondition.sortAttribute = "userRating";
        sortCondition.type = hotelsSortedCondition.userRatingModel;
    }else if (hotelsSortedCondition.nameModel!='1'){
        sortCondition.sortAttribute = "name";
        sortCondition.type = hotelsSortedCondition.nameModel;
    }
    return sortCondition;
}

function getVehiclesSortCondition(vehiclesSortedCondition) {
    let sortCondition = {};
    if (vehiclesSortedCondition.priceModel!='1'){
        sortCondition.sortAttribute = "price";
        sortCondition.type = vehiclesSortedCondition.priceModel;
    }else if (vehiclesSortedCondition.nameModel!='1'){
        sortCondition.sortAttribute = "name";
        sortCondition.type = vehiclesSortedCondition.nameModel;
    }
    console.info(sortCondition);
    return sortCondition;
}

function getCarTransfersSortCondition(carTransfersSortedCondition) {
    let sortCondition = {};
    if (carTransfersSortedCondition.priceModel!='1'){
        sortCondition.sortAttribute = "price";
        sortCondition.type = carTransfersSortedCondition.priceModel;
    }else if (carTransfersSortedCondition.nameModel!='1'){
        sortCondition.sortAttribute = "name";
        sortCondition.type = carTransfersSortedCondition.nameModel;
    }
    console.info(sortCondition);
    return sortCondition;
}

function getFlightSortCondition(flightSortedCondition) {
    let sortCondition = {};
    if (flightSortedCondition.priceModel!='1'){
        sortCondition.sortAttribute = "price";
        sortCondition.type = flightSortedCondition.priceModel;
    }else if (flightSortedCondition.nameModel!='1'){
        sortCondition.sortAttribute = "name";
        sortCondition.type = flightSortedCondition.nameModel;
    }
    console.info(sortCondition);
    return sortCondition;
}

function getActivitiesSortCondition(activitySortedCondition) {
    let sortCondition = {};
    if (activitySortedCondition.priceModel!='1'){
        sortCondition.sortAttribute = "price";
        sortCondition.type = activitySortedCondition.priceModel;
    }else if (activitySortedCondition.nameModel!='1'){
        sortCondition.sortAttribute = "name";
        sortCondition.type = activitySortedCondition.nameModel;
    }
    console.info(sortCondition);
    return sortCondition;
}

export default {
    sort,
    str,
    getHotelSortCondition,
    getVehiclesSortCondition,
    getCarTransfersSortCondition,
    getActivitiesSortCondition,
    getFlightSortCondition
}
