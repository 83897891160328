import * as mutationName from "@/store/modules/touractivity/mutationTypes"
import TourActivityUtils from "@/utils/TourActivityUtils";
import FilterUtils from "@/utils/FilterUtils";
import SortUtils from "@/utils/SortUtils";

const mutations = {
    [mutationName.UPDATE_ACTIVITIES_INFO](state, payload){
        state.minActivityPrice = 0;
        state.maxActivityPrice = 0;
        if (payload.activitiesSearchRS!=null && payload.activitiesSearchRS.tourActivityInfos!=null
                && payload.activitiesSearchRS.tourActivityInfos.length>0

        ){
            let result = TourActivityUtils.getActivitiesBySearchRS(payload.activitiesSearchRS);
            state.activities= result.activities;
            state.activityResultNum = result.activities.length;
            state.minActivityPrice = result.minPrice;
            state.maxActivityPrice = result.maxPrice;
            state.resultNum = result.activities.length;
            state.currencyCode = result.currencyCode;

            state.activitiesShow = [];
            let activitiesShow = TourActivityUtils.getActivitiesShow(state.activities, 1, 10);
            console.info(activitiesShow);
            state.activitiesShow = activitiesShow;
        }else {
            state.activities =[];
            state.activitiesShow =[];
            state.activityResultNum = 0;
            state.minActivityPrice = 0;
            state.maxActivityPrice = 0;
            state.resultNum = 0;
            state.currencyCode = '';
        }
    },
    [mutationName.UPDATE_ACTIVITIES_SHOW](state, payload){
        let currentPage = payload.currentPage;
        let pageSize = payload.pageSize;
        let filterCondition = payload.filterCondition;
        let activities = state.activities;
        activities = FilterUtils.filterActivities(activities, filterCondition);

        if (payload.sortCondition.sortAttribute!=null && payload.sortCondition.type!=null){
            activities = SortUtils.sort(activities,payload.sortCondition.sortAttribute, payload.sortCondition.type);
        }
        state.resultNum = activities==null?0:activities.length;

        state.activitiesShow = [];
        let activitiesShow = TourActivityUtils.getActivitiesShow(activities, currentPage, pageSize);
        state.activitiesShow = activitiesShow;
    },
    [mutationName.UPDATE_ACTIVITY_DETAIL](state, payload){

        if (payload.tourActivityAvailRS && payload.tourActivityAvailRS.tourActivityInfoList){
            state.tourActivityAvailRS = payload.tourActivityAvailRS.tourActivityInfoList[0]
            let tourActivityDetail = TourActivityUtils.getTourActivityDetail(state.tourActivityAvailRS);
            console.info(tourActivityDetail)
            state.tourActivityDetail = tourActivityDetail;
        }else {
            state.tourActivityDetail = {};
            state.tourActivityAvailRS = {}
        }
    },
    [mutationName.UPDATE_SELECTED_ACITIVITY_INFO](state, payload){
        if (payload.selectedActivityInfo){
            state.selectedActivityInfo = payload.selectedActivityInfo
        }else {
            state.selectedActivityInfo = {};
        }
    },
    [mutationName.UPDATE_CONTACT_INFO](state, payload){
        if (payload.tourActivityPassengerModel){
            state.tourActivityPassengerModel = payload.tourActivityPassengerModel
        }else {
            state.tourActivityPassengerModel = {};
        }
    },
    [mutationName.PREBOOK_ACTIVITY](state, payload){
        if (payload.tourActivityPrebookRS){
            state.tourActivityPrebookRS = payload.tourActivityPrebookRS;
            let amount = state.tourActivityPrebookRS.reservationDetails.pricing.groups[0].price.amount;
            let currencyCode = state.tourActivityPrebookRS.reservationDetails.pricing.groups[0].price.currencyCode;
            let totalPayment = {
                amount: amount,
                currencyCode: currencyCode
            }
            let tourActivityBookRQ = TourActivityUtils.getTourActivityBookRQ(payload.tourActivityBookRQ, payload.tourActivityPrebookRS);
            state.totalPayment = totalPayment;
            state.tourActivityBookRQ = tourActivityBookRQ;
        }else {
            state.tourActivityPrebookRS = {};
            state.tourActivityBookRQ={}
        }
    }
};

export default mutations;
