const state={
    /*列表页相关*/
    activitiesSearchRS: {},
    activities:[],
    activitiesShow: [],
    activityResultNum: 0,
    minActivityPrice: 0,
    maxActivityPrice: 0,
    currencyCode: '',
    /*详情页相关*/
    tourActivityDetail: {},
    /*预定页面相关*/
    selectedActivityInfo: {},
    tourActivityPassengerModel: {},
    totalPayment: {
        currencyCode: '',
        amount: ''
    },
    tourActivityPrebookRS: {},
    tourActivityBookRQ: {}
};

export default state;
