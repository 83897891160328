    /*开始 列表页相关*/

    import DateUtils from "@/utils/DateUtils";

    function getLowfareSearchRQ(originDestiations, adultNum, youthNum, childNum, infantNum, cabinPrefs) {
        //console.info(request)
        let passengerTypeQuantityList = [];
        if (adultNum > 0) {
            passengerTypeQuantityList.push({
                "code": "ADT",
                "quantity": adultNum
            })
        }
        if (youthNum > 0) {
            passengerTypeQuantityList.push({
                "age": "14",
                "code": "ADT",
                "quantity": youthNum
            })
        }
        if (childNum > 0) {
            passengerTypeQuantityList.push({
                "code": "CNN",
                "quantity": childNum
            })
        }
        if (infantNum > 0) {
            passengerTypeQuantityList.push({
                "code": "INF",
                "quantity": infantNum
            })
        }

        return {
            "originDestinationInformationList": originDestiations,
            "travelerInfoSummary": {
                "airTravelerAvailList": [
                    {
                        "passengerTypeQuantityList": passengerTypeQuantityList
                    }
                ],
                "priceRequestInformation": {}
            },
            "specificFlightInfo": {
                "airline": {
                    "code": "YY"
                }
            },
            "travelPreferencesList": [
                {
                    "cabinPrefList": cabinPrefs
                }
            ]
        }
    }

    function getSpecificFlightItineraries(pricedItineraryInfos, groupNumber, origDestRph) {
        let flightItinerariesResult = {
            transitOptions: [],
            airlineOptions: [],
            departureAirportOptions: [],
            arrivalAirportOptions: [],
            minFlyingTime: 0,
            maxFlyingTime: 0,
            minFlightPrice: 0,
            maxFlightPrice: 0,
            flightItineraries: []
        };
        let flightsMap = {};
        pricedItineraryInfos.forEach(pricedItineraryInfo => {
            if (groupNumber>0){
                let originDestinationOptionPre = pricedItineraryInfo.airItinerary.originDestinationOptions[groupNumber-1];
                // console.info(originDestinationOptionPre)
                if (originDestinationOptionPre.rph == origDestRph){
                    flightsMap = getFlightMap(pricedItineraryInfo, groupNumber, flightsMap);
                }
            }else {
                flightsMap = getFlightMap(pricedItineraryInfo, groupNumber, flightsMap);
            }
        });

        let airlineMap = {};
        let departureAirportCodeMap = {};
        let arrivalAirportCodeMap = {};
        for (let key in flightsMap) {
            let flightShow = {
                price: null,
                currencyCode: '',
                originDestinationOptions: []
            };
            let bundleKeyMap = {};
            flightsMap[key].forEach(originDestinationOption => {
                let totalAmount = originDestinationOption.totalAmount;
                if (!flightShow.price || flightShow.price > totalAmount) {
                    flightShow.price = totalAmount;
                    flightShow.lowestBundleId = originDestinationOption.bundleID;
                    flightShow.currencyCode = originDestinationOption.currencyCode;
                    bundleKeyMap[originDestinationOption.bundleID] = originDestinationOption;

                    //最高最低价格
                    if (flightItinerariesResult.minFlightPrice == 0 || flightItinerariesResult.minFlightPrice > flightShow.price) {
                        flightItinerariesResult.minFlightPrice = flightShow.price;
                    }
                    if (flightItinerariesResult.maxFlightPrice == 0 || flightItinerariesResult.maxFlightPrice < totalAmount) {
                        flightItinerariesResult.maxFlightPrice = totalAmount;
                    }
                }
                if (!bundleKeyMap[originDestinationOption.bundleID]) {
                    bundleKeyMap[originDestinationOption.bundleID] = originDestinationOption;
                    // originDestinationOptionMap[originDestinationOption.bundleID+":"+totalAmount] = originDestinationOption.bundleID;
                }
                // 防止没有bundleID，但是，价格不一样的情况
                // else if (!originDestinationOptionMap[originDestinationOption.bundleID+":"+totalAmount]){
                //     bundleKeyMap[originDestinationOption.bundleID+":"+totalAmount] = originDestinationOption;
                //     originDestinationOptionMap[originDestinationOption.bundleID+":"+totalAmount] = originDestinationOption.bundleID;
                // }

                //最短最长时长
                let durationInMins = originDestinationOption.durationInMins;
                if (flightItinerariesResult.minFlyingTime == 0 || flightItinerariesResult.minFlyingTime > durationInMins) {
                    flightItinerariesResult.minFlyingTime = durationInMins;
                }
                if (flightItinerariesResult.maxFlyingTime == 0 || flightItinerariesResult.maxFlyingTime < durationInMins) {
                    flightItinerariesResult.maxFlyingTime = durationInMins;
                }

                //机场
                departureAirportCodeMap[originDestinationOption.originalDepartureCode] = originDestinationOption.originalDepartureCode;
                arrivalAirportCodeMap[originDestinationOption.destinationArrivalCode] = originDestinationOption.destinationArrivalCode;

                //航空公司
                originDestinationOption.flightSegments.forEach(flightSegment => {
                    airlineMap[flightSegment.operatingAirlineCode] = flightSegment.operatingAirlineCode;
                })
            });
            for (let bundleKey in bundleKeyMap) {
                flightShow.originDestinationOptions.push(bundleKeyMap[bundleKey]);
            }

            flightItinerariesResult.flightItineraries.push(flightShow);
        }

        for (let key in airlineMap) {
            flightItinerariesResult.airlineOptions.push(airlineMap[key])
        }
        for (let key in departureAirportCodeMap) {
            flightItinerariesResult.departureAirportOptions.push(departureAirportCodeMap[key])
        }
        for (let key in arrivalAirportCodeMap) {
            flightItinerariesResult.arrivalAirportOptions.push(arrivalAirportCodeMap[key])
        }
        console.info(flightItinerariesResult);
        return flightItinerariesResult;
    }

    function getFlightMap(pricedItineraryInfo, groupNumber, flightsMap) {
        let originDestinationOption = pricedItineraryInfo.airItinerary.originDestinationOptions[groupNumber];

        if (!flightsMap[originDestinationOption.rph]) {
            flightsMap[originDestinationOption.rph] = []
        }

        //
        let passengerFareList = pricedItineraryInfo.airItineraryPricingInfo.PTC_FareBreakdowns[0].passengerFareList;
        let totalAmount = pricedItineraryInfo.airItineraryPricingInfo.itinTotalFares[0].totalFare.amount;
        let currencyCode = pricedItineraryInfo.airItineraryPricingInfo.PTC_FareBreakdowns[0]
            .passengerFareList[0].baseFare.currencyCode;
        originDestinationOption.totalAmount = totalAmount;
        originDestinationOption.currencyCode = currencyCode;
        originDestinationOption.passengerFareList = passengerFareList;
        originDestinationOption.priceType = pricedItineraryInfo.priceType;
        flightsMap[originDestinationOption.rph].push(originDestinationOption);

        return flightsMap;
    }

    function getItinerariesShow(flightItineraries, currentPage, pageSize) {
        let totalPages = flightItineraries.length <= pageSize ? 1 : Math.ceil(flightItineraries.length / pageSize);

        let starIndex = pageSize * (currentPage - 1);
        let endIndex = flightItineraries.length <= pageSize || totalPages == currentPage ? flightItineraries.length : pageSize * currentPage;
        return flightItineraries.slice(starIndex, endIndex);
    }

    /*结束 请求相关*/

    /*开始 详情页相关*/
    function getVerifyPriceRQ(airItinerary, passengerTypeQuantityList) {

        return {
            "airItinerary": airItinerary,
            "travelerInfoSummary": {
                "airTravelerAvailList": [
                    {
                        "passengerTypeQuantityList": passengerTypeQuantityList
                    }
                ]
            }
        }
    }

    function getAirRuleRQ(rph) {

        return {

            "abbreviatedRuleTextInd": false,
            "ruleReqInfo": {
                // this rph is from airprice RS
                "rph": rph
            }
        }
    }

    /*结束 详情页相关*/

    /*开始 Offer相关*/
    function getAirGetOfferRQ(qutoId) {
        return {
            "requestCriterion": {
                "airItinerary": {
                    "itineraries": [
                        {
                            "priced": false,
                            "rph": qutoId
                        }
                    ]
                }
            }
        }
    }

    function getPriceWithOfferRQ(priceIds, itineraryRph) {

        return {
            "travelerInfoSummary": {
                "priceRequestInformation": {}
            },
            "offer": {
                "priced": priceIds,
                "summary": [
                    {
                        "itineraryRPH": itineraryRph
                    }
                ]
            }
        }
    }

    /*结束 Offer相关*/

    /*开始 预定页相关*/
    function getAirBookRQ(quoteID, travelerInfos, seatRequests, pricedOffers, username) {

        let airTravelerList = [];
        let i = 0;
        travelerInfos.forEach(travelerInfo => {
            let custLoyaltyList =  []
            if (travelerInfo.ffpNumber!=null && travelerInfo.ffpNumber!=''
                    && travelerInfo.flyerAirline !=null && travelerInfo.flyerAirline!=''
            ){
                custLoyaltyList.push({
                    "vendorCode": travelerInfo.flyerAirline,
                    "membershipID": travelerInfo.ffpNumber
                });
            }

            if (travelerInfo.passengerTypeCode == 'ADT') {
                let airPassenger = {
                    "travelerRefNumber": {
                        "rph": "PAX" + (++i)
                    },
                    "passengerTypeCode": travelerInfo.passengerTypeCode,
                    "personName": {
                        "namePrefix": travelerInfo.title,
                        "givenName": travelerInfo.firstName,
                        "surname": travelerInfo.lastName,
                        "nameTitle": travelerInfo.title
                    },
                    "documents": [
                        {
                            "docID": travelerInfo.passportNumber,
                            "expireDate": travelerInfo.expireDate,
                            "docIssueCountry": travelerInfo.passportCountryCode,
                            "docHolderNationality": travelerInfo.passportCountryCode
                        }
                    ],
                    "birthDate": travelerInfo.birth,
                    "emailList": [
                        {
                            "emailAddress": travelerInfo.emailAddress
                        }
                    ],
                    "telephoneList": [
                        {
                            "countryAccessCode": travelerInfo.areaCode,
                            "phoneNumber": travelerInfo.phoneNumber
                        }
                    ],
                    "custLoyaltyList": custLoyaltyList
                };
                airTravelerList.push(airPassenger);
            } else {
                let airPassenger = {
                    "travelerRefNumber": {
                        "rph": "PAX" + (++i)
                    },
                    "passengerTypeCode": travelerInfo.passengerTypeCode,
                    "personName": {
                        "namePrefix": travelerInfo.title,
                        "givenName": travelerInfo.firstName,
                        "surname": travelerInfo.lastName,
                        "nameTitle": travelerInfo.title
                    },
                    "documents": [
                        {
                            "docID": travelerInfo.passportNumber,
                            "expireDate": travelerInfo.expireDate,
                            "docIssueCountry": travelerInfo.passportCountryCode,
                            "docHolderNationality": travelerInfo.passportCountryCode
                        }
                    ],
                    "birthDate": travelerInfo.birth,
                    "telephoneList": [
                        {
                            "countryAccessCode": travelerInfo.areaCode,
                            "phoneNumber": travelerInfo.phoneNumber
                        }
                    ],
                    "custLoyaltyList": custLoyaltyList
                }
                airTravelerList.push(airPassenger);
            }
        });

        return {
            "targetName": "PROD",
            "airItinerary": {
                "airItineraryRPH": quoteID,
            },
            "priceInfo": {
                "quoteID": quoteID
            },
            "travelerInfo": {
                "airTravelerList": airTravelerList,
                "specialReqDetails": {
                    "seatRequests": seatRequests
                },
            },
            "offer": {
                "priced": pricedOffers
            },
            "pos": {
                "sourceList": [
                    {
                        "bookingChannel": {
                            "companyName": {
                                "code": "bookingengine"
                            },
                            "type": "7"
                        },
                        "requestorID": {
                            "id": username
                        }
                    }
                ]
            }
        }
    }

    /*结束 预定页相关*/

    /*开始 通用相关*/
    function getWrapFlightSegment(flightSegment) {
        let flightItinerary = flightSegment;
        if (flightSegment.segmentDetailsList) {
            flightItinerary.segmentDetails = flightSegment.segmentDetailsList[0];
        } else {
            flightItinerary.segmentDetails = {}
            let arrivalDateTime = flightSegment.arrivalDateTime;
            let departureDateTime = flightSegment.departureDateTime;
            let minutes = (new Date(arrivalDateTime).getTime() - new Date(departureDateTime).getTime()) / 1000 / 60;
            flightItinerary.segmentDetails.flightTime = minutes;
        }

        let hours = flightItinerary.segmentDetails.flightTime / 60 == 0 ? '' : Math.floor(flightItinerary.segmentDetails.flightTime / 60) + 'h';
        let munitues = flightItinerary.segmentDetails.flightTime % 60 + 'm';
        flightSegment.duration = hours + munitues;
        flightSegment.departureTime = DateUtils.formatDate(new Date(flightSegment.departureDateTime), 'HH:mm');
        flightSegment.arrivalTime = DateUtils.formatDate(new Date(flightSegment.arrivalDateTime), 'HH:mm');
        return flightSegment;
    }

    function getWrapOriginDestinationOption(originDestinationOption) {

        let durationInMins = originDestinationOption.durationInMins;
        let hours = durationInMins / 60 == 0 ? '' : Math.floor(durationInMins / 60) + 'h';
        let munitues = durationInMins % 60 + 'm';
        originDestinationOption.duration = hours + munitues;

        let groupNumber = null;
        let flightSegmentsNew = [];
        originDestinationOption.flightSegments.forEach(flightSegment => {
            let flightSegmentNew = getWrapFlightSegment(flightSegment);
            groupNumber = flightSegmentNew.groupNumber;
            flightSegmentsNew.push(flightSegmentNew);
            // originDestinationOptionInfo.itineraryInfos.push(flightItinerary);
            if (!originDestinationOption.originalDepartureCode) {
                originDestinationOption.originalDepartureCode = flightSegment.departureAirportCode;
                let date = new Date(flightSegment.departureDateTime);
                let hourInDay = date.getHours();
                let timeInterval = hourInDay >= 5 && hourInDay < 12 ? 'MORNING' : (hourInDay >= 12 && hourInDay < 18 ? "AFTERNOON" : "EVENING");

                originDestinationOption.originalDepartureDateTime = DateUtils.formatDate(date, 'YYYY-MM-DD HH:mm');
                originDestinationOption.departureTimeInterval = timeInterval;
            }
            originDestinationOption.destinationArrivalCode = flightSegment.arrivalAirportCode;
            let date = new Date(flightSegment.arrivalDateTime);
            let hourInDay = date.getHours();
            let timeInterval = hourInDay >= 5 && hourInDay < 12 ? 'MORNING' : (hourInDay >= 12 && hourInDay < 18 ? "AFTERNOON" : "EVENING");
            originDestinationOption.destinationArrivalDateTime = DateUtils.formatDate(new Date(flightSegment.arrivalDateTime), 'YYYY-MM-DD HH:mm');
            originDestinationOption.arrivalTimeInteraval = timeInterval;
        });

        if (originDestinationOption.flightSegments.length > 1) {
            let length = originDestinationOption.flightSegments.length;
            let flightSegments = originDestinationOption.flightSegments;
            originDestinationOption.flightNumbers = flightSegments[0].operatingAirlineCode + '-' + flightSegments[0].flightNumber + ",";
            originDestinationOption.stopCodes = '';
            originDestinationOption.stopNumber = length - 1;
            for (let i = 1; i < length; i++) {
                originDestinationOption.stopCodes += flightSegments[i].departureAirportCode;
                originDestinationOption.flightNumbers += flightSegments[i].operatingAirlineCode + '-' + flightSegments[i].flightNumber;
                if (i < length - 1) {
                    originDestinationOption.stopCodes += ',';
                    originDestinationOption.flightNumbers += ',';
                }
            }
        } else {
            originDestinationOption.stopNumber = 0;
        }
        originDestinationOption.flightSegments = flightSegmentsNew;
        originDestinationOption.groupNumber = groupNumber;
        return originDestinationOption;
    }

    function getWrapperPricedItineraryList(pricedItineraryList) {
        pricedItineraryList.forEach(pricedItinerary => {
            let wrapOriginDestinationOptions = [];
            pricedItinerary.airItinerary.originDestinationOptions.forEach(originDestinationOption => {
                let wrapOriginDestinationOption = getWrapOriginDestinationOption(originDestinationOption);

                let match = false;
                if (pricedItinerary.offer && pricedItinerary.offer.summary) {
                    pricedItinerary.offer.summary.forEach(summary => {
                        if (summary.origDestRPH == wrapOriginDestinationOption.rph) {
                            wrapOriginDestinationOption.bundleID = summary.bundleID;
                            match = true;
                        }
                    })
                }

                if (!match) {
                    wrapOriginDestinationOption.bundleID = '-1';
                }

                wrapOriginDestinationOptions.push(wrapOriginDestinationOption);
            });
            pricedItinerary.airItinerary.originDestinationOptions = wrapOriginDestinationOptions;
        });

        return pricedItineraryList;
    }

    function getWrapperAirItinerary(airItinerary) {
        let wrapperAirItinerary = {
            originDestinationOptions: []
        }
        if (airItinerary.originDestinationOptions==null || airItinerary.originDestinationOptions.length==0)return wrapperAirItinerary;
        airItinerary.originDestinationOptions.forEach(originDestinationOption => {
            let wrapOriginDestinationOption = getWrapOriginDestinationOption(originDestinationOption);

            wrapperAirItinerary.originDestinationOptions.push(wrapOriginDestinationOption);
        });
        return wrapperAirItinerary;
    }

    function getOfferMap(offerSummary) {
        let offerMap = {}

        offerSummary.forEach(summary => {
            if (!summary.bundleID) summary.bundleID = "-1";
            offerMap[summary.bundleID] = summary;
        });

        return offerMap;
    }

    /*结束 通用相关*/
    export default {
        getLowfareSearchRQ,
        getVerifyPriceRQ,
        getAirGetOfferRQ,
        getAirRuleRQ,
        getPriceWithOfferRQ,
        getSpecificFlightItineraries,
        getItinerariesShow,
        getOfferMap,
        getAirBookRQ,
        getWrapperPricedItineraryList,
        getWrapperAirItinerary
    }
