import FlightUtils from "@/utils/FlightUtils";
import axios from "@/config/AxiosConfig";

function lowFareSearch(originDestiations, adultNum, youthNum, childNum, infantNum, cabinPrefs) {
    let lowfareSearchRQ = FlightUtils.getLowfareSearchRQ(originDestiations, adultNum, youthNum, childNum, infantNum, cabinPrefs);
    let url = "/api/v1/air/low-fare-search";
    // console.info(lowfareSearchRQ);
    return axios.post(url, lowfareSearchRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function verifyPrice(airItinerary, passengerTypeQuantityList) {
    let verifyPriceRQ = FlightUtils.getVerifyPriceRQ(airItinerary, passengerTypeQuantityList);
    let url = "/api/v1/air/verify-price";
    // console.info(verifyPriceRQ);
    return axios.post(url, verifyPriceRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function fareRules(rph) {
    let airRuleRQ = FlightUtils.getAirRuleRQ(rph);
    let url = "/api/v1/air/fare-rules";
    // console.info(airRuleRQ);
    return axios.post(url, airRuleRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function getOffers(qutoId) {
    let airGetOfferRQ = FlightUtils.getAirGetOfferRQ(qutoId);
    let url = "/api/v1/air/offer";
    // console.info(airGetOfferRQ);
    return axios.post(url, airGetOfferRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function verifyPriceWithOffers(priceIds, itineraryRph) {
    let verifyPriceRQ = FlightUtils.getPriceWithOfferRQ(priceIds, itineraryRph);
    let url = "/api/v1/air/verify-price";
    // console.info(verifyPriceRQ);
    return axios.post(url, verifyPriceRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function book(quoteID, travelerInfos, seatRequests, pricedOffers, username) {
    let airBookRQ = FlightUtils.getAirBookRQ(quoteID, travelerInfos, seatRequests, pricedOffers, username);
    let url = "/api/v1/air/book";
    // console.info(airBookRQ);
    return axios.post(url, airBookRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function getFrequentFlyerPrograms() {
    let url = "/api/v1/air/frequent-flyer-programs";
    return axios.get(url);
}


export default {
    lowFareSearch,
    verifyPrice,
    fareRules,
    getOffers,
    verifyPriceWithOffers,
    book,
    getFrequentFlyerPrograms
}
