import axios from "@/config/AxiosConfig";
import BookingUtils from "@/utils/BookingUtils";

function bookingsRetrieve(bookingsRetrieveRQ) {
    let url = "/api/v1/booking/history";
    //console.info(bookingsRetrieveRQ)
    return axios.post(url, bookingsRetrieveRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}

function bookingCancel(payload) {
    let cancelRQ = BookingUtils.getCancelRQ(payload.bookingId, payload.cancelType);
    let url = "/api/v1/booking/cancel";

    return axios.post(url, cancelRQ,{
        headers:{
            "Content-Type": "application/json"
        }
    });
}
function printVoucher(payload) {
    let url = "/api/v1/booking/print-voucher?bookingId="+payload.bookingId+"&type="+payload.type+"&templateId=0&itemId="+payload.itemId;

    return axios.get(url, {
        headers:{
            "Content-Type": "application/json"
        },
        //解决PDF空白问题
        responseType: 'arraybuffer'
    }).then((res) => {
        let blob = new Blob([res.data], {
            type: 'application/pdf'
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        let fname = "Voucher-"+payload.bookingId;
        link.href = objectUrl;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}

export default {
    bookingsRetrieve,
    bookingCancel,
    printVoucher
}
