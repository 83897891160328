import * as mutationName from "@/store/modules/cartransfer/mutationTypes";
import CarTransferUtils from "@/utils/CarTransferUtils";
import FilterUtils from "@/utils/FilterUtils";
import SortUtils from "@/utils/SortUtils";
const mutations = {
    [mutationName.UPDATE_GROUND_SERVICES_INFO](state, payload){
        state.groundAvailRS = {};
        state.carTransfersInfo ={};
        state.carTransferInfosShow = [];
        state.minTransferPrice = 0;
        state.maxTransferPrice = 0;
        state.carTransferResultNum = 0;
        state.currencyCode = '';
        if (payload.groundAvailRS && payload.groundAvailRS.groundServices && payload.groundAvailRS.groundServices.length>0){
            state.groundAvailRS = payload.groundAvailRS;
            let carTransfersInfo = CarTransferUtils.getTransfersInfo(payload.groundAvailRS);
            state.carTransfersInfo = carTransfersInfo;
            state.minTransferPrice = carTransfersInfo['FIRSTTRIP'].minPrice;
            state.maxTransferPrice = carTransfersInfo['FIRSTTRIP'].maxPrice;
            state.carTransferResultNum = carTransfersInfo['FIRSTTRIP'].transfers.length;
            state.currencyCode = carTransfersInfo['FIRSTTRIP'].currencyCode;
            state.carTransferInfosShow = [];
            let carTransferInfosShow = CarTransferUtils.getCarTransferInfosShow(carTransfersInfo['FIRSTTRIP'].transfers, 1, 10);
            state.carTransferInfosShow = carTransferInfosShow;
        }
    },
    [mutationName.UPDATE_GROUND_SERVICES_SHOW](state, payload){
        let currentPage = payload.currentPage;
        let pageSize = payload.pageSize;
        let filterCondition = payload.filterCondition;
        let tripDirection = payload.tripDirection;
        let carTransferInfos = state.carTransfersInfo[tripDirection].transfers;
        if (filterCondition){
            carTransferInfos = FilterUtils.filterCarTransfers(carTransferInfos, filterCondition);
        }

        if (payload.sortCondition && payload.sortCondition.sortAttribute!=null && payload.sortCondition.type!=null){
            carTransferInfos = SortUtils.sort(carTransferInfos,payload.sortCondition.sortAttribute, payload.sortCondition.type);
        }
        state.carTransferResultNum = carTransferInfos==null?0:carTransferInfos.length;
        let carTransfersInfo = state.carTransfersInfo;
        state.minTransferPrice = carTransfersInfo[tripDirection].minPrice;
        state.maxTransferPrice = carTransfersInfo[tripDirection].maxPrice;
        state.currencyCode = carTransfersInfo[tripDirection].currencyCode;

        state.carTransferInfosShow = [];
        let carTransferInfosShow = CarTransferUtils.getCarTransferInfosShow(carTransferInfos, currentPage, pageSize);
        state.carTransferInfosShow = carTransferInfosShow;
    },
    [mutationName.UPDATE_GROUND_BOOKING_REVIEW_INFO](state, payload){
        let carTransferBookRS = payload.carTransferBookRS;
        if (carTransferBookRS){
            let groundReservation = carTransferBookRS.groundReservations[0];
            let primary = groundReservation.passenger.primary;
            let groundServices = groundReservation.groundServices;
            let transfersReviewInfo = {
            };

            let tripMap = {};
            let instance = groundServices[0].reference.instance;
            let searchDatetimes = instance.split('-').splice(4);
            let searchDatetime = searchDatetimes.join('-');
            tripMap[searchDatetime] = 'FIRSTTRIP';
            let firstTripPickupDatetimeStr = searchDatetime.replaceAll('T', ' ');
            let firstTripPickupDate = new Date(firstTripPickupDatetimeStr);
            if (groundServices.length>1){
                groundServices.forEach(groundService => {
                    let tripDateTime = groundService.reference.instance.split('-').splice(4).join('-');
                    if (!tripMap[tripDateTime]){

                        let datetimeStr = tripDateTime.replaceAll('T', ' ');
                        let compareDate = new Date(datetimeStr);
                        if (compareDate.getTime()>firstTripPickupDate.getTime()){
                            tripMap[tripDateTime] = 'SECONDTRIP';
                        }else{
                            tripMap[tripDateTime] = 'FIRSTTRIP';
                            tripMap[searchDatetime] = 'SECONDTRIP';
                        }
                    }
                })
            }

            groundServices.forEach(groundService  => {
                transfersReviewInfo = CarTransferUtils.constructTransferInfo(groundService, transfersReviewInfo, tripMap);
            });

            transfersReviewInfo.confirmations = groundReservation.confirmations;
            transfersReviewInfo.totalAmount = groundReservation.totalCharge.estimatedTotalAmount;
            transfersReviewInfo.currencyCode = groundReservation.totalCharge.currencyCode
            transfersReviewInfo.holder = {
                firstName: primary.personName.givenName,
                lastName: primary.personName.surname,
                nameTitle: primary.personName.nameTitle,
                phoneNumber: primary.telephoneList[0].phoneNumber,
                emailAddress: primary.emailList[0].stringValue,
            };

            console.info('carTransferBookingReview');
            console.info(transfersReviewInfo);
            state.carTransferBookingReview = transfersReviewInfo;
            state.groundBookRQ = payload.groundBookRQ;
            state.totalPayment = {
                currencyCode: transfersReviewInfo.currencyCode,
                amount: transfersReviewInfo.totalAmount
            };

        }else {
            state.carTransferBookingReview ={};
        }
    }
};

export default mutations;
