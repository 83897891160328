<template>
    <el-footer class="footer">
        <div class="main-footer-sub">
            <ul class="container footer-sub-list">
                <li>
                    <a href="#/contactus">Contact us</a>
                </li>
                <li>
                    <a href="#/aboutus">About Lion Travel</a>
                </li>
                <li>
                    <a href="https://developer.expediapartnersolutions.com/terms/en">Supplier Terms and Conditions</a>
                </li>
                <li>
                    <a href="#">Awards</a>
                </li>
            </ul>
        </div>
        <div class="main-footer">
            <div class="container">
                <!--<el-row>
                    <el-col :span="6" class="about-box">
                        <h3 class="i18n" name="Footer-Popular">{{$t('Terms and Conditions')}}</h3>
                        <p class="i18n" name="Footer-Description">
                            {{$t('Agreement between customer and bookingengine.hk')}}
                        </p>
                        <a href="https://support.expediapartnersolutions.com/hc/en-us/articles/360015079020" >Supplier Terms and Conditions</a>
                        <p>
                          These General Terms and Conditions for Booking (the "General Terms and Conditions"), along with the informatin set out in your booking
                          confirmatin email (the "Booking Confimation") set out the terms on which the company agrees to make available the Services to you through
                          the Website (see defined terms below)
                        </p>
                    </el-col>
                    <el-col :span="6" class="links">
                        <h4 class="i18n" name="Footer-Popular">{{$t('Popular Things to do')}}</h4>
                        <ul>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-1">{{$t('Romantic France')}}</a>
                            </li>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-2">{{$t('Wonderful Lodnon')}}</a>
                            </li>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-3">{{$t('Awesome Amsterdam')}}</a>
                            </li>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-4">{{$t('Wild Africa')}}</a>
                            </li>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-5">{{$t('Beach Goa')}}</a>
                            </li>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-6">{{$t('Casino Los Vages')}}</a>
                            </li>
                            <li>
                                <a href="#" class="i18n" name="Popular-Tours-7">{{$t('Romantic France')}}</a>
                            </li>
                        </ul>
                    </el-col>
                    <el-col :span="6" class="links">
                        <h4 class="i18n" name="Footer-OurServies">{{$t('Our Services')}}</h4>
                        <ul>
                            <li><a href="#" class="i18n" name="Footer-OurServies-1">{{$t('Domestic Flights')}}</a></li>
                            <li><a href="#" class="i18n" name="Footer-OurServies-2">{{$t('International Flights')}}</a></li>
                            <li><a href="#" class="i18n" name="Footer-OurServies-3">{{$t('Tours')}} &amp; {{$t('Holidays')}}</a></li>
                            <li><a href="#" class="i18n" name="Footer-OurServies-4">{{$t('Domestic Hotels')}}</a></li>
                            <li><a href="#" class="i18n" name="Footer-OurServies-5">{{$t('International Hotels')}}</a></li>
                            <li><a href="#" class="i18n" name="Footer-OurServies-6">{{$t('Cruise Holidays')}}</a></li>
                            <li><a href="#" class="i18n" name="Footer-OurServies-7">{{$t('Car Rental')}}</a></li>
                        </ul>
                    </el-col>
                    <el-col :span="6" class="contact-box">
                        <h4 class="i18n" name="ContactUs">{{$t('Contact Us')}}</h4>
                        <p class="i18n" name="ContactUs-Address">
                            <i class="fa fa-home"></i> {{$t('Street #156 Burbank, Studio City Hollywood, California USA')}}
                        </p>
                        <p><i class="fa fa-phone"></i> +852 39889931</p>
                        <p class="i18n" name="ContactUs-Email"><i class="fa fa-envelope-o"></i> sales@bookingengine.hk</p>
                    </el-col>
                </el-row>-->
                <el-row :gutter="30" class="mt15">
                    <el-col :span="14" class="main-footer-left">
                        <img src="../assets/images/logo1.png" alt="" class="footer-logo"/>
                        <ul class="company-info">
                            <li>
                              LION INTERNATIONAL TRAVEL SERVICE COMPANY LTD.
                            </li>
                            <li>
                              Traveling Agency License No: 350980
                            </li>
                            <li>
                              Address:  Flat D, 6/F, Milton Mansion, 96 Nathan Road, Tsim Sha Tsui,
                            </li>
                            <li>
                              Kowloon, Hong Kong
                            </li>
                            <li>
                              Branch:  Room 308, CMB Wing Lung Bank Centre, 636 Nathan Road,
                            </li>
                            <li>
                              Kowloon, Hong Kong
                            </li>
                        </ul>
                    </el-col>
                    <el-col :span="10" class="text-center main-footer-right">
                        <el-row>
                            <el-col :span="12" class="mb30">
                                <img src="../assets/images/asset10.png" alt="" height="70">
                                <br/>
                                <p class="mt10">
                                    The first brand of Chinese travel and life services
                                </p>
                            </el-col>
                            <el-col :span="12" class="mb30">
                                <img src="../assets/images/asset9.png" alt="" height="70">
                                <br/>
                                <p class="mt10">
                                    Global Diversity Rich Local Experience
                                </p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <img src="../assets/images/asset7.png" alt="" height="60" class="mt10 mb0">
                                <br/>
                                <p class="mt10">
                                    Safe and reliable payment mechanism and privacy protection
                                </p>
                            </el-col>
                            <el-col :span="12">
                                <img src="../assets/images/asset8.png" alt="" height="70">
                                <br/>
                                <p class="mt10">
                                    All-round professional and caring customer service team
                                </p>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row class="we-accept">
                    <el-col :span="24">
                        <h4 class="i18n" name="WeAccept">{{$t('We Accept')}}</h4>
                        <ul>
                            <li><img src="../assets/images/payment/paymentmethod.png" alt="INTERGER" width="350"></li>
<!--                            <li><img src="../assets/images/visa.png" alt="VISA" width="50"></li>-->
<!--                            <li><img src="../assets/images/mastercard.png" alt="Master Card" width="50"></li>-->
<!--                            <li><img src="../assets/images/unionpay.png" alt="Union Pay" width="50"></li>-->
<!--                            <li><img src="../assets/images/discover.png" alt="DISCOVER" width="50"></li>-->
<!--                            <li><img src="../assets/images/dinersclub.png" alt="Diners Club" width="50"></li>-->
                        </ul>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!---<div class="main-footer-nav">
            <div class="container">
                <el-row class="footer-nav">
                    <el-col :span="12" class="left-main-footer-nav">
                        <p>{{$t('Copyright © 2021 LimpidThemes. All Rights Reserved.')}}</p>
                    </el-col>
                    <el-col :span="12" class="text-right right-main-footer-nav">
                        <ul>
                            <li><a href="#" class="i18n" name="FOOTERFLIGHTS">{{$t('FLIGHTS')}}</a></li>
                            <li><a href="#" class="i18n" name="FOOTERTOURS">{{$t('Things to do')}}</a></li>
                            <li><a href="#" class="i18n" name="FOOTERCARS">{{$t('CARS')}}</a></li>
                            <li><a href="#" class="i18n" name="FOOTERHOTELS">{{$t('HOTELS')}}</a></li>
                            <li><a href="#" class="i18n" name="FOOTERBLOG">{{$t('BLOG')}}</a></li>
                        </ul>
                    </el-col>
                    <div class="go-up">
                        <el-backtop :bottom="100">
                            <a><i class="fa fa-arrow-up"></i></a>
                        </el-backtop>
                    </div>
                </el-row>
            </div>
        </div>--->
        <div class="main-footer-copyright">
            <div>COPYRIGHT © 2022 LION TRAVEL</div>
        </div>
    </el-footer>
</template>
<script>
export default {
    name: 'FooterMenu',
    data(){
        return{
            subscribe:{
                email:''
            }
        }
    }
}
</script>
<style lang="scss">
.footer{
    .main-footer-sub{
        padding: 45px 0px;
        background: #0A3152;
        color: #fff;
        .sub-row{
            // margin-left: 15px;
            // margin-right: 15px;
            .subscribe-form{
                label{
                    display: inline-block;
                    max-width: 100%;
                    margin-bottom: 5px;
                    font-weight: 700;
                }
                .el-input__wrapper{
                    background: #07253F;
                    border-radius: 0;
                }
                .el-input-group--append{
                    height: 40px;
                    --el-input-border-color: rgb(10,49,82);
                }
                .el-input-group__append{
                    height: 40px;
                    border: none;
                    background: #f9676b;
                    color: #fff;
                    font-weight: bold;
                    padding: 0 30px;
                    cursor: pointer;
                }
                .fa-paper-plane{
                    margin-right: 5px;
                }
                .el-input__inner{
                    color: #fff;
                }
            }
            .social-media{
                padding: 0px;
                list-style-type: none;
                text-align: right;
                // margin-right: -15px;
                li{
                    display: inline;
                    a{
                        background: #f9676b none repeat scroll 0 0;
                        border-radius: 50%;
                        color: #fff;
                        display: inline-block;
                        height: 16px;
                        margin: 38px 5px 0;
                        padding: 10px 10px;
                        width: 16px;
                        i.fa-facebook{
                            position: relative;
                            left: -4px;
                        }
                    }
                }
            }
        }
    }
    .main-footer{
        padding: 60px 0px;
        background: #07253F;
        .about-box{
            color: #BEC4C8;
            line-height: 2;
            margin-bottom: 30px;
            h3{
                font-weight: 800;
                margin-top: 0px;
                color: #f9676b;
                margin-bottom: 15px;
                letter-spacing: 1px;
                font-size: 18px;
            }
            a{
                display: inline-block;
                padding: 2px 15px;
                color: #f9676b;
                //border: 2px solid #f9676b;
                font-weight: bold;
                margin-top: 10px;
            }
        }
        .links{
            line-height: 2;
            margin-bottom: 30px;
            h4{
                color: #f9676b;
                margin-top: 0px;
                text-transform: uppercase;
                font-weight: 800;
                margin-bottom: 15px;
                letter-spacing: 1px;
                font-size: 18px;
            }
            ul{
                list-style-type: none;
                padding: 0px;
                li{
                    position: relative;
                    &:before{
                        content: "\f105";
                        position: absolute;
                        font-family: "FontAwesome";
                        color: #BEC4C8;
                    }
                    a{
                        color: #BEC4C8;
                        margin-left: 15px;
                    }
                }
            }
        }
        .contact-box{
            margin-bottom: 30px;
            h4{
                color: #f9676b;
                margin-top: 0px;
                text-transform: uppercase;
                font-weight: 800;
                margin-bottom: 15px;
                letter-spacing: 1px;
            }
            p{
                color: #BEC4C8;
                line-height: 2;
                i{
                    border: 2px solid #bec4c8;
                    border-radius: 50%;
                    color: #f9676b;
                    display: inline-block;
                    height: 16px;
                    margin-right: 10px;
                    margin-top: 10px;
                    padding: 10px 10px;
                    width: 16px;
                }
                i.fa-home:before{
                    position: relative;
                    left: 1px;
                }
                i.fa-phone:before{
                    position: relative;
                    left: 2px;
                    top: 1px;
                }
            }
        }
        .we-accept{
            text-align: center;
            padding: 30px 0px 0px;
            h4{
                color: #f9676b;
                margin-top: 0px;
                text-transform: uppercase;
                font-weight: 800;
                margin-bottom: 15px;
                letter-spacing: 1px;
            }
            ul{
                list-style-type: none;
                padding: 0px;
                li{
                    display: inline;
                    margin: 0px 5px;
                }
            }
        }
    }
    .main-footer-nav{
        padding: 30px 0px;
        background: #0A3152;
        color: #BEC4C8;
        position: relative;
        .container{
            position: relative;
        }
        p{
            margin-bottom: 0;
        }
        ul{
            margin-bottom: 0px;
            li{
                display: inline;
                margin: 0px 5px;
                a{
                    color: #BEC4C8;
                    margin-left: 15px;
                }
            }
        }
        .go-up{
            // position: absolute;
            // right: -65px;
            // top: -7px;
            // a{
            //     color: #BEC4C8;
            //     background: #07253F none repeat scroll 0 0;
            //     border-radius: 50%;
            //     display: inline-block;
            //     height: 20px;
            //     padding: 5px;
            //     width: 20px;
            //     .fa-arrow-up{
            //         position: relative;
            //         left: 3px;
            //         top: 2px;
            //     }
            // }
            .el-backtop{
                width: 32px;
                height: 32px;
                .fa-arrow-up{
                    color: #BEC4C8;
                }
            }
        }
    }
}
.el-footer{
    height: auto;
    padding: 0;
}
</style>