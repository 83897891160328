
const getters = {
    carTransferInfosShow(state) {
      return state.carTransferInfosShow;
    },
    carTransfersInfo(state) {
      return state.carTransfersInfo;
    },
    minTransferPrice(state){
        return state.minTransferPrice;
    },
    maxTransferPrice(state){
        return state.maxTransferPrice;
    },
    carTransferResultNum(state){
        return state.carTransferResultNum;
    },
    currencyCode(state){
        return state.currencyCode;
    },
    // 预定页相关
    groundBookRQ(state) {
      return state.groundBookRQ;
    },
    carTransferBookingReview(state){
      return state.carTransferBookingReview;
    },
    totalPayment(state) {
        return state.totalPayment;
    }
};

export default getters;
